import { useState, useEffect } from "react";
import "./SideMenuBar.scss";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { VscMenu } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { logOut, clearErrors, clearMessages } from "./../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

const SideMenuBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors } = useSelector((state) => state.authReducer);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setactive] = useState(window.location.pathname);
  const toggle = () => setIsOpen(!isOpen);

  const adminUser = JSON.parse(sessionStorage.getItem("admin"));
  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/"), 2000);
    }
  }, [errors, message]);
  return (
    <>
      <motion.div
        animate={{
          width: isOpen ? "220px" : "45px",
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
        className="sidebar"
      >
        <div className="sidebar-container">
          <div className="sidebar-container-name align-item-center">
            <div className="sidebar-container-name-icon align-item-center">
              <VscMenu onClick={toggle} style={{ cursor: "pointer" }} />
            </div>
            <div className="sidebar-container-name-user align-item-center">
              {isOpen ? (
                <h6>{adminUser?.fullName && adminUser.fullName}</h6>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* Menu Item  */}
          <div className="sidebar-container-menu">
            <div className="sidebar-container-menu-list">
              {props.routes.map((item) => {
                return (
                  <NavLink
                    key={item.id}
                    to={item.path}
                    onClick={() => setactive(item.path)}
                  >
                    {({ isActive }) => (
                      <SideBarItemStyled
                        isActive={isActive}
                        isNotification={Boolean(item.notification)}
                        isOpen={isOpen}
                      >
                        <span className="icon">{item.icon}</span>

                        {isOpen && <p>{item.name}</p>}
                        {isOpen && Boolean(item.notification) && (
                          <span className="notification">
                            {item.notification}
                          </span>
                        )}
                      </SideBarItemStyled>
                    )}
                  </NavLink>
                );
              })}
            </div>
            {isOpen ? (
              <button
                className="logout-btn-sidebar uppercase"
                onClick={() => dispatch(logOut())}
              >
                logout
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SideMenuBar;

const SideBarItemStyled = styled.div`
  &,
  & * {
    box-sizing: border-box;
  }

  display: grid;
  padding-bottom: 2rem;

  ${(p) =>
    !p.isOpen
      ? "grid-template-columns: 1fr;"
      : p.isNotification
      ? "grid-template-columns: auto 1fr auto;"
      : "grid-template-columns: auto 1fr;"};

  gap: 1rem;
  ${(p) => (p.isActive ? "color: #FFC841;" : "color : #808080;")}
  align-items: center;

  & > .icon {
    color: #ffc841;
    font-size: 1.8rem;
  }

  & > p {
    font-family: Montserrat;
    font-size: 1.4rem;
    line-height: 2.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > .notification {
    font-size: 1.2rem;
    background-color: #f23d33;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    display: grid;
    place-items: center;
    color: white;
    font-weight: bold;
  }
`;
