import React, { useState, useEffect } from "react";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Container, Heading, Modal, GoBackBtn } from "../../components";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  GetPromoCode,
  DeletePromoCode,
  clearErrors,
  clearMessages,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { StyledOverflowScroll } from "../analytics/Analytics";
const PromoCode = () => {
  const [promoCodeId, setPromoCodeId] = useState("");
  const dispatch = useDispatch();
  const { message, data, errors, loading } = useSelector(
    (state) => state.adminReducer
  );

  const [alert, setAlert] = useState(false);
  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);

  useEffect(() => {
    dispatch(GetPromoCode());
  }, []);

  const handlePromoDeleteId = (id) => {
    setAlert(!alert);
    setPromoCodeId(id);
  };

  const handlePromoDelete = () => {
    dispatch(DeletePromoCode(promoCodeId));
    setAlert(!alert);
  };
  return (
    <StyledOverflowScroll>
      <Container className="lg">
        <GoBackBtn />
      </Container>
      {alert ? (
        <Modal action={alert}>
          <div className="modal-accept">
            <h3>Are you sure !</h3>
            <p>You want to delete this review ? </p>
            <div className="modal-accept-button">
              <button onClick={() => handlePromoDelete()}>Yes</button>
              <button
                onClick={() => {
                  setAlert(!alert);
                }}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <Container className="half">
        <Heading className="primary center m-6">Promo Codes</Heading>

        <div className="promocode">
          {loading ? (
            <Puff
              height="60"
              width="60"
              radius="6"
              color="black"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          ) : data.length > 0 ? (
            data.map((item, ind) => {
              return (
                <PromoCard
                  key={ind}
                  code={item.name}
                  discount={item.percent_off}
                  description={item.description}
                  onClick={() => {
                    handlePromoDeleteId(item._id);
                  }}
                />
              );
            })
          ) : (
            <h1>No promoCode found</h1>
          )}
        </div>
      </Container>
    </StyledOverflowScroll>
  );
};

export default PromoCode;

const PromoCard = (props) => {
  return (
    <>
      <div className="promocard m-5">
        <div className="promocard-container">
          <div className="space-between">
            <CardItem heading="Promo code" content={props.code} />
            <div className="delete-icon">
              <RiDeleteBin5Fill onClick={props.onClick} />
            </div>
          </div>
          <CardItem heading="Discount" content={props.discount} />
          <CardItem heading="Description" content={props.description || "--"} />
        </div>
      </div>
    </>
  );
};

const CardItem = (props) => {
  return (
    <>
      <div className="carditem m-2">
        <h3>{props.heading}</h3>
        <p>{props.content}</p>
      </div>
    </>
  );
};
