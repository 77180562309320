import styled from "@emotion/styled";

export const InterfaceFeatureStyled = styled.div`
  &,
  & > * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Montserrat;
  }

  display: grid;
  gap: 4rem;
  padding: 4rem 2rem !important;
  max-width: 86rem;
  margin: 0 auto !important;

  & > h1 {
    font-size: 2.4rem;
    color: black;
    text-align: center;
  }

  & button:not(.icon) {
    justify-self: center;
    width: fit-content;
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
    background-color: #ffc841;
    cursor: pointer;
    outline: none;
    border: none;

    &:hover:not(:disabled) {
      background-color: #fdd05c;
    }

    &:disabled {
      background-color: #f2f1f1;
    }
  }
`;

export const HowCanShowStyled = styled.form`
  display: grid;
  gap: 1rem;

  & > .row {
    display: grid;
    gap: 1rem;
    align-items: center;

    & > label {
      font-weight: 500;
      font-size: 1.4rem;
      color: black;
    }

    & > .group {
      display: grid;
      max-width: fit-content;
      align-items: center;
      grid-template-columns: auto auto;
      justify-content: start;
      gap: 0.5rem;

      & > label {
        font-size: 1.2rem;
        color: black;
        cursor: pointer;
      }
    }
  }
`;

export const SelectedOccasionsFormStyled = styled.form`
  display: grid;
  gap: 1rem;

  & > .row {
    display: grid;
    gap: 1rem;
    align-items: center;

    & > label {
      font-weight: 500;
      font-size: 1.4rem;
      color: black;
    }
  }
`;

export const SelectedOccasionsStyled = styled.div`
  display: grid;
  gap: 0.5rem;

  & > h2 {
    font-weight: 700;
    font-size: 1.6rem;
    color: black;
    text-align: center;
  }

  & > table {
    display: grid;
    max-width: 100%;
    overflow: auto;

    & > thead,
    & > tbody {
      display: grid;

      & > tr {
        border: none;
        display: grid;
        grid-template-columns: 7rem 1fr 1fr;
        min-width: 40rem;

        &:has(th) {
          background-color: #f2f1f1;
        }

        &:has(td) {
          background-color: #cfcfcf;

          &:nth-child(even) {
            background-color: #f2f1f1;
          }
        }

        & > th,
        & > td {
          border: none;
          padding: 0.6rem 1rem;
          display: grid;
          align-items: center;
          justify-content: start;
          text-align: left;
          font-size: 1.2rem;
          color: #242424;

          &:nth-child(1) {
            justify-content: center;
          }

          &:nth-child(2) {
            border-left: 0.1rem solid rgba(0, 0, 0, 0.1);
            border-right: 0.1rem solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
`;

export const DividerStyled = styled.div`
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
`;

export const HomePageRelatedFormStyled = styled.form`
  display: grid;
  gap: 1.6rem;

  & > .group {
    display: grid;
    gap: 0.8rem;

    & > label {
      font-weight: 500;
      font-size: 1.4rem;
      color: black;
    }

    & > input {
      border: 0.1rem solid #cccccc;
      outline: none;
      border-radius: 0.4rem;
      padding: 1.2rem 2rem;
    }

    & > p {
      color: red;
      font-size: 1.2rem;
      font-weight: 500;
    }

    &.images > div {
      background-color: #f2f1f1;
      border: 0.1rem solid #cccccc;
      border-radius: 0.6rem;
      padding: 1.2rem;

      display: grid;
      gap: 2rem;

      & > .images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        &.only-one {
          grid-template-columns: 1fr;
        }

        & > .group {
          position: relative;
          display: grid;
          border: 0.1rem solid #cccccc;
          box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
            0 4px 6px -4px rgb(0 0 0 / 0.1);
          border-radius: 0.6rem;

          & > img {
            width: 100%;
            aspect-ratio: 9/13;
            overflow: hidden;
            object-fit: cover;

            &.only-one {
              aspect-ratio: auto;
              max-height: 50rem;
              border-radius: 0.6rem;
            }
          }

          & > button {
            position: absolute;
            right: 0.8rem;
            top: 0.8rem;
            z-index: 5;
          }

          & > input {
            width: 100%;
            border: none;
            padding: 0.8rem 1.2rem;
            border-radius: 0.4rem;
            outline: none;
          }
        }
      }

      & > label {
        display: grid;
        min-height: 5rem;
        place-items: center;

        & > span {
          font-size: 1.2rem;
          background-color: #ffc841;
          padding: 0.8rem 1.6rem;
          border-radius: 0.4rem;
          display: inline-block;
          cursor: pointer;

          &:hover {
            background-color: #fdd05c;
          }
        }
      }
    }
  }
`;

export const SelectedImagesStyled = styled.div`
  display: grid;
  gap: 0.5rem;

  & > h2 {
    font-weight: 700;
    font-size: 1.6rem;
    color: black;
    text-align: center;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    & > .group {
      position: relative;
      display: grid;
      border: 0.1rem solid #cccccc;
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
      background-color: #f2f1f1;

      & > img {
        width: 100%;
        aspect-ratio: 9/13;
        overflow: hidden;
        object-fit: cover;
      }

      & > button {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
        border: none;
        outline: none;
        cursor: pointer;
      }

      & > form {
        display: grid;
        grid-template-columns: 1fr auto;

        & > input {
          width: 100%;
          border: none;
          padding: 0.8rem 1.2rem;
          border-radius: 0.4rem;
          outline: none;
        }
      }
    }
  }
`;
