import { chatContants } from "./../constants";

const initialState = {
  getAllChatsData: {
    data: [],
    loading: false,
  },

  createNewChatData: {
    loading: false,
  },

  createNewChatsWithVendorsAndCustomersData: {
    loading: false,
  },
  allMessages: [],
};

const chatReducer = (s = initialState, action) => {
  const state = { ...s };

  switch (action.type) {
    case chatContants.GET_ALL_CHATS.request:
      state.getAllChatsData.loading = true;
      break;

    case chatContants.GET_ALL_CHATS.success:
    case chatContants.GET_ALL_CHATS.error:
      state.getAllChatsData.loading = false;
      state.getAllChatsData.data = action.payload.data;
      break;

    case chatContants.POST_NEW_CHAT.request:
      state.createNewChatData.loading = true;
      break;

    case chatContants.POST_NEW_CHAT.success:
    case chatContants.POST_NEW_CHAT.error:
      state.createNewChatData.loading = false;
      break;

    case chatContants.POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS.request:
      state.createNewChatsWithVendorsAndCustomersData.loading = true;
      break;

    case chatContants.POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS.success:
    case chatContants.POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS.error:
      state.createNewChatsWithVendorsAndCustomersData.loading = false;
      break;

    case chatContants.UPDATE_MESSAGE:
      state.allMessages = action.payload;
      break;

    default:
      break;
  }

  return state;
};

export default chatReducer;
