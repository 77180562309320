import React, { useEffect } from "react";

const useUserChange = (cb) => {
  if (!cb) {
    throw new Error("Cb is required!");
  }

  useEffect(() => {
    let f2;

    const f1 = () => {
      f2 = cb();
    };
    window.addEventListener("user-state-change", f1);
    f1();

    return () => {
      window.removeEventListener("user-state-change", f1);
      f2 && f2();
    };
  }, []);
};

export default useUserChange;
