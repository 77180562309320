import React, { useEffect, useLayoutEffect, useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { cardImage, profile } from "../../assests";
import {
  Container,
  Grid,
  Heading,
  Modal,
  Button,
  FormText,
} from "../../components";

import { GrFacebook } from "react-icons/gr";
import { BsGlobe2 } from "react-icons/bs";
import { FaInstagramSquare } from "react-icons/fa";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Puff } from "react-loader-spinner";
import { updateVerification } from "../../store/actions";
import { toast } from "react-hot-toast";
import { BACKEND_STATIC_URL } from "../../constants";
import { StyledOverflowScroll } from "../analytics/Analytics";
const VerificationRequestDetail = () => {
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [holdModal, setHoldModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { verifications, loading } = useSelector(
    (state) => state.verificationReducer
  );
  const [verification, setVerification] = useState({});
  const dispatch = useDispatch();

  const handleSubmit = (status, reason) => {
    dispatch(
      updateVerification({ body: { status, reason }, _id: id }, () => {
        toast.success("Verification updated successfully!");
        navigate("/verification-request");
      })
    );
  };

  const acceptvalidation = Yup.object({
    accept_field: Yup.string()
      .min(2, "Must be 2 character")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
  });
  const rejectvalidation = Yup.object({
    reject_field: Yup.string()
      .min(2, "Must be 2 character")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
  });
  const holdvalidation = Yup.object({
    hold_field: Yup.string()
      .min(2, "Must be 2 character")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
  });

  useEffect(() => {
    const verification = verifications.find(
      (verification) => verification._id === id
    );
    if (!verification) {
      navigate("/verification-request", { replace: true });
      return;
    }
    setVerification(verification);
  }, [id, navigate, verifications]);

  return (
    <StyledOverflowScroll>
      {acceptModal ? (
        <Modal action="">
          <div className="px-3">
            <Heading className="normal center m-4">Accept Verification</Heading>
            <div>
              <Formik
                initialValues={{
                  accept_field: "",
                }}
                validateOnMount
                validationSchema={acceptvalidation}
                onSubmit={(values, { resetForm }) => {
                  resetForm();
                  handleSubmit("accepted", values.accept_field);
                  setAcceptModal(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <FormText
                      label="Provide a reason for acceptance"
                      name="accept_field"
                      type="text"
                      cols="100"
                      rows="10"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {/* Reject Modal */}

      {rejectModal ? (
        <Modal>
          <div className="px-3">
            <Heading className="normal center m-4">Reject Verification</Heading>
            <div>
              <Formik
                initialValues={{
                  reject_field: "",
                }}
                validateOnMount
                validationSchema={rejectvalidation}
                onSubmit={(values, { resetForm }) => {
                  resetForm();
                  handleSubmit("rejected", values.reject_field);
                  setRejectModal(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <FormText
                      label="Provide a reason for rejection"
                      name="reject_field"
                      type="text"
                      cols="100"
                      rows="10"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {/* Hold Modal */}

      {holdModal ? (
        <Modal>
          <div className="px-3">
            <Heading className="normal center m-4">Hold Verification</Heading>
            <div>
              <Formik
                initialValues={{
                  hold_field: "",
                }}
                validateOnMount
                validationSchema={holdvalidation}
                onSubmit={(values, { resetForm }) => {
                  resetForm();
                  handleSubmit("hold", values.hold_field);
                  setAcceptModal(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <FormText
                      label="Provide a reason for Hold"
                      name="hold_field"
                      type="text"
                      cols="100"
                      rows="10"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : (
        <div className=" m-5">
          <Container className="md">
            <div className="verification-request-detail ">
              <Container className="md">
                <div className="space-between">
                  <div className="display-none"></div>
                  <Heading className="primary center">
                    {verification?.user?.fullName ?? ""}{" "}
                    {verification?.user?.surName ?? ""}
                  </Heading>
                  <div className="verification-image-profile">
                    <img
                      src={
                        verification?.user?.photoPath
                          ? `${BACKEND_STATIC_URL}${verification?.user?.photoPath}`
                          : profile
                      }
                      alt="profile"
                    />
                  </div>
                </div>
              </Container>

              <div className="m-5">
                <Container className="md">
                  <div className="m-1 center">
                    <Grid className="grid-3-simple">
                      <div className="align-item-center  social-media_verification">
                        <a href={verification?.socialLinks?.facebook}>
                          <GrFacebook />
                          <p>
                            {verification?.socialLinks?.facebook?.replace(
                              /^(https?:\/\/)?(www\.)?/,
                              ""
                            ) ?? "--"}
                          </p>
                        </a>
                      </div>
                      <div className="align-item-center  social-media_verification">
                        <a href={verification?.socialLinks?.instagram}>
                          <FaInstagramSquare />
                          <p>
                            {verification?.socialLinks?.instagram?.replace(
                              /^(https?:\/\/)?(www\.)?/,
                              ""
                            ) ?? "--"}
                          </p>
                        </a>
                      </div>
                      <div className="align-item-center  social-media_verification">
                        <a href={verification?.socialLinks?.webiste ?? "--"}>
                          <BsGlobe2 />
                          <p>
                            {verification?.socialLinks?.webiste?.replace(
                              /^(https?:\/\/)?(www\.)?/,
                              ""
                            ) ?? "--"}
                          </p>
                        </a>
                      </div>
                    </Grid>
                  </div>
                </Container>
                <div className="verification-request-detail-hr m-1">
                  <hr />
                </div>
              </div>

              <Container className="md">
                <Grid className="grid-2">
                  <Container className="container">
                    <CardItem
                      heading="Full Name"
                      content={verification?.user?.fullName ?? "--"}
                    />
                    <CardItem
                      heading="Town/City"
                      content={verification?.user?.city ?? "--"}
                    />
                    <CardItem
                      heading="Company name"
                      content={verification?.user?.companyName ?? "--"}
                    />
                    <CardItem
                      heading="Email"
                      content={verification?.user?.email ?? "--"}
                    />
                    {/* <CardItem
                      heading="Part time / full time"
                      content={verification?.user?.shift ?? "--"}
                    /> */}
                  </Container>
                  <Container className="">
                    <CardItem
                      heading="Street address"
                      content={verification?.user?.address ?? "--"}
                    />
                    <CardItem
                      heading="Type of business"
                      content={verification?.user?.bussinessType ?? "--"}
                    />
                    <CardItem
                      heading="Business working since"
                      content={
                        verification?.yearOfExperience
                          ? new Date().getFullYear() -
                            verification?.yearOfExperience
                          : "--"
                      }
                    />
                  </Container>
                </Grid>
                <CardItem
                  heading="Reason for apply "
                  content={verification?.description ?? "--"}
                />

                <div className="request-detail-images">
                  <div className="request-detail-images-container">
                    {/* <img
                      src={
                        verification?.productPictures
                          ? verification?.productPictures[0]
                          : cardImage
                      }
                      alt="card"
                      
                    />
                    <img
                      src={
                        verification?.productPictures
                          ? verification?.productPictures[0]
                          : cardImage
                      }
                      alt="card"
                      
                    /> */}
                    <img
                      src={
                        verification?.productPictures
                          ? BACKEND_STATIC_URL +
                            verification?.productPictures[0]
                          : cardImage
                      }
                      alt="card"
                    />
                  </div>
                </div>

                <div className="m-5">
                  <div className="request-detail-btn center ">
                    <button
                      onClick={() => {
                        setAcceptModal(!acceptModal);
                      }}
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => {
                        setHoldModal(!holdModal);
                      }}
                    >
                      Hold
                    </button>
                    <button
                      onClick={() => {
                        setRejectModal(!rejectModal);
                      }}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </Container>
            </div>
          </Container>
        </div>
      )}
    </StyledOverflowScroll>
  );
};

export default VerificationRequestDetail;

const CardItem = (props) => {
  return (
    <>
      <div className="carditem m-3">
        <h3>{props.heading}</h3>
        <p>{props.content}</p>
      </div>
    </>
  );
};
