import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./ServiceDetail.scss";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { cardImage } from "../../assests";

import {
  Container,
  Heading,
  InfoItem,
  GoBackBtn,
  Button,
  Modal,
  FormText,
} from "../../components";

import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import {
  GetSingleService,
  UpdateServiceByStatus,
  clearErrors,
  clearMessages,
  getAllChats,
} from "../../store/actions";
import { Puff } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { BACKEND_STATIC_URL, REACT_APP_VENDOR_SHOP } from "../../constants";
import { useQueryParams } from "../../hooks";
import {
  deleteVendorService,
  patchVendorServiceDeleteRequest,
} from "../../store/actions/services.actions";
import { sendMessageNew } from "../../firebase/message";
import { getChatOfAvailableChats } from "../../utils/getChat";
import { getLoggedInUserDetails } from "../../utils";
import moment from "moment";
import {
  NOTIFICATION_TYPES,
  sendNotfication,
} from "../../firebase/notfication";

const ServiceDetail = () => {
  const navigate = useNavigate();
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [holdModal, setHoldModal] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    singleVendorService,
    errors,
    message,
    loading,
    vendorServiceLoading,
  } = useSelector((state) => state.adminReducer);
  const { delete: isDeleted, isAcceptedEdit } = useQueryParams({
    delete: false,
    isAcceptedEdit: false,
  });
  const transformedService = useMemo(() => {
    if (singleVendorService?.acceptedEdit && isAcceptedEdit) {
      return { ...singleVendorService, ...singleVendorService?.acceptedEdit };
    }
    return singleVendorService;
  }, [isAcceptedEdit, singleVendorService]);

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors(id));
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate(-1), 2000);
    }
  }, [errors, message]);

  useEffect(() => {
    dispatch(GetSingleService(id));
    dispatch(getAllChats());
  }, [id, dispatch]);

  const acceptvalidation = Yup.object({
    accept_field: Yup.string()
      .min(2, "Must be 2 character")
      .max(250, "Must be 250 characters or less")
      .required("Required"),
  });
  const rejectvalidation = Yup.object({
    reject_field: Yup.string()
      .min(2, "Must be 2 character")
      .max(250, "Must be 250 characters or less")
      .required("Required"),
  });
  const holdvalidation = Yup.object({
    hold_field: Yup.string()
      .min(2, "Must be 2 character")
      .max(250, "Must be 250 characters or less")
      .required("Required"),
  });

  const sendMessage = useCallback(
    (message) => {
      sendMessageNew({
        chatId: getChatOfAvailableChats(
          getLoggedInUserDetails().user.id,
          singleVendorService?.vendor?.id
        )._id,
        message,
        receiverId: singleVendorService?.vendor?.id,
        senderId: getLoggedInUserDetails().user.id,
      });
    },
    [singleVendorService?.vendor?.id]
  );

  return (
    <>
      {acceptModal ? (
        <Modal action="">
          <div className="px-3">
            <Heading className="normal center m-4">Accept Service</Heading>
            <div>
              <Formik
                initialValues={{
                  accept_field: "",
                }}
                validateOnMount
                validationSchema={acceptvalidation}
                onSubmit={(values, { resetForm }) => {
                  const { accept_field } = values;
                  let finalResult = {
                    status: "Accepted",
                    reason: accept_field,
                    acceptedEdit: isAcceptedEdit,
                  };
                  dispatch(
                    UpdateServiceByStatus(finalResult, id, () => {
                      const message = `Your following ${
                        transformedService?.acceptedEdit && isAcceptedEdit
                          ? "edit request"
                          : "Service"
                      } has been accepted by admin: \n Service Name : ${
                        transformedService?.customServiceName ||
                        transformedService?.serviceName?.serviceName
                      } \n Created At: ${moment(
                        new Date(transformedService?.createdAt)
                      ).format("MMM DD, YYYY")} \n Accepted At: ${moment(
                        new Date(Date.now())
                      ).format("MMM DD, YYYY")} \n Accepted Reason: ${
                        values.accept_field
                      }`;
                      sendMessage(message);
                      sendNotfication({
                        message,
                        notficationType: NOTIFICATION_TYPES.SERVICE,
                        productId: transformedService.id,
                        receiverId: transformedService?.vendor?.id,
                        senderId: getLoggedInUserDetails().user.id,
                      });
                    })
                  );
                  resetForm({ values: "" });
                  setAcceptModal(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <FormText
                      label="Provide a reason for acceptance"
                      name="accept_field"
                      type="text"
                      cols="100"
                      rows="10"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {/* Reject Modal */}

      {rejectModal ? (
        <Modal>
          <div className="px-3">
            <Heading className="normal center m-4">Reject Service</Heading>
            <div>
              <Formik
                initialValues={{
                  reject_field: "",
                }}
                validateOnMount
                validationSchema={rejectvalidation}
                onSubmit={(values, { resetForm }) => {
                  const { reject_field } = values;
                  let finalResult = {
                    status: "Rejected",
                    reason: reject_field,
                    acceptedEdit: isAcceptedEdit,
                  };
                  dispatch(
                    UpdateServiceByStatus(finalResult, id, () => {
                      const message = `Your following ${
                        transformedService?.acceptedEdit && isAcceptedEdit
                          ? "edit request"
                          : "Service"
                      } has been rejected by admin: \n Service Name : ${
                        transformedService?.customServiceName ||
                        transformedService?.serviceName?.serviceName
                      } \n Created At: ${moment(
                        new Date(transformedService?.createdAt)
                      ).format("MMM DD, YYYY")} \n Rejected At: ${moment(
                        new Date(Date.now())
                      ).format("MMM DD, YYYY")} \n Rejected Reason: ${
                        values.reject_field
                      }`;
                      sendMessage(message);
                      sendNotfication({
                        message,
                        notficationType: NOTIFICATION_TYPES.SERVICE,
                        productId: transformedService.id,
                        receiverId: transformedService?.vendor?.id,
                        senderId: getLoggedInUserDetails().user.id,
                      });
                    })
                  );
                  resetForm({ values: "" });
                  setRejectModal(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <FormText
                      label="Provide a reason for rejection"
                      name="reject_field"
                      type="text"
                      cols="100"
                      rows="10"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {/* Hold Modal */}

      {holdModal ? (
        <Modal>
          <div className="px-3">
            <Heading className="normal center m-4">Hold Service</Heading>
            <div>
              <Formik
                initialValues={{
                  hold_field: "",
                }}
                validateOnMount
                validationSchema={holdvalidation}
                onSubmit={(values, { resetForm }) => {
                  const { hold_field } = values;
                  let finalResult = {
                    status: "Hold",
                    reason: hold_field,
                  };
                  dispatch(
                    UpdateServiceByStatus(finalResult, id, () => {
                      const message = `Your following service has been put on hold by admin: \n Service Name : ${
                        transformedService?.customServiceName ||
                        transformedService?.serviceName?.serviceName
                      } \n Created At: ${moment(
                        new Date(transformedService?.createdAt)
                      ).format("MMM DD, YYYY")} \n Held At: ${moment(
                        new Date(Date.now())
                      ).format("MMM DD, YYYY")} \n Put on Hold Reason: ${
                        values.hold_field
                      }`;
                      sendMessage(message);

                      sendNotfication({
                        message,
                        notficationType: NOTIFICATION_TYPES.SERVICE,
                        productId: transformedService.id,
                        receiverId: transformedService?.vendor?.id,
                        senderId: getLoggedInUserDetails().user.id,
                      });
                    })
                  );
                  resetForm({ values: "" });
                  setHoldModal(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <FormText
                      label="Provide a reason for Hold"
                      name="hold_field"
                      type="text"
                      cols="100"
                      rows="10"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {loading || vendorServiceLoading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : Object.keys(transformedService).length > 0 ? (
        <>
          <Container className="lg">
            <GoBackBtn className="m-4" />
          </Container>
          <Container className="half">
            <div className="servicedetail m-5">
              <Heading className="primary center m-5">
                {transformedService?.serviceName?.serviceName || "--"}
              </Heading>
            </div>

            <div className="servicedetail-container m-5">
              <InfoItem
                title="Custom Service Name"
                content={`${transformedService?.customServiceName || "--"}`}
              />
              <InfoItem
                title="Booking notice period"
                content={`${transformedService?.bookingPeriod || "--"}`}
              />
              <InfoItem
                title="Availability for booking"
                content={transformedService?.bookingWeekDays.join(", ")}
              />
              <InfoItem
                title="Guests limit"
                content={transformedService?.numberOfCaters || "--"}
              />
              {transformedService?.isProvidingServiceAtAllLoc ? (
                <InfoItem
                  title="Service Area"
                  content={"Providing services to all places of UK"}
                />
              ) : (
                <>
                  <div className="servicedetail-container-area">
                    <h6>Service area</h6>
                    {transformedService?.serviceArea?.map((a) => (
                      <p>
                        <MdOutlineKeyboardArrowRight className="servicedetail-container-area-icon" />
                        <span>{a?.slice(0, -4)}</span>
                      </p>
                    )) || "--"}
                  </div>
                  <br />
                </>
              )}
              <div className="servicedetail-container-area">
                <h6>Occasion Covered</h6>
                {transformedService?.occassion?.map((o) => (
                  <p>
                    <MdOutlineKeyboardArrowRight className="servicedetail-container-area-icon" />
                    <span>{o?.occasionName}</span>
                  </p>
                )) || "--"}
              </div>
              <Heading className="center m-2">Images</Heading>
              <div className="servicedetail-container-image m-3">
                <div className="servicedetail-container-image-section">
                  {transformedService?.thumbnails?.map((t) => (
                    <img
                      src={
                        transformedService?.thumbnails
                          ? BACKEND_STATIC_URL + t
                          : cardImage
                      }
                      alt="service-photos"
                    />
                  ))}
                </div>
              </div>
              <InfoItem
                title="About service"
                content={transformedService?.description ?? "--"}
              />
              <InfoItem
                title="Referal Link"
                content={transformedService?.referalLink ?? "No provided!"}
              />
              <InfoItem
                title="Current Status"
                content={transformedService?.status ?? "--"}
              />
              <InfoItem
                title="Old Reason"
                content={transformedService?.reason ?? "--"}
              />
              <InfoItem
                title="Vendor Subscription Plan"
                content={
                  transformedService?.vendor?.subscription?.plan?.product
                    ?.name || "Not subscribed to any"
                }
              />
              <InfoItem
                title="Vendor Instragram Link"
                content={
                  <a
                    href={
                      transformedService?.vendor?.instagram?.includes("http")
                        ? transformedService?.vendor?.instagram
                        : `https://www.instagram.com/${transformedService?.vendor?.instagram}`
                    }
                    style={{ color: "blue" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {transformedService?.vendor?.instagram?.includes("http")
                      ? transformedService?.vendor?.instagram
                      : `https://www.instagram.com/${transformedService?.vendor?.instagram}`}
                  </a>
                }
              />
            </div>

            <div className="m-6">
              <div className="request-detail-btn center ">
                {isDeleted ? (
                  <>
                    <button
                      onClick={() => {
                        dispatch(
                          deleteVendorService({ id }, () => {
                            toast.success("Serviced deleted!");
                            navigate("/vender-services?deleteStatus=PENDING");
                            const message = `Your following service has been deleted by admin: \n Service Name : ${
                              transformedService?.customServiceName ||
                              transformedService?.serviceName?.serviceName
                            } \n Created At: ${moment(
                              new Date(transformedService?.createdAt)
                            ).format("MMM DD, YYYY")} \n Requested At: ${moment(
                              new Date(
                                transformedService?.deleteStatusUpdatedAt
                              )
                            ).format("MMM DD, YYYY")}`;
                            sendMessage(message);
                            sendNotfication({
                              message,
                              notficationType: NOTIFICATION_TYPES.SERVICE,
                              productId: transformedService.id,
                              receiverId: transformedService?.vendor?.id,
                              senderId: getLoggedInUserDetails().user.id,
                            });
                          })
                        );
                      }}
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => {
                        dispatch(
                          patchVendorServiceDeleteRequest(
                            {
                              id,
                              body: { deleteStatus: "NULL" },
                            },
                            () => {
                              toast.success("Request rejected!");
                              navigate("/vender-services?deleteStatus=PENDING");
                              const message = `Your following service deletion request has been rejected by admin: \n Service Name : ${
                                transformedService?.customServiceName ||
                                transformedService?.serviceName?.serviceName
                              } \n Created At: ${moment(
                                new Date(transformedService?.createdAt)
                              ).format(
                                "MMM DD, YYYY"
                              )} \n Requested At: ${moment(
                                new Date(
                                  transformedService?.deleteStatusUpdatedAt
                                )
                              ).format("MMM DD, YYYY")}`;
                              sendMessage(message);

                              sendNotfication({
                                message,
                                notficationType: NOTIFICATION_TYPES.SERVICE,
                                productId: transformedService.id,
                                receiverId: transformedService?.vendor?.id,
                                senderId: getLoggedInUserDetails().user.id,
                              });
                            }
                          )
                        );
                      }}
                    >
                      Reject
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setAcceptModal(!acceptModal);
                      }}
                    >
                      Accept
                    </button>
                    {!singleVendorService?.acceptedEdit && (
                      <button
                        onClick={() => {
                          setHoldModal(!holdModal);
                        }}
                      >
                        Hold
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setRejectModal(!rejectModal);
                      }}
                    >
                      Reject
                    </button>
                  </>
                )}
              </div>
            </div>

            <br />
            <br />
            <br />
          </Container>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ServiceDetail;
