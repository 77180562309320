import axios from "axios";
import { BACKEND_URL } from "../../constants";
import { supportConstants } from "../constants";
import asyncAction from "../../utils/asyncAction";

export const getAllSupportTickets = ({ page } = {}, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/message/get/support?limit=10${
          page ? `&page=${page}` : ""
        }`,
        authHeaders
      );
      return { data };
    },
    supportConstants.GET_ALL_SUPPORT_TICKETS,
    onSuccess,
    onError
  );
};
