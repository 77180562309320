import React from "react";
import "./Input.scss";
import { useField } from "formik";
const Input = (className, ...props) => {
  const [field, meta] = useField(props);

  return (
    <>
      <div className="input-container">
        <input
          type="text"
          className={`input ${className}   ${
            meta.touched && meta.error && "invalid-input"
          }`}
          {...field}
          {...props}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default Input;
