import React, { useEffect } from "react";
import { StyledOverflowScroll } from "../analytics/Analytics";
import styled from "@emotion/styled";
import { Container } from "../../components";
import { Pagination } from "@mui/material";
import { useQueryParams } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupportTickets } from "../../store/actions";
import { CenteredLoader } from "../Chat/Chat";

const SupportTickets = () => {
  const { setQueryParam, page } = useQueryParams({ page: 1 });
  const { getAllSupportTicketsData } = useSelector((s) => s.supportReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSupportTickets({ page }));
  }, [page, dispatch]);

  return (
    <StyledOverflowScroll>
      <SupportTicketsStyled>
        <h1>Support Tickets</h1>

        <div>
          <div className="data">
            {getAllSupportTicketsData?.loading ? (
              <div className="loader">
                <CenteredLoader />
              </div>
            ) : getAllSupportTicketsData?.data?.results?.length === 0 ? (
              <p className="no-found">No Record Found.</p>
            ) : (
              <div className="data">
                {getAllSupportTicketsData?.data?.results?.map((d, i) => (
                  <div className="support-card" key={i}>
                    <div>
                      <h6>Email</h6>
                      <span>{d?.email}</span>
                    </div>
                    <div>
                      <h6>Subject</h6>
                      <span>{d?.subject}</span>
                    </div>
                    <div>
                      <h6>Message</h6>
                      <span>{d?.message}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {getAllSupportTickets?.data?.totalPages && (
            <div className="pagination">
              <Pagination
                page={Number(page)}
                count={getAllSupportTickets?.data?.totalPages}
                onChange={(_, p) => {
                  setQueryParam({ page: p });
                }}
              />
            </div>
          )}
        </div>
      </SupportTicketsStyled>
    </StyledOverflowScroll>
  );
};

export default SupportTickets;

const SupportTicketsStyled = styled.div`
  padding: 3rem 1.6rem;
  display: grid;
  gap: 3rem;
  max-width: 57.6rem;
  margin: 0 auto;

  & > h1 {
    font-size: 2.8rem;
    text-align: center;
  }

  & > div {
    display: grid;
    gap: 3rem;

    & > .data {
      & > .leader {
        padding: 5rem 0;
      }

      & > .no-found {
        text-align: center;
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 500;
      }

      & > .data {
        display: grid;
        gap: 1rem;

        & > .support-card {
          background-color: #f2f1f1;
          border: 0.1rem solid #c8c7c7;
          border-radius: 3rem;
          padding: 3rem;

          display: grid;
          gap: 2rem;

          & > div {
            display: grid;
            gap: 1rem;

            & > h6 {
              font-weight: 600;
              font-size: 1.4rem;
              color: rgba(0, 0, 0, 1);
            }

            & > span {
              display: block;
              font-weight: 400;
              font-size: 1.6rem;
              color: rgba(0, 0, 0, 0.7);
            }
          }
        }
      }
    }

    & > .pagination {
      display: grid;
      place-items: center;
    }
  }
`;
