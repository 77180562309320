import React, { useEffect, useMemo, useState } from "react";
import { StyledFilterMenu, VendorsAccordingToCitiesStyled } from "./styles";
import { useSelector } from "react-redux";
import { MdSearch } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { useQueryParams } from "../../hooks";

const availableFilters = ["All", "0-100", "101-500", "501-1000", "1000+"];

const VendorsAccordingToCities = () => {
  const { getNumberOfVendorsByCityData } = useSelector(
    (state) => state.adminReducer
  );
  const [filterAchor, setFilterAchor] = useState(null);
  const { setQueryParam, filter, query } = useQueryParams({
    filter: "All",
    query: "",
  });
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const f1 = () => {
      setQueryParam({ query: searchText });
    };

    const timeout = setTimeout(f1, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchText, setQueryParam]);

  const filteredVendorsByCityData = useMemo(() => {
    const filteredWithSearchText = getNumberOfVendorsByCityData?.data?.filter(
      (v) => v?._id?.toLowerCase()?.includes(query?.toLowerCase())
    );

    switch (filter) {
      case availableFilters[0]:
        return filteredWithSearchText;
      case availableFilters[1]:
        return filteredWithSearchText?.filter(
          (v) => v.count >= 0 && v.count <= 100
        );
      case availableFilters[2]:
        return filteredWithSearchText?.filter(
          (v) => v.count >= 101 && v.count <= 500
        );
      case availableFilters[3]:
        return filteredWithSearchText?.filter(
          (v) => v.count >= 501 && v.count <= 1000
        );
      case availableFilters[4]:
        return filteredWithSearchText?.filter((v) => v.count > 1000);

      default:
        return filteredWithSearchText;
    }
  }, [filter, getNumberOfVendorsByCityData?.data, query]);

  return (
    <>
      <VendorsAccordingToCitiesStyled>
        <h1>Vendors By Cities</h1>

        <div>
          <div className="filters">
            <button onClick={(e) => setFilterAchor(e.currentTarget)}>
              <FaFilter />
            </button>
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                type="text"
                placeholder="Search by city name"
              />
              <button>
                <MdSearch />
              </button>
            </form>
          </div>
          <table>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>City Name</th>
                <th>Number of Vendors</th>
              </tr>
            </thead>
            <tbody>
              {filteredVendorsByCityData
                ?.sort((a, b) => b.count - a.count)
                ?.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data?._id?.slice(0, -4)}</td>
                    <td>{data?.count}</td>
                  </tr>
                ))}

              {filteredVendorsByCityData?.length === 0 && (
                <tr>
                  <td className="width-full-row">
                    No Result Found. Try something else.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </VendorsAccordingToCitiesStyled>

      <StyledFilterMenu
        open={Boolean(filterAchor)}
        anchorEl={filterAchor}
        onClose={() => setFilterAchor(null)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {availableFilters?.map((v, i) => (
          <button
            onClick={() => {
              setQueryParam({ filter: v });
              setFilterAchor(null);
            }}
            key={i}
            className={filter === v ? "selected" : ""}
          >
            {v}
          </button>
        ))}
      </StyledFilterMenu>
    </>
  );
};

export default VendorsAccordingToCities;
