import styled from "@emotion/styled";

export const HeatMapStyled = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;

  & > p {
    font-size: 2.2rem;
    color: #4c4d4e;
    left: 0.2rem;
    top: 0.2rem;
    padding: 1.5rem 1.3rem;
    background-color: #fff;
    position: absolute;
    z-index: 5500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
  }
`;
