import { authConstant } from "./../constants";
import { adminLogin } from "./../../http";
import axios from "axios";
import { emitUserStateChange } from "../../utils";

export const AdminLogin = (admin) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.ADMIN_LOGIN_REQUEST });
    try {
      const result = await adminLogin(admin);
      const { data } = result;
      dispatch({
        type: authConstant.ADMIN_LOGIN_SUCCESS,
        payload: "Login Successfully",
      });
      sessionStorage.setItem("adminToken", data.tokens.access.token);
      sessionStorage.setItem("adminRefreshToken", data.tokens.refresh.token);
      sessionStorage.setItem("admin", JSON.stringify(data.user));

      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: authConstant.ADMIN_LOGIN_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const logOut = () => {
  return async (dispatch) => {
    dispatch({ type: authConstant.ADMIN_LOGOUT_REQUEST });
    try {
      sessionStorage.clear();
      dispatch({
        type: authConstant.ADMIN_LOGOUT_SUCCESS,
        payload: "Logout",
      });
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: authConstant.ADMIN_LOGOUT_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const ChangePassword = (body) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.CHANGE_PASSWORD_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/api/admin/change/password`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: authConstant.CHANGE_PASSWORD_SUCCESS,
        payload: "Password has been changed",
      });
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: authConstant.CHANGE_PASSWORD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: authConstant.CLEAR_ERRORS });
};

// Clearing Messages
export const clearMessages = () => async (dispatch) => {
  dispatch({ type: authConstant.CLEAR_MESSAGES });
};
