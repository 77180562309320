import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Container,
  Heading,
  FormInput,
  FormText,
  Button,
} from "../../components";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewReview } from "../../store/actions";
import { toast } from "react-hot-toast";
const AddReview = () => {
  const { loading } = useSelector((s) => s.reviewReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "Must be 2 character")
      .max(35, "Must be 35 characters or less")
      .required("Required"),
    time: Yup.string()
      .min(2, "Must be 2 character")
      .max(35, "Must be 35 characters or less")
      .required("Required"),
    review: Yup.string()
      .min(2, "Must be 2 character")
      .max(300, "Must be 300 characters or less")
      .required("Required"),
  });

  return (
    <>
      <Container className="extra-small">
        <Heading className="primary center m-4">Add Review</Heading>
        <Formik
          initialValues={{
            name: "",
            time: "",
            review: "",
          }}
          validateOnMount
          validationSchema={validation}
          onSubmit={(values, { resetForm }) => {
            dispatch(
              createNewReview(
                {
                  body: {
                    name: values.name,
                    time: values.time,
                    description: values.review,
                  },
                },
                () => {
                  toast.success("Review updated successfully!");
                  navigate("/reviews");
                }
              )
            );
          }}
        >
          {(formik) =>
            loading ? (
              <Puff
                height="60"
                width="60"
                radius="6"
                color="black"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            ) : (
              <Form>
                <FormInput label="Customer name" name="name" type="text" />
                <FormInput
                  label="How long ago was review submitted?"
                  name="time"
                  type="text"
                />
                <FormText
                  label="Enter review"
                  name="review"
                  type="text"
                  cols="100"
                  rows="10"
                />
                <Button className="btn-lighter rounded center m-2">
                  Add Review
                </Button>
              </Form>
            )
          }
        </Formik>
      </Container>
    </>
  );
};

export default AddReview;
