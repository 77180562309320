import { toast } from "react-hot-toast";
import history from "./history";

export const asyncAction = (cb, actionNames, onSuccess, onError) => {
  return async (dispatch, state, ...rest) => {
    try {
      dispatch({ type: actionNames.request });
      const token = sessionStorage.getItem("adminToken");

      const payload = await cb(
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        },
        dispatch,
        state,
        ...rest
      );
      dispatch({ type: actionNames.success, payload });
      onSuccess && onSuccess(payload);
      console.log(`${actionNames.success} =>`, payload);
    } catch (error) {
      dispatch({ type: actionNames.error });
      console.log(error);

      if (
        error?.response?.status === 401 &&
        error?.response?.data?.errors[0]?.message === "Not Authorize"
      ) {
        toast.error("Session has been expired.");
        sessionStorage.clear();
        history.navigate("/");
        return;
      }
      onError && onError(error);

      toast.error(
        error.response?.data?.errors[0]
          ? error.response?.data?.errors[0]?.message
          : "Something went wrong. Please try again later!"
      );
    }
  };
};

export default asyncAction;
