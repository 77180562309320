import React, { useCallback, useEffect, useState } from "react";
import "./Reviews.scss";
import { Container, Modal } from "../../components";
import { Link } from "react-router-dom";

import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Puff } from "react-loader-spinner";
import { deleteReview, getAllReviews } from "../../store/actions";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import toast from "react-hot-toast";

const Reviews = () => {
  const [deletionId, setDeletionId] = useState(null);
  const { loading, reviews } = useSelector((s) => s.reviewReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReviews());
  }, [dispatch]);

  const onDelete = useCallback(() => {
    dispatch(
      deleteReview({ body: deletionId }, () => {
        toast.success("Review deleted!");
        dispatch(getAllReviews());
      })
    );
    setDeletionId(null);
  }, [deletionId, dispatch]);

  return (
    <>
      <div className="m-5">
        <Container className="half">
          <div className="add_review_button m-3 align-item-right">
            <Link to="/add_review">Add review</Link>
          </div>

          {loading ? (
            <Puff
              height="60"
              width="60"
              radius="6"
              color="black"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          ) : reviews.length > 0 ? (
            reviews.map((review) => (
              <ReviewCard
                customer_name={review.name ?? "--"}
                posted_time={review?.time ?? "--"}
                review={review?.description ?? "--"}
                onClick={() => {
                  setDeletionId(review?._id);
                }}
                key={review?._id}
              />
            ))
          ) : (
            <h1>No record found!</h1>
          )}
        </Container>
      </div>

      <DeleteConfirmationModal
        onClose={() => setDeletionId(null)}
        open={Boolean(deletionId)}
        onConfirmation={onDelete}
      />
    </>
  );
};

export default Reviews;

const ReviewCard = (props) => {
  return (
    <>
      <div className="reviewcard m-6">
        <div className="reviewcard-container">
          <div className="space-between">
            <CardItem heading="Customer Name" content={props.customer_name} />
            <div className="delete-icon">
              <RiDeleteBin5Fill onClick={props.onClick} />
            </div>
          </div>
          <div className="m-1">
            <CardItem
              heading="Review submited time"
              content={props.posted_time}
            />
          </div>

          <CardItem heading="Review" content={props.review} />
        </div>
      </div>
    </>
  );
};

const CardItem = (props) => {
  return (
    <>
      <div className="carditem m-3">
        <h3>{props.heading}</h3>
        <p>{props.content}</p>
      </div>
    </>
  );
};
