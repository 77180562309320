import axios from "axios";
import { verificationConstants } from "../constants/verifications.constants";
import asyncAction from "../../utils/asyncAction";
import { toast } from "react-hot-toast";

export const getAllVerificationByStatus = (
  { status, page },
  onSuccess,
  onError
) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/verification?status=${status}&page=${page}&limit=16`,
        authHeaders
      );
      return {
        verifications: data?.results ?? [],
        totalPages: data?.totalPages ?? 1,
      };
    },
    verificationConstants.getAllVerificationByStatus,
    onSuccess,
    onError
  );
};

export const updateVerification = ({ body, _id }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      await axios.patch(
        `${process.env.REACT_APP_ROOT}/v1/api/verification/${_id}`,
        body,
        authHeaders
      );
      return {};
    },
    verificationConstants.updateVerification,
    onSuccess,
    onError
  );
};
