import React, { useEffect } from "react";
import { Container, Grid } from "../../components";
import "./Dashboard.scss";
import { subscriber, visitor } from "../../assests";
import toast from "react-hot-toast";
import {
  VisitorsByBrowser,
  GetAllSubscriber,
  clearErrors,
  GetAllCustomersList,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import HeatMap from "../../components/HeatMap/HeatMap";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    visitorsByBrowser,
    allSubscriber,
    allSubscriberLoading,
    errors,
    getAllCustomerDetailsData,
  } = useSelector((state) => state.adminReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
  }, [dispatch, errors]);

  useEffect(() => {
    if (visitorsByBrowser?.length <= 0) {
      dispatch(VisitorsByBrowser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (allSubscriber?.length <= 0) {
      dispatch(GetAllSubscriber());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !getAllCustomerDetailsData?.data ||
      getAllCustomerDetailsData?.data?.length <= 0
    ) {
      dispatch(GetAllCustomersList());
    }
  }, [dispatch, getAllCustomerDetailsData?.data]);

  const downloadCSV = (csvData = []) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const convertSubscriberArrayToCSV = (array) => {
    const csvRows = [
      [
        "Email",
        "Full Name",
        "User ID",
        "Stripe Customer Id",
        "Subscribe to Newsletter",
        "Subscribed Subscription Name",
        "Auto Renewal On",
        "Current Subscription stated at",
        "Current Subscription will end at",
      ],
    ];

    array.forEach((obj) => {
      csvRows.push([
        obj.email,
        obj.fullName,
        obj.userId,
        obj.customerId,
        obj.addedToMailList ? "Yes" : "No",
        obj.name,
        obj.autoRenewalOn ? "Yes" : "No",
        `"${new Intl.DateTimeFormat("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date(obj.current_period_start))}"`,
        `"${new Intl.DateTimeFormat("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date(obj.current_period_end))}"`,
      ]);
    });

    return csvRows.join("\n");
  };

  const handleSubscriberCSVDownload = () => {
    if (allSubscriber.length === 0) {
      toast.error(allSubscriberLoading ? "Please wait" : "No record found");
      return;
    }
    const csvData = convertSubscriberArrayToCSV(allSubscriber);
    downloadCSV(csvData);
  };

  const convertCustomerArrayToCSV = (array) => {
    const csvRows = [
      ["Email", "Full Name", "User ID", "Subscribe to Newsletter"],
    ];

    array.forEach((obj) => {
      csvRows.push([
        obj.email,
        obj.fullName,
        obj.userId,
        obj.addedToMailList ? "Yes" : "No",
      ]);
    });

    return csvRows.join("\n");
  };

  const handleCustomerCSVDownload = () => {
    if (getAllCustomerDetailsData?.data?.length === 0) {
      toast.error(
        getAllCustomerDetailsData?.loading ? "Please wait" : "No record found"
      );
      return;
    }
    const csvData = convertCustomerArrayToCSV(getAllCustomerDetailsData?.data);
    downloadCSV(csvData);
  };

  return (
    <>
      <Container className="md">
        <div className="m-5">
          <Grid className="grid-3">
            <DashbaordCard
              title="Visitors by Browsers"
              value={
                visitorsByBrowser?.length > 0 ? visitorsByBrowser?.length : 0
              }
              img={subscriber}
            />
            <DashbaordCard
              title="Subscriber"
              value={
                allSubscriberLoading
                  ? "Loading..."
                  : allSubscriber?.length > 0
                  ? allSubscriber?.length
                  : 0
              }
              img={visitor}
              onClick={handleSubscriberCSVDownload}
              disabled={allSubscriberLoading}
            />
            <DashbaordCard
              title="Customers"
              value={
                getAllCustomerDetailsData?.loading
                  ? "Loading..."
                  : getAllCustomerDetailsData?.data?.length > 0
                  ? getAllCustomerDetailsData?.data?.length
                  : 0
              }
              img={visitor}
              onClick={handleCustomerCSVDownload}
              disabled={getAllCustomerDetailsData?.loading}
            />
            <HeatMap onClick={() => navigate("/vendors-according-to-cities")} />
          </Grid>
          <br />
          <br />
        </div>
      </Container>
    </>
  );
};

export default Dashboard;

const DashbaordCard = (props) => {
  return (
    <>
      <div
        onClick={props.onClick}
        className={`dashboardcard ${props.disabled ? "" : "cursor"}`}
      >
        <div className="dashboardcard-containar">
          <p>{props.title}</p>
          <h3>{props.value}</h3>
          <img src={props.img} alt="wave" />
        </div>
      </div>
    </>
  );
};
