export const chatContants = {
  GET_ALL_CHATS: {
    request: "GET_ALL_CHATS_REQUEST",
    success: "GET_ALL_CHATS_SUCCESS",
    error: "GET_ALL_CHATS_FAILURE",
  },
  POST_NEW_CHAT: {
    request: "POST_NEW_CHAT_REQUEST",
    success: "POST_NEW_CHAT_SUCCESS",
    error: "POST_NEW_CHAT_FAILURE",
  },

  POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS: {
    request: "POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS_REQUEST",
    success: "POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS_SUCCESS",
    error: "POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS_FAILURE",
  },

  UPDATE_MESSAGE: "UPDATE_MESSAGE",
};
