import { reviewConstants } from "../constants";

const initialState = {
  loading: false,
  reviews: [],
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case reviewConstants.getAllReviews.request:
    case reviewConstants.createNewReview.request:
    case reviewConstants.deleteReview.request:
      return {
        ...state,
        loading: true,
      };
    case reviewConstants.getAllReviews.success:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case reviewConstants.getAllReviews.error:
    case reviewConstants.createNewReview.success:
    case reviewConstants.createNewReview.error:
    case reviewConstants.deleteReview.error:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reviewReducer;
