export const reviewConstants = {
  getAllReviews: {
    request: "GET_ALL_REVIEWS_REQUEST",
    success: "GET_ALL_REVIEWS_SUCCESS",
    error: "GET_ALL_REVIEWS_FAILURE",
  },
  createNewReview: {
    request: "CREATE_NEW_REVIEW_REQUEST",
    success: "CREATE_NEW_REVIEW_SUCCESS",
    error: "CREATE_NEW_REVIEW_FAILURE",
  },

  deleteReview: {
    request: "DELETE_REVIEW_REQUEST",
    success: "DELETE_REVIEW_SUCCESS",
    error: "DELETE_REVIEW_FAILURE",
  },
};
