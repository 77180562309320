import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";

const history = {
  navigate: null,
  hash: null,
  key: null,
  pathname: null,
  search: null,
  state: null,
  useSelector: {},
};

export const NavigateOutsideofComponent = () => {
  const navigate = useNavigate();
  const { hash, key, pathname, search, state } = useLocation();
  const store = useSelector((s) => s);

  useEffect(() => {
    history["navigate"] = navigate;
    history["hash"] = hash;
    history["key"] = key;
    history["pathname"] = pathname;
    history["search"] = search;
    history["state"] = state;
    history["useSelector"] = store;
  }, [navigate, hash, key, store, pathname, search, state]);

  return <></>;
};

export default history;
