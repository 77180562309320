import React, { useState, useEffect } from "react";
import { TbSearch } from "react-icons/tb";
import { profile } from "../../assests";
import { Container, Grid, Heading, UserCard } from "../../components";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import { GetAllVendor, clearErrors } from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_STATIC_URL } from "../../constants";
import { StyledOverflowScroll } from "../analytics/Analytics";
import { LayoutStyled } from "../customer/Customer";

const Vender = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { data, totalResults, errors, loading } = useSelector(
    (state) => state.adminReducer
  );

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
  }, [errors]);

  useEffect(() => {
    dispatch(GetAllVendor());
    setSearchText("");
  }, []);
  return (
    <StyledOverflowScroll>
      <div className="m-5">
        <Container className="lg">
          <Heading className="primary center m-5">Vendors</Heading>
          <div className="customerpage m-5">
            <div className="customerpage-search">
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <TbSearch />
            </div>
            <div className="customerpage-activeusers">
              <p>Active venderos</p>
              <p>{totalResults ? totalResults : 0}</p>
            </div>
          </div>
          <div className="m-5">
            <LayoutStyled>
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : data?.length > 0 ? (
                data
                  .filter(
                    (data) =>
                      `${data?.fullName} ${data?.userId || ""}`
                        ?.toLowerCase()
                        .search(searchText.toLowerCase()) !== -1
                  )
                  .map((item, ind) => {
                    return (
                      <UserCard
                        key={ind}
                        image={
                          item?.photoPath
                            ? `${BACKEND_STATIC_URL}${item?.photoPath}`
                            : profile
                        }
                        userId={item.userId}
                        name={item.fullName}
                        to={`/vender_detail/${item.id}`}
                      />
                    );
                  })
              ) : (
                <h1>No record found</h1>
              )}
            </LayoutStyled>
          </div>
        </Container>
      </div>
    </StyledOverflowScroll>
  );
};

export default Vender;
