import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import adminReducer from "./admin.reducer";
import verificationReducer from "./verification.reducer";
import servicesReducer from "./servcies.reducer";
import reviewReducer from "./review.reducer";
import occasionReducer from "./occasion.reducer";
import occasion2Reducer from "./occasion2.reducer";
import serviceTypeReducer from "./serviceType.reducer";
import chatReducer from "./chat.reducer";
import userInterface2Reducer from "./userInterface2.reducer";
import fileReducer from "./file.reducer";
import notificationReducer from "./notification.reducer";
import supportReducer from "./support.reducer";

const rootReducer = combineReducers({
  authReducer,
  verificationReducer,
  adminReducer,
  servicesReducer,
  reviewReducer,
  occasionReducer,
  serviceTypeReducer,
  occasion2Reducer,
  chatReducer,
  userInterface2Reducer,
  fileReducer,
  notificationReducer,
  supportReducer,
});

export default rootReducer;
