import { supportConstants } from "./../constants";

const initialState = {
  getAllSupportTicketsData: {
    data: [],
    loading: false,
  },
};

const supportReducer = (s = initialState, action) => {
  const state = JSON.parse(JSON.stringify(s));

  switch (action.type) {
    case supportConstants.GET_ALL_SUPPORT_TICKETS.request:
      state.getAllSupportTicketsData.loading = true;
      break;

    case supportConstants.GET_ALL_SUPPORT_TICKETS.success:
      state.getAllSupportTicketsData.loading = false;
      state.getAllSupportTicketsData.data = action.payload.data;
      break;

    case supportConstants.GET_ALL_SUPPORT_TICKETS.error:
      state.getAllSupportTicketsData.loading = false;
      break;

    default:
      break;
  }

  return state;
};

export default supportReducer;
