import { verificationConstants } from "../constants/verifications.constants";

const initialState = {
  loading: false,
  verifications: [],
  totalPages: 1,
  singleVerification: {},
};

const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case verificationConstants.getAllVerificationByStatus.request:
    case verificationConstants.updateVerification.request:
      return {
        ...state,
        loading: true,
      };
    case verificationConstants.getAllVerificationByStatus.success:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case verificationConstants.updateVerification.success:
      return {
        ...state,
        loading: false,
      };
    case verificationConstants.getAllVerificationByStatus.error:
    case verificationConstants.updateVerification.error:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default verificationReducer;
