import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSingleServiceType } from "../../store/actions";
import { Container, Heading, InfoItem } from "../../components";
import { useParams } from "react-router-dom";
import { BACKEND_STATIC_URL } from "../../constants";
import { CenteredLoader } from "../Chat/Chat";

const OccasionDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { singleServiceType: singleService, loading } = useSelector(
    (state) => state.serviceTypeReducer
  );

  useEffect(() => {
    dispatch(getSingleServiceType(id));
  }, [id, dispatch]);
  return loading ? (
    <div className="m-5">
      <CenteredLoader />
    </div>
  ) : (
    <div>
      <Container className="half">
        <div className="serviceTypeDetail m-5">
          <Heading className="primary center m-5">
            {singleService.serviceName && singleService.serviceName}
          </Heading>
        </div>

        <div className="serviceTypeDetail-container m-5">
          {/* <Heading className="center m-5">Images</Heading> */}
          <div className="serviceTypeDetail-container-image m-5">
            <div className="serviceTypeDetail-container-image-section">
              {singleService.serviceImage &&
                [singleService.serviceImage].map((item, ind) => {
                  return (
                    <img
                      key={ind}
                      src={`${BACKEND_STATIC_URL}${item}`}
                      alt="service-photos"
                      className="w-100"
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OccasionDetails;
