import { userInterfaceConstants2 } from "../constants";
const initialValues = {
  getUserInfaceData: {
    loading: false,
    data: null,
  },
  updateUserInterfaceData: {
    loading: false,
  },
};

const userInterface2Reducer = (state = initialValues, action) => {
  const s = { ...state };
  switch (action.type) {
    case userInterfaceConstants2.GET_USER_INTERFACE.request:
      s.getUserInfaceData.loading = true;
      break;
    case userInterfaceConstants2.GET_USER_INTERFACE.success:
      s.getUserInfaceData.loading = false;
      s.getUserInfaceData.data = action.payload;
      break;
    case userInterfaceConstants2.GET_USER_INTERFACE.error:
      s.getUserInfaceData.loading = false;
      break;

    case userInterfaceConstants2.UPDATE_USER_INTERFACE.request:
      s.updateUserInterfaceData.loading = true;
      break;
    case userInterfaceConstants2.UPDATE_USER_INTERFACE.success:
      s.updateUserInterfaceData.loading = false;
      break;
    case userInterfaceConstants2.UPDATE_USER_INTERFACE.error:
      s.updateUserInterfaceData.loading = false;
      break;

    default:
      break;
  }

  return s;
};

export default userInterface2Reducer;
