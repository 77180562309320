export { default as Login } from "./login/Login";
export { default as Analytics } from "./analytics/Analytics";
export { default as ChatWithCustomer } from "./chatwithCustomer/ChatWithCustomer";
export { default as ChatWithVender } from "./chatWithVender/ChatWithVender";
export { default as Customer } from "./customer/Customer";
export { default as Vender } from "./vender/Vender";
export { default as VenderServices } from "./venderServices/VenderServices";
export { default as VerificationRequest } from "./verificationRequest/VerificationRequest";
export { default as Wallet } from "./wallet/Wallet";
export { default as Dashboard } from "./dashboard/Dashboard";
export { default as Settings } from "./Settings/Settings";
export { default as InterfaceFeature } from "./interfaceFeature/InterfaceFeature";
export { default as MemberShip } from "./memberShip/MemberShip";
export { default as Faq } from "./Faq/Faq";
export { default as Reviews } from "./reviews/Reviews";
export { default as AddReview } from "./reviews/AddReview";
export { default as CustomerDetail } from "./customer/CustomerDetail";
export { default as VenderDetail } from "./vender/VenderDetail";
export { default as ServiceDetail } from "./serviceDetail/ServiceDetail";
export { default as VenderServiceGrid } from "./venderServices/VenderServicesGrid";
export { default as AddFaq } from "./Faq/AddFaq";
export { default as VerificationRequestDetail } from "./verificationRequest/VerificationRequestDetail";
export { default as PromoCode } from "./memberShip/PromoCode";
export { default as AddPromoCode } from "./memberShip/AddPromoCode";
export { default as ManageOccasions } from "./ManageOccasions/ManageOccasions";
export { default as Chat } from "./Chat/Chat";
export { default as VendorsAccordingToCities } from "./VendorsAccordingToCities/VendorsAccordingToCities";
export { default as SubscribersList } from "./SubscribersList/SubscribersList";
export { default as SupportTickets } from "./SupportTickets/SupportTickets";
