const FormHeading = (props) => {
  {
    const data = props.sort;
    if (data === "new") {
      return <h3>New</h3>;
    } else if (data === "accepted") {
      return <h3>Accepted</h3>;
    } else if (data === "rejected") {
      return <h3>Rejected</h3>;
    } else if (data === "hold") {
      return <h3>Hold</h3>;
    }
  }
};

export default FormHeading;
