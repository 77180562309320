import { authConstant, adminConstant } from "./../constants";

const initialState = {
  data: [],
  data2: [],
  vendorData: [],
  vendorServices: [],
  topFiveCustomer: [],
  topFiveServices: [],
  chatConnectionIds: [],
  topFiveVendors: [],
  dailyUser: [],
  weeklyUser: [],
  monthlyUser: [],
  visitorsByBrowser: [],
  transactionHistory: [],
  allSubscriber: [],
  allSubscriberLoading: false,
  singleVendorService: {},
  chatConnection: {},
  userInfo: {},
  vendorInfo: {},
  page: 1,
  totalPages: 1,
  totalResults: 0,
  errors: [],
  message: "",
  loading: false,
  vendorServiceLoading: false,

  getNumberOfVendorsByCityData: {
    loading: false,
    data: [],
  },

  getAllSubscriberListData: {
    loading: false,
    data: null,
  },

  getAllCustomerDetailsData: { loading: false, data: null },
};

const customerAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminConstant.CREATE_PROMO_CODE_REQUEST:
    case adminConstant.GET_PROMO_CODE_REQUEST:
    case adminConstant.DELETE_PROMO_CODE_REQUEST:
    case adminConstant.CREATE_INTERFACE_FEATURE_REQUEST:
    case adminConstant.GET_ALL_CUSTOMER_REQUEST:
    case adminConstant.GET_ALL_VENDOR_REQUEST:
    case adminConstant.GET_SINGLE_VENDOR_REQUEST:
    case adminConstant.DELETE_SINGLE_VENDOR_REQUEST:
    case adminConstant.GET_SINGLE_CUSTOMER_REQUEST:
    case adminConstant.DELETE_SINGLE_CUSTOMER_REQUEST:
    case adminConstant.GET_SERVICE_BY_STATUS_REQUEST:
    case adminConstant.ADD_FAQ_REQUEST:
    case adminConstant.GET_FAQ_REQUEST:
    case adminConstant.DELETE_FAQ_REQUEST:
    case adminConstant.GET_MEMBERSHIP_SUBSCRIPTION_REQUEST:
    case adminConstant.UPDATE_MEMBERSHIP_SUBSCRIPTION_REQUEST:
    case adminConstant.GET_SINGLE_SERVICE_REQUEST:
    case adminConstant.GET_TRANSACTION_HISTORY_REQUEST:
    case adminConstant.CREATE_CHAT_CONNECTION_REQUEST:
    case adminConstant.GET_CUSTOMER_FOR_CHAT_REQUEST:
    case adminConstant.GET_VENDOR_FOR_CHAT_REQUEST:
    case adminConstant.GET_ALL_VENDOR_FOR_CHAT_REQUEST:
    case adminConstant.GET_ALL_CHATS_IDS_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        errors: [],
      };
    case adminConstant.GET_VENDOR_ALL_SERVICE_REQUEST:
    case adminConstant.UPDATE_SERVICE_BY_STATUS_REQUEST:
      return {
        ...state,
        vendorServiceLoading: true,
        message: "",
        errors: [],
      };
    case adminConstant.CREATE_PROMO_CODE_SUCCESS:
    case adminConstant.DELETE_PROMO_CODE_SUCCESS:
    case adminConstant.CREATE_INTERFACE_FEATURE_SUCCESS:
    case adminConstant.DELETE_SINGLE_VENDOR_SUCCESS:
    case adminConstant.DELETE_SINGLE_CUSTOMER_SUCCESS:
    case adminConstant.ADD_FAQ_SUCCESS:
    case adminConstant.DELETE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case adminConstant.GET_TOP_FIVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        topFiveCustomer: action.payload,
      };
    case adminConstant.GET_TOP_FIVE_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        topFiveServices: action.payload,
      };
    case adminConstant.GET_TOP_FIVE_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        topFiveVendors: action.payload,
      };
    case adminConstant.GET_DAILY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyUser: action.payload,
      };
    case adminConstant.GET_WEEKLY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklyUser: action.payload,
      };
    case adminConstant.GET_MONTHLY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyUser: action.payload,
      };
    case adminConstant.CREATE_CHAT_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        chatConnection: action.payload.results,
      };
    case adminConstant.VISITORS_BY_BROWSERS_SUCCESS:
      return {
        ...state,
        loading: false,
        visitorsByBrowser: action.payload,
      };
    case adminConstant.GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionHistory: action.payload,
      };
    case adminConstant.UPDATE_MEMBERSHIP_SUBSCRIPTION_SUCCESS:
    case adminConstant.UPDATE_SERVICE_BY_STATUS_SUCCESS:
      return {
        ...state,
        vendorServiceLoading: false,
        loading: false,
        message: action.payload,
        data: [],
      };
    case adminConstant.GET_SINGLE_VENDOR_SUCCESS:
    case adminConstant.GET_SINGLE_CUSTOMER_SUCCESS:
    case adminConstant.GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case adminConstant.GET_CUSTOMER_FOR_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    case adminConstant.GET_ALL_CHATS_IDS_SUCCESS:
      return {
        ...state,
        loading: false,
        chatConnectionIds: action.payload,
      };
    case adminConstant.GET_VENDOR_FOR_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorInfo: action.payload,
      };
    case adminConstant.GET_SINGLE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleVendorService: action.payload,
      };
    case adminConstant.GET_ALL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        allSubscriberLoading: true,
      };
    case adminConstant.GET_ALL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        allSubscriberLoading: false,
        allSubscriber: action.payload,
      };
    case adminConstant.GET_PROMO_CODE_SUCCESS:
    case adminConstant.GET_SERVICE_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
      };
    case adminConstant.GET_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        data2: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
      };
    case adminConstant.GET_ALL_CUSTOMER_SUCCESS:
    case adminConstant.GET_ALL_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };
    case adminConstant.GET_ALL_VENDOR_FOR_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorData: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };
    case adminConstant.GET_VENDOR_ALL_SERVICE_SUCCESS:
      return {
        ...state,
        vendorServiceLoading: false,
        vendorServices: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
      };

    case adminConstant.GET_NUMBER_OF_VENDORS_BY_CITY_REQUEST:
      return {
        ...state,
        getNumberOfVendorsByCityData: {
          ...state.getNumberOfVendorsByCityData,
          loading: true,
        },
      };
    case adminConstant.GET_NUMBER_OF_VENDORS_BY_CITY_SUCCESS:
      return {
        ...state,
        getNumberOfVendorsByCityData: {
          ...state.getNumberOfVendorsByCityData,
          loading: false,
          data: action.payload,
        },
      };
    case adminConstant.GET_NUMBER_OF_VENDORS_BY_CITY_FAILURE:
      return {
        ...state,
        getNumberOfVendorsByCityData: {
          ...state.getNumberOfVendorsByCityData,
          loading: false,
        },
      };

    case adminConstant.GET_ALL_SUBSCRIBER_LIST_REQUEST:
      return {
        ...state,
        getAllSubscriberListData: {
          ...state.getAllSubscriberListData,
          loading: true,
        },
      };
    case adminConstant.GET_ALL_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        getAllSubscriberListData: {
          ...state.getAllSubscriberListData,
          loading: false,
          data: action.payload,
        },
      };
    case adminConstant.GET_ALL_SUBSCRIBER_LIST_FAILURE:
      return {
        ...state,
        getAllSubscriberListData: {
          ...state.getAllSubscriberListData,
          loading: false,
        },
      };

    case adminConstant.GET_ALL_CUSTOMERS_LIST_REQUEST:
      return {
        ...state,
        getAllCustomerDetailsData: {
          ...state.getAllCustomerDetailsData,
          loading: true,
        },
      };
    case adminConstant.GET_ALL_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        getAllCustomerDetailsData: {
          ...state.getAllCustomerDetailsData,
          loading: false,
          data: action.payload,
        },
      };
    case adminConstant.GET_ALL_CUSTOMERS_LIST_FAILURE:
      return {
        ...state,
        getAllCustomerDetailsData: {
          ...state.getAllCustomerDetailsData,
          loading: false,
        },
      };

    case adminConstant.RESET_ADMIN_REDUCER:
      return {
        ...state,
        ...initialState,
      };
    case adminConstant.CREATE_PROMO_CODE_FAILURE:
    case adminConstant.GET_PROMO_CODE_FAILURE:
    case adminConstant.DELETE_PROMO_CODE_FAILURE:
    case adminConstant.CREATE_INTERFACE_FEATURE_FAILURE:
    case adminConstant.GET_ALL_CUSTOMER_FAILURE:
    case adminConstant.GET_ALL_VENDOR_FAILURE:
    case adminConstant.GET_SINGLE_VENDOR_FAILURE:
    case adminConstant.DELETE_SINGLE_VENDOR_FAILURE:
    case adminConstant.GET_SINGLE_CUSTOMER_FAILURE:
    case adminConstant.DELETE_SINGLE_CUSTOMER_FAILURE:
    case adminConstant.GET_SERVICE_BY_STATUS_FAILURE:
    case adminConstant.ADD_FAQ_FAILURE:
    case adminConstant.GET_FAQ_FAILURE:
    case adminConstant.DELETE_FAQ_FAILURE:
    case adminConstant.GET_MEMBERSHIP_SUBSCRIPTION_FAILURE:
    case adminConstant.UPDATE_MEMBERSHIP_SUBSCRIPTION_FAILURE:
    case adminConstant.GET_SINGLE_SERVICE_FAILURE:
    case adminConstant.GET_VENDOR_ALL_SERVICE_FAILURE:
    case adminConstant.UPDATE_SERVICE_BY_STATUS_FAILURE:
    case adminConstant.GET_TOP_FIVE_CUSTOMER_FAILURE:
    case adminConstant.GET_TOP_FIVE_SERVICES_FAILURE:
    case adminConstant.GET_TOP_FIVE_VENDOR_FAILURE:
    case adminConstant.GET_DAILY_USER_FAILURE:
    case adminConstant.GET_WEEKLY_USER_FAILURE:
    case adminConstant.GET_MONTHLY_USER_FAILURE:
    case adminConstant.VISITORS_BY_BROWSERS_FAILURE:
    case adminConstant.GET_TRANSACTION_HISTORY_FAILURE:
    case adminConstant.CREATE_CHAT_CONNECTION_FAILURE:
    case adminConstant.ADD_MESSAGE_FAILURE:
    case adminConstant.GET_CUSTOMER_FOR_CHAT_FAILURE:
    case adminConstant.GET_VENDOR_FOR_CHAT_FAILURE:
    case adminConstant.GET_ALL_VENDOR_FOR_CHAT_FAILURE:
    case adminConstant.GET_ALL_CHATS_IDS_FAILURE:
      return {
        ...state,
        vendorServiceLoading: false,
        loading: false,
        errors: action.payload.err,
        message: "",
      };

    case adminConstant.GET_ALL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        allSubscriberLoading: false,
        errors: action.payload.err,
      };

    case authConstant.CLEAR_MESSAGES:
      return {
        ...state,
        loading: false,
        vendorServiceLoading: false,
        message: "",
        errors: [],
      };
    case authConstant.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        vendorServiceLoading: false,
        errors: [],
        message: "",
      };
    default:
      return state;
  }
};

export default customerAuthReducer;
