import axios from "axios";
import asyncAction from "../../utils/asyncAction";
import { occasion2Constants } from "../constants";

export const getAllOccasions2 = (payload, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions`,
        authHeaders
      );
      return data;
    },
    occasion2Constants.getAllOccasions,
    onSuccess,
    onError
  );
};

export const createOccasion2 = ({ body }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions`,
        body,
        authHeaders
      );
      return data;
    },
    occasion2Constants.createOccasion,
    onSuccess,
    onError
  );
};

export const getSingleOccasion2 = ({ _id }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions/${_id}`,
        authHeaders
      );
      return data;
    },
    occasion2Constants.getSingleOccasion,
    onSuccess,
    onError
  );
};

export const editOccasion2 = ({ _id, body }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.patch(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions/${_id}`,
        body,
        authHeaders
      );
      return data;
    },
    occasion2Constants.editOccasion,
    onSuccess,
    onError
  );
};

export const deleteOccasion2 = ({ _id }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions/${_id}`,
        authHeaders
      );
      return data;
    },
    occasion2Constants.deleteOccasion,
    onSuccess,
    onError
  );
};
