export const userInterfaceConstants2 = {
  GET_USER_INTERFACE: {
    request: "GET_USER_INTERFACE_REQUEST",
    success: "GET_USER_INTERFACE_SUCCESS",
    error: "GET_USER_INTERFACE_FAILURE",
  },
  UPDATE_USER_INTERFACE: {
    request: "UPDATE_USER_INTERFACE_REQUEST",
    success: "UPDATE_USER_INTERFACE_SUCCESS",
    error: "UPDATE_USER_INTERFACE_FAILURE",
  },
};
