import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubscriberList } from "../../store/actions";
import Papa from "papaparse";

const SubscribersList = () => {
  const dispatch = useDispatch();
  const { getAllSubscriberListData } = useSelector((s) => s.adminReducer);

  const generateLink = useCallback(() => {
    dispatch(getAllSubscriberList());
  }, [dispatch]);

  const downloadCSVFile = useCallback(
    (role) => {
      const csvData = [["Email"]].concat(
        getAllSubscriberListData?.data
          ?.filter((obj) => obj.role === role)
          .map(({ email }) => [email])
      );
      const csvContent = new Blob([Papa.unparse(csvData)], {
        type: "text/csv;charset=utf-8",
      });
      const url = window.URL.createObjectURL(csvContent);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${role === "User" ? "Customers" : "Vendors"}.csv`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    },
    [getAllSubscriberListData?.data]
  );

  return (
    <SubscribersListStyled>
      {getAllSubscriberListData?.data ? (
        <>
          <div>
            <Button onClick={() => downloadCSVFile("Vendor")}>
              Download Vendors CSV File
            </Button>
            <br />
            <br />
            <Button onClick={() => downloadCSVFile("User")}>
              Download Customers CSV File
            </Button>
          </div>
        </>
      ) : (
        <Button
          onClick={generateLink}
          disabled={getAllSubscriberListData.loading}
        >
          Generate Download Links
        </Button>
      )}
    </SubscribersListStyled>
  );
};

export default SubscribersList;

export const SubscribersListStyled = styled.div`
  display: grid;
  min-height: 50rem;
  place-items: center;

  & > div {
    display: grid;
    grid-template-columns: auto;

    & > div {
      margin: 0 auto;
    }
  }
`;
