import React, { useEffect } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import { AdminLogin, clearErrors, clearMessages } from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoginLayout } from "../../layout";
import { Button, FormInput } from "../../components";
import { login } from "../../assests";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.authReducer
  );
  const validation = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/dashboard"), 2000);
    }
  }, [errors, message]);

  return (
    <>
      <LoginLayout image={login} heading="Login">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validateOnMount
          validationSchema={validation}
          onSubmit={(values, { resetForm }) => {
            dispatch(AdminLogin(values));
            resetForm({ values: "" });
          }}
        >
          {(formik) => (
            <Form>
              <FormInput label="Enter your email" name="email" type="email" />
              <FormInput
                label="Enter  your password"
                name="password"
                type="password"
              />
              <Button className="btn-lighter  btn-full rounded center m-2">
                {loading ? (
                  <Puff
                    height="25"
                    width="30"
                    radius="6"
                    color="white"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                  />
                ) : (
                  "Login"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </LoginLayout>
    </>
  );
};

export default Login;
