import axios from "axios";
import asyncAction from "../../utils/asyncAction";
import { reviewConstants } from "../constants";

export const getAllReviews = (payload, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/interface/get/review`,
        authHeaders
      );
      return {
        reviews: data ?? [],
      };
    },
    reviewConstants.getAllReviews,
    onSuccess,
    onError
  );
};

export const createNewReview = ({ body }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/api/interface/create/review`,
        body,
        authHeaders
      );

      return {};
    },
    reviewConstants.createNewReview,
    onSuccess,
    onError
  );
};

export const deleteReview = ({ body }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      await axios.delete(
        `${process.env.REACT_APP_ROOT}/v1/api/interface/create/review/${body}`,
        authHeaders
      );

      return {};
    },
    reviewConstants.createNewReview,
    onSuccess,
    onError
  );
};
