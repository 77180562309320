import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AddFAQ, clearMessages, clearErrors } from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Heading,
  FormInput,
  FormText,
  Button,
  GoBackBtn,
} from "../../components";
const AddFaq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.adminReducer
  );
  const validation = Yup.object({
    question: Yup.string()
      .min(2, "Must be 2 character")
      .max(35, "Must be 35 characters or less")
      .required("Required"),
    answer: Yup.string()
      .min(2, "Must be 2 character")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
  });

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/faqs"), 2000);
    }
  }, [errors, message]);

  return (
    <>
      <Container className="lg">
        <GoBackBtn />
      </Container>
      <Container className="extra-small">
        <Heading className="primary center m-4">Add Faq's</Heading>
        <Formik
          initialValues={{
            question: "",
            answer: "",
          }}
          validateOnMount
          validationSchema={validation}
          onSubmit={(values, { resetForm }) => {
            dispatch(AddFAQ(values));
            resetForm({ values: "" });
          }}
        >
          {(formik) => (
            <Form>
              <FormInput label="Question" name="question" type="text" />
              <FormText
                label="Answer"
                name="answer"
                type="text"
                cols="100"
                rows="10"
              />
              <Button className="btn-lighter rounded center m-2">
                {loading ? "Please wait..." : "Add Faq"}
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AddFaq;
