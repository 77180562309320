import { Modal } from "@mui/material";
import "./styles.scss";
const DeleteConfirmationModal = ({
  open,
  onClose,
  onConfirmation,
  disabled,
}) => {
  return (
    <Modal
      open={open}
      onClose={!disabled && onClose}
      className="deletion-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Confirm Delete</h6>
          <p>Are you sure you want to delete this item?</p>
        </div>
        <div className="bottom">
          <button
            type="submit"
            onClick={onConfirmation}
            className="button-2l3jflkj2"
            disabled={disabled}
          >
            {disabled ? "deleting..." : "Yes"}
          </button>
          <button
            onClick={onClose}
            className="button-glk2jlk6j"
            disabled={disabled}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteConfirmationModal;
