import styled from "@emotion/styled";
import { Menu } from "@mui/material";

export const VendorsAccordingToCitiesStyled = styled.div`
  &,
  & * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }

  display: grid;
  gap: 2rem;
  max-width: 70rem;
  margin: 0 auto !important;
  width: 100%;
  padding: 5rem 2rem !important;

  & > h1 {
    color: #4c4d4e;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
  }

  & > div {
    display: grid;
    gap: 1rem;

    & > .filters {
      display: grid;
      align-items: center;
      justify-content: end;
      grid-template-columns: auto auto;
      gap: 1rem;

      & > button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        will-change: opacity;

        &:hover {
          opacity: 0.7;
        }
      }

      & > form {
        border: 0.1rem solid rgba(0, 0, 0, 0.3);
        max-width: 50vw;
        display: grid;
        grid-template-columns: 1fr auto;
        border-radius: 1000rem;
        overflow: hidden;

        & > input {
          border: none;
          outline: none;
          padding: 0.4rem 0.8rem;
        }
        & > button {
          display: grid;
          place-items: center;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          padding-right: 0.8rem;
        }
      }
    }

    & > table {
      display: grid;
      grid-template-columns: 1fr;
      border: none;
      overflow: auto;
      max-width: 100%;

      & > thead,
      & > tbody {
        display: grid;
        grid-template-columns: 1fr;
        & > tr {
          display: grid;
          grid-template-columns: 8rem 1fr 18rem;
          border: none;
          border-top: 0.1rem solid rgba(0, 0, 0, 0.3);

          &:has(td):last-child {
            border-bottom: 0.1rem solid rgba(0, 0, 0, 0.3);
          }

          & > th,
          & > td {
            display: grid;
            border: none;
            border-left: 0.1rem solid rgba(0, 0, 0, 0.3);
            &:last-child {
              border-right: 0.1rem solid rgba(0, 0, 0, 0.3);
            }
            padding: 0.8rem 1.2rem;

            font-size: 1.4rem;
            text-align: left;

            &:first-child,
            &:last-child {
              text-align: center;
            }

            &.width-full-row {
              grid-column: 1/4;
            }
          }
        }
      }
    }
  }
`;

export const StyledFilterMenu = styled(Menu)`
  & > div.MuiMenu-paper {
    margin-top: 1rem;
    border-radius: 0.4rem;

    & > ul {
      padding: 0;
      display: grid;
      min-width: 15rem;

      & > button {
        display: grid;
        padding: 0.6rem 1.6rem;
        text-align: left;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
        transition: background-color 0.3s ease-in-out;
        will-change: background-color;

        &:not(.selected):hover {
          background-color: #ffc94160;
        }

        &.selected {
          background-color: #ffc941;
        }
      }
    }
  }
`;
