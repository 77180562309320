export const verificationConstants = {
  getAllVerificationByStatus: {
    request: "GET_ALL_VERIFICATIONS_BY_STATUS_REQUEST",
    success: "GET_ALL_VERIFICATIONS_BY_STATUS_SUCCESS",
    error: "GET_ALL_VERIFICATIONS_BY_STATUS_FAILURE",
  },
  updateVerification: {
    request: "UDPATE_VERIFICATION_REQUEST",
    success: "UDPATE_VERIFICATION_SUCCESS",
    error: "UDPATE_VERIFICATION_FAILURE",
  },
};
