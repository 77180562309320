import React, { useState, useEffect } from "react";
import { Button, Container, GoBackBtn, LinkBtn } from "../../components";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import toast from "react-hot-toast";
import {
  CreatePromoCode,
  clearErrors,
  clearMessages,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const AddPromoCode = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [percent_off, setPercentOff] = useState(0);
  const [duration_in_months, setDurationInMonth] = useState(0);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.adminReducer
  );

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/promocode"), 2000);
    }
  }, [errors, message]);

  const validate = (obj) => {
    const schema = Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .matches(/^\S*$/, "Name should not contain any space")
        .matches(
          /^[A-Za-z0-9_-]+$/,
          "Promo code name consists of any lowercase, any uppercase and underscore only."
        ),
      percent_off: Yup.number()
        .typeError("Discount must be a number")
        .min(1, "Discount must be greater than or equal to 1")
        .max(100, "Discount must be less than or equal to 100")
        .required("Discount is required"),
      duration_in_months: Yup.number()
        .typeError("Expires on must be a number")
        .min(1, "Expires on must be greater than or equal to 1")
        .max(12, "Expires must be less than or equal to 12")
        .required("Expires on is required"),
      description: Yup.string().min(10).required("Description is required"),
    });

    try {
      schema.validateSync(obj);
      return true;
    } catch (error) {
      toast.error(error.errors[0]);
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = {
      name,
      percent_off,
      duration_in_months,
      productId: id,
      description,
    };

    if (!validate(result)) {
      return;
    }

    dispatch(CreatePromoCode(result));
    setName("");
    setPercentOff("");
    setDurationInMonth("");
    setDescription("");
  };
  return (
    <>
      <Container className="lg">
        <GoBackBtn />
        <Button onClick={() => navigate("/promocode")}>View Promo Code</Button>
      </Container>

      <Container className="extra-small">
        <div className="m-6">
          <div className="addpromocode">
            <div className="addpromocode-container">
              <form>
                <div className="promocode-name">
                  <label>Promo Code Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    maxLength={11}
                  />
                </div>

                <div className="promocode-discount m-3">
                  <label>Discount (%)</label>
                  <div className="promocode-discount-feild">
                    <div className="promocode-discount-feild-minus">
                      <AiOutlineMinus
                        onClick={() => setPercentOff(percent_off - 1)}
                      />
                    </div>
                    <input
                      type="text"
                      value={percent_off}
                      onChange={(e) => setPercentOff(e.target.value)}
                    />
                    <div className="promocode-discount-feild-plus">
                      <AiOutlinePlus
                        onClick={() => setPercentOff(percent_off + 1)}
                      />
                    </div>
                  </div>
                </div>

                <div className="promocode-expire">
                  <label>Expires on: (month)</label>
                  <div className="promocode-expire-input">
                    <input
                      type="number"
                      placeholder="Enter duration in months"
                      onChange={(e) => setDurationInMonth(e.target.value)}
                    />
                  </div>
                </div>

                <div className="promocode-name" style={{ marginTop: "3rem" }}>
                  <label>Description</label>
                  <textarea
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <Button
                  className="btn-lighter rounded center m-4"
                  onClick={(e) => handleSubmit(e)}
                >
                  {loading ? "Please wait..." : "Save"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AddPromoCode;
