import {
  BsFillChatTextFill,
  BsGraphUpArrow,
  BsFillBriefcaseFill,
  BsFillQuestionCircleFill,
  BsTicket,
} from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { RiDashboardFill } from "react-icons/ri";

import {
  MdRoomService,
  MdRateReview,
  MdVerified,
  MdOutlineAccountBalanceWallet,
  MdMail,
} from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import { FaPeopleArrows } from "react-icons/fa";
import { TbTicket } from "react-icons/tb";

export const adminRoutes = [
  {
    id: 1,
    path: "/dashboard",
    name: "Dashboard",
    icon: <RiDashboardFill />,
  },
  {
    id: 2,
    path: "/vender-services",
    name: "Vendor services",
    icon: <MdRoomService />,
  },
  {
    id: 21,
    path: "/manage-services",
    name: "Manage Services",
    icon: <MdRoomService />,
  },
  {
    id: 223,
    path: "/manage-occasions",
    name: "Manage Occasions",
    icon: <MdRoomService />,
  },
  {
    id: 3,
    path: "/analytics",
    name: "Analytics",
    icon: <BsGraphUpArrow />,
  },
  {
    id: 4,
    path: "/interface-features",
    name: "User interface features",
    icon: <HiOutlineBars3BottomLeft />,
  },
  {
    id: 14,
    path: "/reviews",
    name: "Reviews",
    icon: <MdRateReview />,
  },
  {
    id: 5,
    path: "/customer",
    name: "Customer",
    icon: <HiUserGroup />,
  },
  {
    id: 6,
    path: "/vender",
    name: "Vendor",
    icon: <FaPeopleArrows />,
  },
  {
    id: 7,
    path: "/chat/vendor",
    name: "Message to vendor",
    icon: <BsFillChatTextFill />,
  },
  {
    id: 8,
    path: "/chat/customer",
    name: "Message to customer",
    icon: <BsFillChatTextFill />,
  },
  {
    id: 9,
    path: "/membership",
    name: "Membership",
    icon: <BsFillBriefcaseFill />,
  },
  {
    id: 10,
    path: "/settings",
    name: "Settings",
    icon: <FiSettings />,
  },
  {
    id: 11,
    path: "/faqs",
    name: "FAQs",
    icon: <BsFillQuestionCircleFill />,
  },
  // {
  //   id: 12,
  //   path: "/verification-request",
  //   name: "Verification request",
  //   icon: <MdVerified />,
  // },
  {
    id: 1232421,
    path: "/subscribers",
    name: "Subscribers",
    icon: <MdMail />,
  },
  {
    id: 2532064421,
    path: "/support-tickets",
    name: "Support tickets",
    icon: <TbTicket />,
  },
  {
    id: 13,
    path: "/wallet",
    name: "Wallet",
    icon: <MdOutlineAccountBalanceWallet />,
  },
];
