import React, { useCallback, useMemo } from "react";
import "./PanelLayout.scss";
import { Outlet } from "react-router-dom";
import { Footer, Navbar, SideMenuBar } from "../../components/common";
import { adminRoutes } from "../../data/routesData";
import {
  getNumberOfUnseenMessages,
  getOtherSideParticipant,
} from "../../utils/getChat";
import { useSelector } from "react-redux";

const PanelLayout = () => {
  const { allMessages, getAllChatsData } = useSelector((s) => s.chatReducer);

  const numberOfNoficationsByRole = useCallback(
    (role) =>
      getAllChatsData?.data?.reduce((prev, c) => {
        if (getOtherSideParticipant(c)?.role === role) {
          prev += getNumberOfUnseenMessages(c?._id, allMessages);
        }
        return prev;
      }, 0),
    [allMessages, getAllChatsData?.data]
  );

  const routes = useMemo(() => {
    return adminRoutes.map((route) => {
      switch (route.path) {
        case "/chat/vendor":
          route.notification = numberOfNoficationsByRole("Vendor");
          return route;
        case "/chat/customer":
          route.notification = numberOfNoficationsByRole("User");
          return route;
        default:
          return route;
      }
    });
  }, [numberOfNoficationsByRole]);

  return (
    <>
      <div className="customerdashboardlayout">
        <div className="customerdashboardlayout-container">
          <SideMenuBar routes={routes} />
          <div className="customerdashboardlayout-container-content">
            <div className="customerdashboardlayout-container-content-nav">
              <Navbar />
            </div>
            <div className="customerdashboardlayout-container-content-outlet">
              <Outlet />
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelLayout;
