import axios from "axios";
import { BACKEND_URL } from "../../constants";
import { chatContants } from "../constants";
import asyncAction from "../../utils/asyncAction";

export const getAllChats = ({ payload } = {}, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/chat`,
        authHeaders
      );
      return { data };
    },
    chatContants.GET_ALL_CHATS,
    onSuccess,
    onError
  );
};

export const createNewChat = (
  { payload: { body } } = {},
  onSuccess,
  onError
) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.post(
        `${BACKEND_URL}/v1/api/chat`,
        body,
        authHeaders
      );
      return data;
    },
    chatContants.POST_NEW_CHAT,
    onSuccess,
    onError
  );
};

export const createNewChatsWithVendorsAndCustomers = (
  { payload } = {},
  onSuccess,
  onError
) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.post(
        `${BACKEND_URL}/v1/api/chat/create-with-vendors-and-customers`,
        {},
        authHeaders
      );
      return data;
    },
    chatContants.POST_NEW_CHATS_WITH_VENDORS_AND_CUSTOMERS,
    onSuccess,
    onError
  );
};

export const updateMessages = (payload) => ({
  type: chatContants.UPDATE_MESSAGE,
  payload,
});
