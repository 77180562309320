import axios from "axios";
import asyncAction from "../../utils/asyncAction";
import { servicesTypeConstants } from "../constants";

export const createServiceType = (data) => {
  return async (dispatch) => {
    //print the form data
    console.log(data);
    //print form data:
    for (var value of data.values()) {
      console.log(value);
    }
    dispatch({ type: servicesTypeConstants.createServiceType.request });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/api/admin-services`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res);
      if (res.status === 201) {
        dispatch({
          type: servicesTypeConstants.createServiceType.success,
          payload: { message: "Service Type Created Successfully" },
        });
        dispatch(getAllServicesTypes());
      } else {
        console.log(res.message);
        dispatch({
          type: servicesTypeConstants.createServiceType.error,
          payload: { err: "ServiceType Not Created" },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllServicesTypes = () => {
  return async (dispatch) => {
    dispatch({ type: servicesTypeConstants.getAllServicesTypes.request });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/admin-services`
      );
      console.log(res.data);
      dispatch({
        type: servicesTypeConstants.getAllServicesTypes.success,
        payload: { serviceTypes: res.data },
      });
    } catch (error) {
      dispatch({
        type: servicesTypeConstants.getAllServicesTypes.error,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};
export const deleteServiceType = (id, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: servicesTypeConstants.deleteServiceType.request });
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_ROOT}/v1/api/admin-services/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
          },
        }
      );
      console.log(res.data);
      dispatch({
        type: servicesTypeConstants.deleteServiceType.success,
        payload: { serviceTypes: res.data },
      });
      dispatch(getAllServicesTypes());
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: servicesTypeConstants.deleteServiceType.error,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};
export const getSingleServiceType = (id) => {
  return async (dispatch) => {
    dispatch({ type: servicesTypeConstants.getSingleServiceType.request });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/admin-services/${id}`
      );
      console.log(res.data);
      dispatch({
        type: servicesTypeConstants.getSingleServiceType.success,
        payload: { singleServiceType: res.data },
      });
    } catch (error) {
      dispatch({
        type: servicesTypeConstants.getSingleServiceType.error,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const updateServiceType = (data, id) => async (dispatch) => {
  dispatch({ type: servicesTypeConstants.createServiceType.request });
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_ROOT}/v1/api/admin-services/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(res);
    if (res.status === 200) {
      dispatch({
        type: servicesTypeConstants.createServiceType.success,
        payload: { message: "ServiceType Updated Successfully" },
      });
    } else {
      console.log(res.message);
      dispatch({
        type: servicesTypeConstants.createServiceType.error,
        payload: { err: "ServiceType Not Updated" },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const clearServiceTypeErrors = () => async (dispatch) => {
  dispatch({ type: servicesTypeConstants.clearErrors });
};
export const clearServiceTypeMessages = () => async (dispatch) => {
  dispatch({ type: servicesTypeConstants.clearMessages });
};
