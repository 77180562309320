import React from "react";
import { Messages } from "../../components";
import { dummy__messages, userData } from "../../data/messageData";
const ChatWithCustomer = () => {
  return (
    <>
      <Messages users={userData} messages={dummy__messages} />
    </>
  );
};

export default ChatWithCustomer;
