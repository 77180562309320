import axios from "axios";
import { occasionConstant } from "../constants";

export const getAllOccasions = () => {
  return async (dispatch) => {
    dispatch({ type: occasionConstant.GET_ALL_OCCASION.REQUEST });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions`
      );
      dispatch({
        type: occasionConstant.GET_ALL_OCCASION.SUCCESS,
        payload: { occasions: res.data },
      });
    } catch (error) {
      dispatch({
        type: occasionConstant.GET_ALL_OCCASION.FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

//ids: ObjectId[]
export const addFeaturedOccasion = (ids, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: occasionConstant.ADD_FEATURED_OCCASION.REQUEST });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions/top`,
        { occasions: ids },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
          },
        }
      );
      dispatch({
        type: occasionConstant.ADD_FEATURED_OCCASION.SUCCESS,
        payload: { occasions: res.data },
      });

      onSuccess && onSuccess();
    } catch (error) {
      console.log(error);
      dispatch({
        type: occasionConstant.ADD_FEATURED_OCCASION.FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const getFeaturedOccasion = (onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: occasionConstant.GET_FEATURED_OCCASION.REQUEST });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/occasions/top`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminToken")}`,
          },
        }
      );
      dispatch({
        type: occasionConstant.GET_FEATURED_OCCASION.SUCCESS,
        payload: { occasions: res.data },
      });

      onSuccess && onSuccess();
    } catch (error) {
      console.log(error);
      dispatch({
        type: occasionConstant.GET_FEATURED_OCCASION.FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const clearOccasionsErrors = () => async (dispatch) => {
  dispatch({ type: occasionConstant.CLEAR_ERRORS });
};
export const clearOccasionMessages = () => async (dispatch) => {
  dispatch({ type: occasionConstant.CLEAR_MESSAGES });
};
