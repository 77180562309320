import React from "react";
import { Link } from "react-router-dom";
import "./UserCard.scss";
import { NewBlink } from "../../assests";
const UserCard = (props) => {
  return (
    <>
      <Link to={props.to} title={props.name}>
        <div className="usercard">
          {props.show && <img src={NewBlink} alt="New" />}
          <div className="usercard-container">
            <img src={props.image && props.image} alt="profile" />
            <div>
              <h5>{props.name}</h5>
              {props.userId && <p>User Id: {props.userId}</p>}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default UserCard;
