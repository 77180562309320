import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSingleOccasion2, getSingleServiceType } from "../../store/actions";
import { Container, Heading, InfoItem } from "../../components";
import { useParams } from "react-router-dom";
import { BACKEND_STATIC_URL } from "../../constants";
import { CenteredLoader } from "../Chat/Chat";

const ServiceTypeDetail = () => {
  const { id } = useParams();
  const { getSingleOccasionData } = useSelector((s) => s.occasion2Reducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleOccasion2({ _id: id }));
  }, [id, dispatch]);

  return getSingleOccasion2?.isLoading ? (
    <div className="m-5">
      <CenteredLoader />
    </div>
  ) : (
    <div>
      <Container className="half">
        <div className="serviceTypeDetail m-5">
          <Heading className="primary center m-5">
            {getSingleOccasionData?.data?.occasionName || "--"}
          </Heading>
        </div>

        <div className="serviceTypeDetail-container m-5">
          {/* <Heading className="center m-5">Images</Heading> */}
          <div className="serviceTypeDetail-container-image m-5">
            <div className="serviceTypeDetail-container-image-section">
              {getSingleOccasionData?.data?.occasionImage &&
                [getSingleOccasionData?.data?.occasionImage].map(
                  (item, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${BACKEND_STATIC_URL}${item}`}
                        alt="service-photos"
                        className="w-100"
                      />
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ServiceTypeDetail;
