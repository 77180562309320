import { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  FormInput,
  FormText,
  Heading,
  Modal,
} from "../../components";
import { MdDelete } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  GetMyService,
  DeleteMyService,
  clearErrors,
  clearMessages,
  createServiceType,
  getAllServicesTypes,
  deleteServiceType,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { Form } from "formik";
import { RiUpload2Fill } from "react-icons/ri";
import "./ServicesCrud.scss";
import {
  clearServiceTypeErrors,
  clearServiceTypeMessages,
} from "./../../store/actions";
import { BACKEND_STATIC_URL } from "../../constants";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal/DeleteConfirmationModal";
// import { fakeServiceDetailsData } from "./fakeServiceDetailsData";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-root": {
      color: "black",
      backgroundColor: "#ffc841",
      "&:hover": {
        backgroundColor: "#ffc841",
        color: "black",
      },
      "& .Mui-selected": {
        backgroundColor: "#ffc841",
        color: "black",
      },
    },
  },
});

const ServicesCrud = () => {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const { message, errors, loading, serviceTypes } = useSelector(
    (state) => state.serviceTypeReducer
  );
  const [selectedDeleteId, setSelectedDeleteId] = useState("");

  useEffect(() => {
    dispatch(getAllServicesTypes());
  }, []);
  //   const [alert, setAlert] = useState(false);
  //   const [serviceId, setServiceId] = useState(false);
  //   const classes = useStyles();
  //   const dispatch = useDispatch();
  //   const [page, setPage] = useState(1);
  //   const { details, errors, message, loading, totalPages } = useSelector(
  //     (state) => state.vendorReducer
  //   );
  //   const vendorUser = JSON.parse(sessionStorage.getItem("vendor"));

  //   useEffect(() => {
  //     dispatch(GetMyService(vendorUser.id, page));
  //     if (page != 1) {
  //       dispatch(GetMyService(vendorUser.id, page));
  //     }
  //   }, [page]);

  //   useEffect(() => {
  //     if (errors?.length > 0) {
  //       toast.error(errors);
  //       dispatch(clearErrors());
  //     }
  //     if (message != "") {
  //       toast.success(message);
  //       dispatch(clearMessages());
  //     }
  //   }, [errors, message]);

  //   const handleDelete = () => {
  //     dispatch(DeleteMyService(serviceId));
  //   };
  //   const handleServiceDelete = (id) => {
  //     setAlert(!alert);
  //     setServiceId(id);
  //   };
  // useEffect(()=>{
  //   if(errors?.length>0){
  //     toast.error(errors);
  //     dispatch(clearServiceTypeErrors());
  //   }
  //   if(message!==""){
  //     toast.success(message);
  //     dispatch(clearServiceTypeMessages());

  //     setName("");
  //     setImage(null);
  //   }

  // },[message,errors,dispatch])
  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearServiceTypeErrors());
    }
  }, [errors, dispatch]);

  useEffect(() => {
    if (message !== "") {
      toast.success(message);
      dispatch(clearServiceTypeMessages());

      setName("");
      setImage(null);
    }
  }, [message, dispatch]);
  const imageRef = useRef();
  // const onImageChange = (event) => {
  //  // set the image to state
  //   setImage(URL.createObjectURL(event.target.files[0]));

  // }
  // const onImageChange = (event) => {
  //   setImage(event.target.files[0]);
  // }
  const onImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleCreateService = () => {
    console.log(image);
    console.log(name);
    const data = new FormData();
    data.append("serviceName", name);
    data.append("serviceImage", image);
    dispatch(createServiceType(data));
  };

  const handleServiceDelete = (id) => {
    dispatch(
      deleteServiceType(id, () => {
        toast.success("Deleted Successfully!");
        setSelectedDeleteId("");
      })
    );
  };

  return (
    <>
      <Container className="lg">
        <Heading className="m-5 center">Manage Services</Heading>
        <div className="add-serviceType">
          <label htmlFor="name">Service Name</label>
          <input
            label="Enter Service Name"
            placeholder="Baloons"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="uploadimages-btn m-5">
            <div onClick={() => imageRef.current.click()}>
              <RiUpload2Fill style={{ marginRight: "1rem" }} />
              Upload Image
            </div>
          </div>
          {image && (
            <div className="create-service-images_preview m-2">
              <div className="create-service-images_preview-images">
                {[image].map((pic, ind) => {
                  return (
                    <div
                      className="create-service-images_preview-images-item"
                      key={ind}
                    >
                      <img src={URL.createObjectURL(image)} alt="service" />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div style={{ display: "none" }} className="">
            <input
              type="file"
              name="myImage"
              accept="image/*"
              ref={imageRef}
              onChange={onImageChange}
            />
          </div>
          <Button
            onClick={handleCreateService}
            className="rounded center "
            disabled={!image || !name}
          >
            Create Service
          </Button>
        </div>

        {/* {alert ? (
        <Modal action={alert}>
        <div className="modal-accept">
        <h3>Are you sure !</h3>
        <p>You want to delete this service ? </p>
        <div className="modal-accept-button">
        <button onClick={() => handleDelete()}>Yes</button>
              <button
                onClick={() => {
                  setAlert(!alert);
                }}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )} */}
        {window.screen.width > 600 ? (
          <Container className="md">
            <Heading className="center m-5">Services Types</Heading>
            <p className="count">{serviceTypes.length} Types of Services</p>
            <div className="table-container">
              <div className="myservices-table m-2">
                <table>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Service name</th>
                      {/* <th>Status</th>
                    <th>Reason</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <Puff
                          height="60"
                          width="60"
                          radius="6"
                          color="black"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      </tr>
                    ) : serviceTypes.length > 0 ? (
                      serviceTypes.map((data, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{ind + 1}</td>
                            <td>{data.serviceName}</td>
                            {/* <td>{data.status}</td>
                          <td>{data?.reason && data.reason}</td> */}
                            <td>
                              <Link to={`/service_type/${data._id}`}>
                                <HiEye />
                              </Link>
                              <Link to={`/edit_service_type/${data._id}`}>
                                <FaRegEdit />
                              </Link>
                              <MdDelete
                                onClick={() => {
                                  setSelectedDeleteId(data._id);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No service found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {serviceTypes.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "4rem",
                    }}
                  >
                    {/* <Pagination
                    classes={{ root: classes.root }}
                    variant="outlined"
                    count={totalPages}
                    page={page}
                    size="large"
                    showFirstButton
                    showLastButton
                    onChange={(e, value) => setPage(value)}
                  /> */}
                  </div>
                )}
              </div>
            </div>
          </Container>
        ) : (
          <Container className="responsive">
            <Heading className="center m-5">My Services</Heading>
            <p className="count">
              Total Services :{" "}
              {serviceTypes?.length ? serviceTypes?.length : "0"}
            </p>
            {serviceTypes.length > 0 &&
              serviceTypes.map((data, idx) => {
                return (
                  <div className="my-services-tab" key={idx}>
                    <div>
                      <div>
                        <p>{data?.serviceName && data.serviceName}</p>
                      </div>
                      <span>
                        <Link to={`/service_type/${data._id}`}>
                          <HiEye />
                        </Link>
                        <Link to={`/edit_service_type/${data._id}`}>
                          <FaRegEdit />
                        </Link>
                        <MdDelete
                          onClick={() => {
                            setSelectedDeleteId(data._id);
                          }}
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="service-img-div">
                      <img
                        src={`${BACKEND_STATIC_URL}${data.serviceImage}`}
                        alt="service"
                      />
                    </div>
                  </div>
                );
              })}
          </Container>
        )}
      </Container>
      <DeleteConfirmationModal
        open={Boolean(selectedDeleteId)}
        onClose={() => setSelectedDeleteId("")}
        onConfirmation={() => handleServiceDelete(selectedDeleteId)}
        disabled={loading}
      />
    </>
  );
};

export default ServicesCrud;
