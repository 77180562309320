import "./VenderServices.scss";
import {
  Container,
  Grid,
  Heading,
  FormHeading,
  VendorServiceStatusCard,
} from "../../components";
import UserCard from "../../components/userCard/UserCard";
import { useEffect, useState } from "react";
import { profile } from "../../assests";
import { Puff } from "react-loader-spinner";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAllServicesWithVendors } from "../../store/actions/services.actions";
import getUniqueVendors from "../../utils/getUniqueVendors";
import { useQueryParams } from "../../hooks";
import styled from "@emotion/styled";
import { MdSearch } from "react-icons/md";
import { useDebounceValue } from "usehooks-ts";
import { BACKEND_STATIC_URL } from "../../constants";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-root": {
      color: "black",
      backgroundColor: "#ffc841",
      "&:hover": {
        backgroundColor: "#ffc841",
        color: "black",
      },
      "& .Mui-selected": {
        backgroundColor: "#ffc841",
        color: "black",
      },
    },
  },
});

const StyledSearchForm = styled.form`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: end;

  &,
  & * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }

  & > div {
    border-radius: 1000rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 1.2rem 2rem;
    gap: 1.2rem;

    & > input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 1.4rem;
      max-width: 25rem;
      background-color: transparent;
    }

    & > button {
      background-color: transparent;
      border: none;
      outline: none;
      display: grid;
      place-items: center;
      font-size: 2rem;
      line-height: 100%;
      cursor: pointer;

      &:hover {
        opacity: 80%;
      }
    }
  }
`;

const VenderServices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, serviceStatus, deleteStatus, setQueryParam, query } =
    useQueryParams({
      page: "1",
      serviceStatus: "Pending",
      deleteStatus: "NULL",
      query: "",
    });
  const { loading, services, totalPages } = useSelector(
    (state) => state.servicesReducer
  );

  const [debouncedQuery, setQuery] = useDebounceValue("", 500);

  useEffect(() => {
    setQueryParam({ query: debouncedQuery });
  }, [debouncedQuery, setQueryParam]);

  useEffect(() => {
    dispatch(
      getAllServicesWithVendors({
        status: serviceStatus,
        page,
        deleteStatus,
        query,
      })
    );
  }, [dispatch, page, serviceStatus, deleteStatus, query]);

  const RoleSelector = () => (
    <>
      {loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : services.length > 0 ? (
        services?.map((s) => (
          <VendorServiceStatusCard
            img={
              s?.vendor?.photoPath
                ? `${BACKEND_STATIC_URL}${s?.vendor?.photoPath}`
                : ""
            }
            serviceName={s?.serviceName?.serviceName}
            vendorName={`${s?.vendor?.fullName}${
              s?.vendor?.userId ? ` (${s?.vendor?.userId})` : ""
            }`}
            to={`/service_detail/${s.id}?${
              deleteStatus === "PENDING" ? "delete=true" : ""
            }${
              s?.acceptedEdit && serviceStatus === "Pending"
                ? "isAcceptedEdit=true"
                : ""
            }`}
            createdAt={s?.createdAt}
            status={s?.status}
            deleteStatus={s?.deleteStatus}
            statusUpdateDate={
              s?.deleteStatus === "PENDING"
                ? s?.deleteStatusUpdatedAt
                : s?.statusUpdatedAt
            }
          />
        ))
      ) : (
        <h1>No record found</h1>
      )}
    </>
  );

  return (
    <>
      <Container className="lg">
        <Heading className="m-5 center">Vendor services</Heading>

        <div className="venderservice m-5">
          <div className="venderservice-menu">
            {["Pending", "Accepted", "Rejected", "Hold"].map((val) => (
              <button
                onClick={() =>
                  setQueryParam(
                    { serviceStatus: val, deleteStatus: "NULL" },
                    true
                  )
                }
                id={
                  serviceStatus === val && deleteStatus === "NULL"
                    ? "active-tab"
                    : ""
                }
                key={val}
              >
                {val}
              </button>
            ))}
            <button
              onClick={() => setQueryParam({ deleteStatus: "PENDING" }, true)}
              id={deleteStatus === "PENDING" ? "active-tab" : ""}
            >
              Delete Requests
            </button>
          </div>
        </div>

        <StyledSearchForm onSubmit={(e) => e.preventDefault()}>
          <div>
            <input
              placeholder="search"
              onChange={(e) => setQuery(e.target.value)}
              type="text"
            />
            <button type="submit">
              <MdSearch />
            </button>
          </div>
        </StyledSearchForm>

        <div className="venderservice-cards m-5">
          {/* <h3 className="m-4">New Forms</h3> */}
          {/* <FormHeading  /> */}
          <div className="m-2" />
          <LayoutStyled>
            <RoleSelector />
          </LayoutStyled>
        </div>

        {totalPages > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              classes={{ root: classes.root }}
              variant="outlined"
              count={totalPages}
              page={page}
              size="large"
              showFirstButton
              showLastButton
              onChange={(e, value) => setQueryParam({ page: value })}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default VenderServices;

export const LayoutStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(38rem, 1fr));
  gap: 2rem;
`;
