export const occasionConstant = {
  GET_ALL_OCCASION: {
    REQUEST: "GET_ALL_OCCASION_REQUEST",
    SUCCESS: "GET_ALL_OCCASION_SUCCESS",
    FAILURE: "GET_ALL_OCCASION_FAILURE",
  },
  ADD_FEATURED_OCCASION: {
    REQUEST: "UPDATE_FEATURED_OCCASION_REQUEST",
    SUCCESS: "UPDATE_FEATURED_OCCASION_SUCCESS",
    FAILURE: "UPDATE_FEATURED_OCCASION_FAILURE",
  },
  GET_FEATURED_OCCASION: {
    REQUEST: "GET_FEATURED_OCCASION_REQUEST",
    SUCCESS: "GET_FEATURED_OCCASION_SUCCESS",
    FAILURE: "GET_FEATURED_OCCASION_FAILURE",
  },
  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
};
