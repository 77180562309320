import { occasionConstant } from "../constants";

const initialState = {
  occasions: [],
  errors: [],
  loading: false,
  message: "",
  loading2: false,
  featuredOccasions: [],
};

const occasionReducer = (state = initialState, action) => {
  switch (action.type) {
    case occasionConstant.GET_FEATURED_OCCASION.REQUEST:
      return {
        ...state,
        loading2: true,
      };
    case occasionConstant.GET_FEATURED_OCCASION.SUCCESS:
      return {
        ...state,
        loading2: false,
        featuredOccasions: action.payload.occasions,
      };
    case occasionConstant.GET_FEATURED_OCCASION.FAILURE:
      return {
        ...state,
        loading2: false,
      };
    case occasionConstant.GET_ALL_OCCASION.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case occasionConstant.GET_ALL_OCCASION.SUCCESS:
      return {
        ...state,
        loading: false,
        occasions: action.payload.occasions,
      };
    case occasionConstant.GET_ALL_OCCASION.FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.err,
      };
    case occasionConstant.ADD_FEATURED_OCCASION.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case occasionConstant.ADD_FEATURED_OCCASION.SUCCESS:
      return {
        ...state,
        loading: false,
        // occasions: action.payload.occasions,
        message: "Featured Occasions Updated Successfully",
      };
    case occasionConstant.ADD_FEATURED_OCCASION.FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.err,
      };
    case occasionConstant.CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case occasionConstant.CLEAR_MESSAGES:
      return {
        ...state,
        message: "",
      };
    default:
      return state;
  }
};

export default occasionReducer;
