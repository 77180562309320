import { useEffect, useRef, useState } from "react";
import { getNumberOfVendorByCity } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { HeatMapStyled } from "./styles";
import axios from "axios";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import { MapContainer, TileLayer, useMapEvent } from "react-leaflet";
import { Gradient } from "react-leaflet-heatmap-layer-v3/lib/Heatmap";
import { useHeatMapContext } from "../../context/HeatMapContext";

const HeatMap = ({ onClick }) => {
  return (
    <HeatMapStyled onClick={onClick}>
      <MapContainer
        center={[55.2, -3.2768]}
        zoom={5}
        style={{ width: "100%", height: "450px" }}
        scrollWheelZoom={false}
        dragging={false}
        touchZoom={false}
        zoomControl={false}
        zoomAnimation={false}
        doubleClickZoom={false}
      >
        <ChildComp />
      </MapContainer>
      <p>Vendors HeatMap</p>
    </HeatMapStyled>
  );
};

export default HeatMap;

const ChildComp = () => {
  const dispatch = useDispatch();
  const map = useMapEvent("dragend", (e) => {
    console.log(e);
  });

  const { getNumberOfVendorsByCityData } = useSelector(
    (state) => state.adminReducer
  );

  const { citiesWithLatAndLong } = useHeatMapContext();

  useEffect(() => {
    map.flyTo({ lat: 55.2, lng: -3.2765 }, 5, { animate: false });
  }, [map, citiesWithLatAndLong]);

  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <HeatmapLayer
        fitBoundsOnLoad
        fitBoundsOnUpdate
        radius={15}
        points={citiesWithLatAndLong.map((city) => [
          city.lat,
          city.lon,
          city.count,
        ])}
        longitudeExtractor={(m) => m[1]}
        latitudeExtractor={(m) => m[0]}
        intensityExtractor={(m) => parseFloat(m[2])}
        max={Math.max(0, ...citiesWithLatAndLong.map((c) => c.count))}
        gradient={{
          0: "#000000",
          0.2: "#570000",
          0.4: "#ff0000",
          0.6: "#ffc800",
          0.8: "#ffff00",
          1: "#FFFFFF",
        }}
      />
    </>
  );
};
