import React, { useEffect } from "react";
import "./Analytics.scss";
import { Container, Grid } from "../../components";
import toast from "react-hot-toast";
import {
  GetTopFiveCustomer,
  GetTopFiveServices,
  GetTopFiveVendor,
  WeeklyUser,
  DailyUser,
  MonthlyUser,
  VisitorsByBrowser,
  clearErrors,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

const Analytics = () => {
  const dispatch = useDispatch();
  const {
    topFiveCustomer,
    topFiveServices,
    topFiveVendors,
    dailyUser,
    weeklyUser,
    monthlyUser,
    // visitorsByBrowser,
    errors,
  } = useSelector((state) => state.adminReducer);

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
  }, [errors]);

  useEffect(() => {
    if (topFiveCustomer.length <= 0) {
      dispatch(GetTopFiveCustomer());
    }
    if (topFiveServices.length <= 0) {
      dispatch(GetTopFiveServices());
    }
    if (topFiveVendors.length <= 0) {
      dispatch(GetTopFiveVendor());
    }
    if (dailyUser.length <= 0) {
      dispatch(DailyUser());
    }
    if (weeklyUser.length <= 0) {
      dispatch(WeeklyUser());
    }
    if (monthlyUser.length <= 0) {
      dispatch(MonthlyUser());
    }
    // if (visitorsByBrowser.length <= 0) {
    //   dispatch(VisitorsByBrowser());
    // }
  }, []);

  return (
    <StyledOverflowScroll>
      <Container className="lg">
        <div className="m-6">
          <TopLayout>
            <TopCard for="Customers">
              {topFiveCustomer?.length > 0 &&
                topFiveCustomer.map((data, ind) => {
                  return (
                    <Item
                      no={ind + 1}
                      key={ind}
                      detail={data?.email && data.email}
                    />
                  );
                })}
            </TopCard>
            <TopCard for="Services">
              {topFiveServices?.length > 0 &&
                topFiveServices.map((data, ind) => {
                  return (
                    <Item
                      no={ind + 1}
                      detail={data?.serviceName?.serviceName || "--"}
                    />
                  );
                })}
            </TopCard>
            <TopCard for="Vendor">
              {topFiveVendors?.length > 0 &&
                topFiveVendors.map((data, ind) => {
                  return (
                    <Item no={ind + 1} detail={data?.email && data.email} />
                  );
                })}
            </TopCard>
          </TopLayout>
        </div>
        <div className="m-6">
          <Grid className="grid-4">
            <BottomCard time="Daily" number={dailyUser?.dailyUsers || 0} />
            <BottomCard time="Weekly" number={weeklyUser.weeklyUsers || 0} />
            <BottomCard time="Monthly" number={monthlyUser.monthlyUsers || 0} />

            {/* <div className="activeuser-card">
              <div className="activeuser-card-container">
                <p>Real time Active Users by channel</p>
                <hr />
                <h4>
                  Right <br /> Now
                </h4>
                <h2>
                  {visitorsByBrowser?.length > 0 ? visitorsByBrowser.length : 0}
                </h2>
                <p>Active users on this site</p>
              </div>
            </div> */}
          </Grid>
        </div>
      </Container>
    </StyledOverflowScroll>
  );
};

export default Analytics;

////////////////////
//Sub Components

const TopCard = (props) => {
  return (
    <>
      <div className="topcard">
        <div className="topcard-container">
          <h3 className="center ">Top 5 {props.for}</h3>
          <div className="m-4">{props.children}</div>
        </div>
      </div>
    </>
  );
};

const Item = (props) => {
  return (
    <>
      <div className="topcard-item m-3">
        <h3>{props.no} .</h3>
        <h3>{props.detail}</h3>
      </div>
    </>
  );
};

// User Cards

const BottomCard = (props) => {
  return (
    <>
      <div className="bottomcard">
        <div className="bottomcard-container">
          <h4>
            Users <br />
            {props.time}
          </h4>
          <h1>{props.number}</h1>
        </div>
      </div>
    </>
  );
};

// const
const TopLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  gap: 1.4rem;
`;

export const StyledOverflowScroll = styled.div`
  height: 100%;
  overflow: auto;
`;
