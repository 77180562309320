import { servicesConstants } from "../constants";

const initialState = {
  loading: false,
  services: [],
  totalPages: 1,
  singleService: {},

  deleteLoading: false,

  updateLoading: false,
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case servicesConstants.getAllServicesWithVendors.request:
      return {
        ...state,
        loading: true,
      };
    case servicesConstants.getAllServicesWithVendors.success:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case servicesConstants.getAllServicesWithVendors.error:
      return {
        ...state,
        loading: false,
      };

    case servicesConstants.deleteVendorService.request:
      return {
        ...state,
        deleteLoading: true,
      };
    case servicesConstants.deleteVendorService.success:
      return {
        ...state,
        deleteLoading: false,
      };
    case servicesConstants.deleteVendorService.error:
      return {
        ...state,
        deleteLoading: false,
      };

    case servicesConstants.patchVendorServiceDeleteRequest.request:
      return {
        ...state,
        updateLoading: true,
      };
    case servicesConstants.patchVendorServiceDeleteRequest.success:
    case servicesConstants.patchVendorServiceDeleteRequest.error:
      return {
        ...state,
        updateLoading: false,
      };

    default:
      return state;
  }
};

export default servicesReducer;
