import { servicesTypeConstants } from '../constants';
const initialValues={
    message:"",
    loading:false,
    serviceTypes:[],
    singleServiceType:{},
    errors:[]
}


const serviceTypeReducer=(state=initialValues,action)=>{
    switch(action.type){
        case servicesTypeConstants.createServiceType.request:
            return {
                ...state,
                loading:true
            }
        case servicesTypeConstants.createServiceType.success:
            console.log(action.payload);
            return {
                ...state,
                loading:false,
                message: action.payload.message
            }
        case servicesTypeConstants.createServiceType.error:
            return {
                ...state,
                loading:false,
                errors:action.payload.err
            }
        
        case servicesTypeConstants.getAllServicesTypes.request:
            return {
                ...state,
                loading:true
            }
        case servicesTypeConstants.getAllServicesTypes.success:
            return {
                ...state,
                loading:false,
                ...action.payload
            }
        case servicesTypeConstants.getAllServicesTypes.error:
            return {
                ...state,
                loading:false,
                errors:action.payload.err
            }
        case servicesTypeConstants.clearErrors:
            console.log("clearing errors");
            return {
                ...state,
                errors:[]
            }
        case servicesTypeConstants.clearMessages:
            return {
                ...state,
                message:""
            }
        case servicesTypeConstants.deleteServiceType.request:
            return {
                ...state,
                loading:true
            }
        case servicesTypeConstants.deleteServiceType.success:
            return {
                ...state,
                loading:false,
                ...action.payload
            }
        case servicesTypeConstants.deleteServiceType.error:
            return {
                ...state,
                loading:false,
                errors:action.payload.err
            }
        case servicesTypeConstants.getSingleServiceType.request:
            return {
                ...state,
                loading:true
            }
        case servicesTypeConstants.getSingleServiceType.success:
            return {
                ...state,
                loading:false,
                ...action.payload
            }
        case servicesTypeConstants.getSingleServiceType.error:
            return {
                ...state,
                loading:false,
                errors:action.payload.err
            }
        case servicesTypeConstants.updateSingleService.request:
            return {
                ...state,
                loading:true
            }
        case servicesTypeConstants.updateSingleService.success:
            return {
                ...state,
                loading:false,
                ...action.payload,
                message:"Service Type Updated Successfully"
            }
        case servicesTypeConstants.updateSingleService.error:
            return {
                ...state,
                loading:false,
                errors:action.payload.err
            }
        default:
            return state
    }
}

export default serviceTypeReducer;