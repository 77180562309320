export const occasion2Constants = {
  getAllOccasions: {
    request: "GET_ALL_OCCASION_2_REQUEST",
    success: "GET_ALL_OCCASION_2_SUCCESS",
    error: "GET_ALL_OCCASION_2_FAILURE",
  },
  createOccasion: {
    request: "POST_OCCASION_2_REQUEST",
    success: "POST_OCCASION_2_SUCCESS",
    error: "POST_OCCASION_2_FAILURE",
  },
  getSingleOccasion: {
    request: "GET_SINGLE_OCCASION_2_REQUEST",
    success: "GET_SINGLE_OCCASION_2_SUCCESS",
    error: "GET_SINGLE_OCCASION_2_FAILURE",
  },
  editOccasion: {
    request: "PATCH_OCCASION_2_REQUEST",
    success: "PATCH_OCCASION_2_SUCCESS",
    error: "PATCH_OCCASION_2_FAILURE",
  },
  deleteOccasion: {
    request: "DELETE_OCCASION_2_REQUEST",
    success: "DELETE_OCCASION_2_SUCCESS",
    error: "DELETE_OCCASION_2_FAILURE",
  },
};
