import axios from "axios";
import asyncAction from "../../utils/asyncAction";
import { userInterfaceConstants2 } from "../constants";

export const getUserInface2 = (payload, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/interface`,
        authHeaders
      );
      return data;
    },
    userInterfaceConstants2.GET_USER_INTERFACE,
    onSuccess,
    onError
  );
};

export const updateUserInface2 = (payload, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.patch(
        `${process.env.REACT_APP_ROOT}/v1/api/interface`,
        payload,
        authHeaders
      );
      return data;
    },
    userInterfaceConstants2.UPDATE_USER_INTERFACE,
    onSuccess,
    onError
  );
};
