import { fileConstants } from "../constants";
const initialValues = {
  uploadFileData: {
    loading: false,
  },
};

const fileReducer = (state = initialValues, action) => {
  const s = { ...state };
  switch (action.type) {
    case fileConstants.UPLOAD_FILE.request:
      s.uploadFileData.loading = true;
      break;
    case fileConstants.UPLOAD_FILE.success:
      s.uploadFileData.loading = false;
      break;
    case fileConstants.UPLOAD_FILE.error:
      s.uploadFileData.loading = false;
      break;

    default:
      break;
  }

  return s;
};

export default fileReducer;
