import { Container, FormHeading, Grid, Heading } from "../../components";
import UserCard from "../../components/userCard/UserCard";
import { useEffect, useState } from "react";
import "./VerificationRequest.scss";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  GetServiceByStatus,
  clearErrors,
  getAllVerificationByStatus,
} from "../../store/actions";
import { profile } from "../../assests";
import { StyledOverflowScroll } from "../analytics/Analytics";
import styled from "@emotion/styled";
import { BACKEND_STATIC_URL } from "../../constants";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-root": {
      color: "black",
      backgroundColor: "#ffc841",
      "&:hover": {
        backgroundColor: "#ffc841",
        color: "black",
      },
      "& .Mui-selected": {
        backgroundColor: "#ffc841",
        color: "black",
      },
    },
  },
});

const VerificationRequest = () => {
  const classes = useStyles();
  const [sort, setSort] = useState("new");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [serviceStatus, setSerivceStatus] = useState("pending");
  const { loading, verifications, totalPages } = useSelector(
    (state) => state.verificationReducer
  );

  useEffect(() => {
    dispatch(getAllVerificationByStatus({ status: serviceStatus, page }));
  }, [dispatch, page, serviceStatus]);

  const handleNewService = () => {
    setSort("new");
    setSerivceStatus("pending");
    setPage(1);
  };
  const handleAcceptedService = () => {
    setSort("accepted");
    setSerivceStatus("accepted");
    setPage(1);
  };
  const handleRejectedService = () => {
    setSort("rejected");
    setSerivceStatus("rejected");
    setPage(1);
  };

  const handleHoldService = () => {
    setSort("hold");
    setSerivceStatus("hold");
    setPage(1);
  };

  const RoleSelector = () => (
    <>
      {loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : verifications.length > 0 ? (
        verifications.map((item, ind) => {
          console.log(item);
          return (
            <UserCard
              key={ind}
              image={
                item?.user?.photoPath
                  ? `${BACKEND_STATIC_URL}${item?.user?.photoPath}`
                  : profile
              }
              name={item?.user?.fullName ?? ""}
              to={`/verification_detail/${item._id}`}
              show={ind === 0 && sort === "new"}
              userId={item?.user?.userId}
            />
          );
        })
      ) : (
        <h1>No record found</h1>
      )}
    </>
  );

  return (
    <StyledOverflowScroll>
      <Container className="lg">
        <Heading className="m-5 center">Verification Request</Heading>

        <div className="venderservice m-5">
          <div className="venderservice-menu">
            <button
              onClick={() => handleNewService()}
              id={sort == "new" ? "active-tab" : ""}
            >
              New
            </button>
            <button
              onClick={() => handleAcceptedService()}
              id={sort == "accepted" && "active-tab"}
            >
              Accepted
            </button>
            <button
              onClick={() => handleRejectedService()}
              id={sort == "rejected" ? "active-tab" : ""}
            >
              Rejected
            </button>
            <button
              onClick={() => handleHoldService()}
              id={sort == "hold" ? "active-tab" : ""}
            >
              Hold
            </button>
          </div>
        </div>

        <div className="venderservice-cards m-5">
          <FormHeading sort={sort} />

          <div className="m-2" />
          <LayoutStyled>
            <RoleSelector />
          </LayoutStyled>
        </div>
        {verifications.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              classes={{ root: classes.root }}
              variant="outlined"
              count={totalPages}
              page={page}
              size="large"
              showFirstButton
              showLastButton
              onChange={(e, value) => setPage(value)}
            />
          </div>
        )}
      </Container>
    </StyledOverflowScroll>
  );
};

export default VerificationRequest;

export const LayoutStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  gap: 1.4rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  }
`;
