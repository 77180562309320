import React, { useEffect, useState } from "react";
import { Container, Grid, Card, GoBackBtn } from "../../components";
import { cardImage, profile, store, verified } from "../../assests";
import { Link, useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getSingleVendorServices } from "../../store/actions/services.actions";
import { Puff } from "react-loader-spinner";
import { Pagination } from "@mui/material";
import { BACKEND_STATIC_URL } from "../../constants";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-root": {
      color: "black",
      backgroundColor: "#ffc841",
      "&:hover": {
        backgroundColor: "#ffc841",
        color: "black",
      },
      "& .Mui-selected": {
        backgroundColor: "#ffc841",
        color: "black",
      },
    },
  },
});
const VenderServicesGrid = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { loading, services, totalPages } = useSelector(
    (state) => state.servicesReducer
  );
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const img = queryParams.get("img");
  const name = queryParams.get("name");
  const address = queryParams.get("address");
  const isVerified = Boolean(queryParams.get("verified"));

  useEffect(() => {
    dispatch(getSingleVendorServices({ id, page }));
  }, [dispatch, id, page]);

  return (
    <>
      <div className="m-5 ">
        <Container className="lg">
          <GoBackBtn className="m-4" />
        </Container>
        <Container className="md">
          <div className="space-between  m-5  vendershop-profile">
            <a
              href={`${
                process.env.REACT_APP_VENDOR_SHOP
              }/#/vender-shop?id=${id}&${isVerified ? "verified=_&" : ""}${
                name ? `name=${name}&` : ""
              }${img ? `img=${img}&` : ""}${
                address ? `address=${address}` : ""
              }`}
              rel="noreferrer"
              target="_blank"
            >
              <div className="vendershop-profile-detail align-item-center">
                <div className="vendershop-profile-detail-image">
                  <img src={img ?? profile} alt="profile" />
                </div>
                <div className="vendershop-profile-detail-content">
                  <div className="align-item-center">
                    <h5>{name ?? "--"}</h5>
                    {isVerified && <img src={verified} alt="verify" />}
                  </div>
                </div>
              </div>
            </a>

            <div className="vendershop-profile-shop">
              <img src={store} alt="store-icon" />
            </div>
          </div>
        </Container>

        <Container className="md">
          {loading ? (
            <Puff
              height="60"
              width="60"
              radius="6"
              color="black"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          ) : services.length === 0 ? (
            <h1>No record Found</h1>
          ) : (
            <Grid className="grid-3">
              {services?.map((service, i) => (
                <Card
                  image={
                    service?.thumbnails
                      ? BACKEND_STATIC_URL + service?.thumbnails[0]
                      : cardImage
                  }
                  name={service?.name ?? "--"}
                  description={service?.description ?? "--"}
                  views={service?.views ?? 0}
                  to={`/service_detail/${service?.id}`}
                  key={i}
                />
              ))}
            </Grid>
          )}
        </Container>
      </div>

      {services.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            classes={{ root: classes.root }}
            variant="outlined"
            count={totalPages}
            page={page}
            size="large"
            showFirstButton
            showLastButton
            onChange={(e, value) => setPage(value)}
          />
        </div>
      )}
    </>
  );
};

export default VenderServicesGrid;
