import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CenteredLoaderStyled,
  ChatStyled,
  LeftChatStyled,
  MessageCardStyled,
  MessageProfileCardStyled,
  MessagesStyled,
  MoreOptionsMenuStyled,
  NoChatSelectedStyled,
  RightChatStyled,
  SearchFormStyled,
  TopBarStyled,
  TypeMessageStyled,
} from "./styles";
import { MdSearch } from "react-icons/md";
import { BACKEND_STATIC_URL } from "../../constants";
import { profile } from "../../assests";
import { IoIosSend, IoMdArrowRoundBack } from "react-icons/io";
import { useQueryParams } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { getAllChats, updateMessages } from "../../store/actions";
import {
  createSnapShotListenerForMessages,
  deleteChat,
  sendMessageNew,
  updateSeen,
} from "../../firebase/message";
import { useFormik } from "formik";
import { messageSchema } from "../../schema";
import { Link, useParams } from "react-router-dom";
import { getLoggedInUserDetails } from "../../utils";
import {
  getChatById,
  getLastMessageCreatedAt,
  getNumberOfUnseenMessages,
  getOtherSideParticipant,
  getOtherSideParticipantId,
} from "../../utils/getChat";
import { Puff } from "react-loader-spinner";
import { CiMenuKebab } from "react-icons/ci";
import moment from "moment";

const Chat = () => {
  const { chatId } = useQueryParams({
    chatId: "",
  });
  const { allMessages } = useSelector((s) => s.chatReducer);

  useEffect(() => {
    const { user } = getLoggedInUserDetails();
    if (!chatId || !user) return;

    updateSeen(user.id, chatId);
  }, [chatId, allMessages]);

  useEffect(() => {
    getAllChats();
  }, []);

  return (
    <ChatStyled>
      <LeftChat />
      {chatId ? <RightChat /> : <NoChatSelected />}
    </ChatStyled>
  );
};

export default Chat;

const LeftChat = () => {
  const {
    getAllChatsData,
    createNewChatsWithVendorsAndCustomersData,
    allMessages,
  } = useSelector((s) => s.chatReducer);
  const { chatWith } = useParams();

  const { query, setQueryParam, chatId } = useQueryParams({
    query: "",
    chatId: "",
  });

  const profiles = useMemo(() => {
    const unsorted = getAllChatsData.data.map((c) => {
      const detail = getOtherSideParticipant(c) || {};
      if (!detail) return null;

      const numberOfUnSeenMessages = getNumberOfUnseenMessages(
        c._id,
        allMessages
      );

      const lastMessageReceivedAt = getLastMessageCreatedAt(c._id, allMessages);

      return {
        ...detail,
        chatId: c._id,
        numberOfUnSeenMessages,
        lastMessageReceivedAt,
      };
    });

    return unsorted.sort(
      (a, b) => b.lastMessageReceivedAt - a.lastMessageReceivedAt
    );
  }, [allMessages, getAllChatsData.data]);

  const filteredProfiles = useMemo(() => {
    return profiles
      ?.filter((p) => p?.role === (chatWith === "customer" ? "User" : "Vendor"))
      ?.filter(
        (p) =>
          p.fullName.toLowerCase().search(query?.toLowerCase()) !== -1 ||
          (p.userId && `${p.userId}`.search(query?.toLowerCase()) !== -1)
      );
  }, [profiles, query, chatWith]);

  return (
    <LeftChatStyled chatSelected={Boolean(chatId)}>
      <div className="input">
        <SearchForm />
      </div>

      {getAllChatsData.loading ||
      createNewChatsWithVendorsAndCustomersData.loading ? (
        <CenteredLoader />
      ) : (
        <div className="group custom-scrollbar">
          {filteredProfiles?.map((p, i) => (
            <MessageProfileCard
              key={i}
              img={p.photoPath}
              name={p.fullName}
              selected={p.chatId === chatId}
              numberOfMessages={p.numberOfUnSeenMessages}
              role={`${p.role === "User" ? "Customer" : p.role}${
                p.userId ? ` Id: ${p.userId}` : ""
              }`}
              to={setQueryParam(
                {
                  chatId: p.chatId,
                },
                true,
                false,
                true
              )}
            />
          ))}

          {filteredProfiles.length === 0 && <p>No Chat Found!</p>}
        </div>
      )}
    </LeftChatStyled>
  );
};

const SearchForm = () => {
  const { query, setQueryParam } = useQueryParams({
    query: "",
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <SearchFormStyled onSubmit={onSubmit}>
      <input
        type="text"
        placeholder="Search with name"
        onChange={(e) => setQueryParam({ query: e.target.value }, false, true)}
        value={query}
      />
      <button type="submit">
        <MdSearch />
      </button>
    </SearchFormStyled>
  );
};

const MessageProfileCard = ({
  selected,
  img,
  name,
  numberOfMessages = 0,
  to,
  role,
}) => {
  return (
    <MessageProfileCardStyled
      numberOfMessages={numberOfMessages}
      selected={selected}
      to={to}
    >
      <img src={img ? `${BACKEND_STATIC_URL}${img}` : profile} alt={name} />

      <div>
        <span className="name">{name || "Un Named"}</span>
        <span className="role">{role || "--"}</span>
      </div>
      {Boolean(numberOfMessages) && (
        <span className="counts">{numberOfMessages}</span>
      )}
    </MessageProfileCardStyled>
  );
};

const RightChat = () => {
  return (
    <RightChatStyled>
      <TopBar />
      <Messages />
      <TypeMessage />
    </RightChatStyled>
  );
};

const TopBar = () => {
  const { chatWith } = useParams();
  const { chatId } = useQueryParams({
    chatId: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const { getAllChatsData } = useSelector((s) => s.chatReducer);

  const otherSideUser = useMemo(() => {
    return getOtherSideParticipant(getChatById(chatId, getAllChatsData.data));
  }, [chatId, getAllChatsData.data]);

  const onDelete = useCallback(() => {
    setAnchorEl(null);
    deleteChat(chatId);
  }, [chatId]);
  return (
    <TopBarStyled>
      <div>
        <Link to="/chat">
          <IoMdArrowRoundBack />
        </Link>
        <img
          src={
            otherSideUser?.photoPath
              ? `${BACKEND_STATIC_URL}${otherSideUser?.photoPath}`
              : profile
          }
          alt={otherSideUser?.fullName}
        />
        <span>{otherSideUser?.fullName || "Un Named"}</span>
      </div>
      <button onClick={(e) => setAnchorEl(e.currentTarget)}>
        <CiMenuKebab />
      </button>

      <MoreOptionsMenuStyled
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <button onClick={onDelete}>Delete Chat</button>
      </MoreOptionsMenuStyled>
    </TopBarStyled>
  );
};

const Messages = () => {
  const { allMessages } = useSelector((s) => s.chatReducer);
  const [loggedInUserId, setloggedInUserId] = useState("");
  const { chatId } = useQueryParams({
    chatId: "",
  });
  const messageContainerRef = useRef(null);

  const filteredMessages = useMemo(
    () => allMessages.filter((m) => m.chatId === chatId),
    [allMessages, chatId]
  );

  const filteredDeleteMessages = useMemo(() => {
    return filteredMessages?.filter(
      (m) =>
        (m?.senderId === loggedInUserId && !m?.deleteBySender) ||
        (m?.receiverId === loggedInUserId && !m?.deleteByReceiver)
    );
  }, [filteredMessages, loggedInUserId]);

  useEffect(() => {
    const { user } = getLoggedInUserDetails();

    setloggedInUserId(user?.id);
  }, []);

  useEffect(() => {
    messageContainerRef?.current?.scrollTo({
      top: messageContainerRef?.current?.scrollHeight,
      behavior: "smooth",
    });
  }, [allMessages, chatId]);

  return (
    <MessagesStyled className="custom-scrollbar" ref={messageContainerRef}>
      {filteredDeleteMessages.map((m) => (
        <>
          <MessageCard
            time={moment(m?.createdAt?.seconds * 1000).format(
              "h:mm A - MMM DD, YYYY"
            )}
            me={m.senderId === loggedInUserId}
            message={m.message}
          />
        </>
      ))}
    </MessagesStyled>
  );
};

const MessageCard = ({ me, message, time }) => {
  return (
    <MessageCardStyled me={me}>
      {message?.split("\n").map((m) => (
        <>
          {m}
          <br />
        </>
      ))}
      <span className="date-time">{time}</span>
    </MessageCardStyled>
  );
};

const TypeMessage = () => {
  const { getAllChatsData } = useSelector((s) => s.chatReducer);
  const { chatId } = useQueryParams({
    chatId: "",
  });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: messageSchema,
    onSubmit: ({ message }, { resetForm }) => {
      const loggedInUserId = getLoggedInUserDetails().user?.id;

      const otherParticipantId = getOtherSideParticipantId(
        getChatById(chatId, getAllChatsData.data)
      );

      sendMessageNew({
        chatId,
        message,
        receiverId: otherParticipantId,
        senderId: loggedInUserId,
      });

      resetForm();
    },
  });
  return (
    <TypeMessageStyled onSubmit={handleSubmit}>
      <input
        type="text"
        value={values.message}
        onChange={handleChange}
        name="message"
        placeholder="Type Message..."
      />
      <button type="submit">
        <IoIosSend />
      </button>
    </TypeMessageStyled>
  );
};

const NoChatSelected = () => {
  return (
    <NoChatSelectedStyled>
      <p>No Chat Selected!</p>
    </NoChatSelectedStyled>
  );
};

export const CenteredLoader = () => {
  return (
    <CenteredLoaderStyled>
      <Puff
        height="60"
        width="60"
        radius="6"
        color="black"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </CenteredLoaderStyled>
  );
};
