import logo from "./logo.svg";
import profile from "./profile.png";
import cardImage from "./card.png";
import login from "./login/login.png";
import facebook from "./social/facebook.svg";
import insta from "./social/insta.svg";
import store from "./store.svg";
import verified from "./verified.svg";
import promo from "./promo.svg";

// Dashboard
import subscriber from "./dashboard/subscriber.svg";
import visitor from "./dashboard/visitor.svg";

import NewBlink from "./newBlink.png";

export {
  logo,
  profile,
  insta,
  facebook,
  login,
  cardImage,
  store,
  verified,
  promo,
  subscriber,
  visitor,
  NewBlink,
};
