import { occasion2Constants } from "../constants";

const initialState = {
  getAllOccasionsData: {
    isLoading: false,
    data: [],
  },

  getSingleOccasionData: {
    isLoading: false,
    data: {},
  },

  createOccasionData: {
    isLoading: false,
  },

  deleteOccasionData: {
    isLoading: false,
  },

  editOccasionData: {
    isLoading: false,
  },
};

const occasion2Reducer = (s = initialState, action) => {
  const state = structuredClone(s);
  switch (action.type) {
    case occasion2Constants.getAllOccasions.request:
      state.getAllOccasionsData.isLoading = true;
      break;
    case occasion2Constants.getAllOccasions.success:
      state.getAllOccasionsData.data = action.payload;
      state.getAllOccasionsData.isLoading = false;
      break;
    case occasion2Constants.getAllOccasions.error:
      state.getAllOccasionsData.isLoading = false;
      break;

    case occasion2Constants.getSingleOccasion.request:
      state.getSingleOccasionData.isLoading = true;
      break;
    case occasion2Constants.getSingleOccasion.success:
      state.getSingleOccasionData.data = action.payload;
      state.getSingleOccasionData.isLoading = false;
      break;
    case occasion2Constants.getSingleOccasion.error:
      state.getSingleOccasionData.isLoading = false;
      break;

    case occasion2Constants.createOccasion.request:
      state.createOccasionData.isLoading = true;
      break;
    case occasion2Constants.createOccasion.success:
      state.createOccasionData.isLoading = false;
      break;
    case occasion2Constants.createOccasion.error:
      state.createOccasionData.isLoading = false;
      break;

    case occasion2Constants.editOccasion.request:
      state.editOccasionData.isLoading = true;
      break;
    case occasion2Constants.editOccasion.success:
      state.editOccasionData.isLoading = false;
      break;
    case occasion2Constants.editOccasion.error:
      state.editOccasionData.isLoading = false;
      break;

    case occasion2Constants.deleteOccasion.request:
      state.deleteOccasionData.isLoading = true;
      break;
    case occasion2Constants.deleteOccasion.success:
      state.deleteOccasionData.isLoading = false;
      break;
    case occasion2Constants.deleteOccasion.error:
      state.deleteOccasionData.isLoading = false;
      break;

    default:
      break;
  }

  return state;
};

export default occasion2Reducer;
