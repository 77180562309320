import { useState } from "react";
import useUserChange from "./useUserChange";
import { getLoggedInUserDetails } from "../utils";

const useUser = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);

  useUserChange(() => {
    setLoggedInUser(getLoggedInUserDetails().user);
  });

  return loggedInUser;
};

export default useUser;
