export const authConstant = {
  ADMIN_LOGIN_REQUEST: "ADMIN_LOGIN_REQUEST",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  ADMIN_LOGOUT_REQUEST: "ADMIN_LOGOUT_REQUEST",
  ADMIN_LOGOUT_SUCCESS: "ADMIN_LOGOUT_SUCCESS",
  ADMIN_LOGOUT_FAILURE: "ADMIN_LOGOUT_FAILURE",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
};
