import React from "react";
import { VendorMessage } from "../../components";
import { dummy__messages, userData } from "../../data/messageData";
const ChatWithVender = () => {
  return (
    <>
      <VendorMessage users={userData} messages={dummy__messages} />
    </>
  );
};

export default ChatWithVender;
