import React, { useState, useEffect } from "react";
import { TbSearch } from "react-icons/tb";
import { profile } from "../../assests";
import { Container, Grid, Heading, UserCard } from "../../components";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import { GetAllCustomer, clearErrors } from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./Customer.scss";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { BACKEND_STATIC_URL } from "../../constants";
import { StyledOverflowScroll } from "../analytics/Analytics";
import styled from "@emotion/styled";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-root": {
      color: "black",
      backgroundColor: "#ffc841",
      "&:hover": {
        backgroundColor: "#ffc841",
        color: "black",
      },
      "& .Mui-selected": {
        backgroundColor: "#ffc841",
        color: "black",
      },
    },
  },
});

const Customer = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { data, totalResults, errors, loading, totalPages } = useSelector(
    (state) => state.adminReducer
  );

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
  }, [errors]);

  useEffect(() => {
    dispatch(GetAllCustomer(page));
    setSearchText("");
  }, [page]);
  return (
    <StyledOverflowScroll>
      <div className="m-5">
        <Container className="lg">
          <Heading className="primary center m-5">Customers</Heading>
          <div className="customerpage m-5">
            <div className="customerpage-search">
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <TbSearch />
            </div>
            <div className="customerpage-activeusers">
              <p>Active customer</p>
              <p>{totalResults ? totalResults : 0}</p>
            </div>
          </div>
          <div className="m-5">
            <LayoutStyled>
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : data?.length > 0 ? (
                data
                  .filter(
                    (data) =>
                      `${data?.fullName} ${data?.userId || ""}`
                        ?.toLowerCase()
                        .search(searchText?.toLowerCase()) !== -1
                  )
                  .map((item, ind) => {
                    return (
                      <UserCard
                        key={ind}
                        image={
                          item?.photoPath
                            ? `${BACKEND_STATIC_URL}${item?.photoPath}`
                            : profile
                        }
                        userId={item.userId}
                        name={item.fullName}
                        to={`/customer_detail/${item.id}`}
                      />
                    );
                  })
              ) : (
                <h1>No record found</h1>
              )}
            </LayoutStyled>
          </div>
          {data.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4rem",
              }}
            >
              <Pagination
                classes={{ root: classes.root }}
                variant="outlined"
                count={totalPages}
                page={page}
                size="large"
                showFirstButton
                showLastButton
                onChange={(e, value) => setPage(value)}
              />
            </div>
          )}
        </Container>
      </div>
    </StyledOverflowScroll>
  );
};

export default Customer;

export const LayoutStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  gap: 1.4rem;
`;
