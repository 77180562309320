import { authConstant } from "./../constants";

const initialState = {
  errors: [],
  message: "",
};

const customerAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstant.ADMIN_LOGIN_REQUEST:
    case authConstant.ADMIN_LOGOUT_REQUEST:
    case authConstant.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        errors: [],
      };
    case authConstant.ADMIN_LOGIN_SUCCESS:
    case authConstant.ADMIN_LOGOUT_SUCCESS:
    case authConstant.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case authConstant.ADMIN_LOGIN_FAILURE:
    case authConstant.ADMIN_LOGOUT_FAILURE:
    case authConstant.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.err,
        message: "",
      };
    case authConstant.CLEAR_MESSAGES:
      return {
        ...state,
        loading: false,
        message: "",
        errors: [],
      };
    case authConstant.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: [],
        message: "",
      };
    default:
      return state;
  }
};

export default customerAuthReducer;
