import {
  addDoc,
  and,
  collection,
  getDocs,
  onSnapshot,
  or,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./config";
import getLoggedInUserDetails from "../utils/getLoggedInUserDetails";

export const sendMessage = async (data) => {
  const collectionRef = collection(db, "customerVendorChat");
  await addDoc(collectionRef, { ...data, createdAt: serverTimestamp() });
};

export const sendMessageNew = async ({
  chatId,
  senderId,
  receiverId,
  message,
}) => {
  if (!chatId || !senderId || !receiverId || !message) return;

  const collectionRef = collection(db, "chat");
  await addDoc(collectionRef, {
    chatId,
    senderId,
    receiverId,
    isSeen: false,
    message,
    createdAt: serverTimestamp(),
    deleteBySender: false,
    deleteByReceiver: false,
  });
};

export const createSnapShotListenerForMessages = (
  loggedInUserId,
  onNewMessage
) => {
  if (!loggedInUserId || !onNewMessage) return;

  const collectionRef = collection(db, "chat");
  const q = query(
    collectionRef,
    or(
      where("senderId", "==", loggedInUserId),
      where("receiverId", "==", loggedInUserId)
    ),
    orderBy("createdAt")
  );

  return onSnapshot(q, (querySnapshot) => {
    onNewMessage(querySnapshot);
  });
};

export const updateSeen = async (loggedInUserId, chatId) => {
  if (!loggedInUserId || !chatId) return;
  try {
    const collectionRef = collection(db, "chat");
    const q = query(
      collectionRef,
      and(
        where("chatId", "==", chatId),
        where("receiverId", "==", loggedInUserId)
      ),
      orderBy("createdAt")
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const chatDocRef = doc.ref;
      await updateDoc(chatDocRef, {
        isSeen: true,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteChat = async (chatId) => {
  if (!chatId) return;
  const loggedInUserId = getLoggedInUserDetails()?.user?.id;

  try {
    const collectionRef = collection(db, "chat");
    const q = query(
      collectionRef,
      and(
        where("chatId", "==", chatId),
        or(
          and(
            where("senderId", "==", loggedInUserId),
            where("deleteBySender", "==", false)
          ),
          and(
            where("receiverId", "==", loggedInUserId),
            where("deleteByReceiver", "==", false)
          )
        )
      ),
      orderBy("createdAt")
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const chatDocRef = doc.ref;
      const updatedObj = {};
      if (doc.data()?.senderId === loggedInUserId) {
        updatedObj.deleteBySender = true;
      } else {
        updatedObj.deleteByReceiver = true;
      }
      await updateDoc(chatDocRef, updatedObj);
    });
  } catch (error) {
    console.log(error);
  }
};
