export const servicesConstants = {
  getAllServicesWithVendors: {
    request: "GET_ALL_SERVICES_WITH_VENDORS_REQUEST",
    success: "GET_ALL_SERVICES_WITH_VENDORS_SUCCESS",
    error: "GET_ALL_SERVICES_WITH_VENDORS_FAILURE",
  },

  deleteVendorService: {
    request: "DELETE_VENDOR_SERVICE_REQUEST",
    success: "DELETE_VENDOR_SERVICE_SUCCESS",
    error: "DELETE_VENDOR_SERVICE_FAILURE",
  },

  patchVendorServiceDeleteRequest: {
    request: "PATCH_VENDOR_SERVICE_DELETE_REQUEST_REQUEST",
    success: "PATCH_VENDOR_SERVICE_DELETE_REQUEST_SUCCESS",
    error: "PATCH_VENDOR_SERVICE_DELETE_REQUEST_FAILURE",
  },
};
