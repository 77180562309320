import React from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Routes,
} from "react-router-dom";
import { PanelLayout } from "./layout";
import {
  AddFaq,
  AddPromoCode,
  AddReview,
  Analytics,
  Chat,
  ChatWithCustomer,
  ChatWithVender,
  Customer,
  CustomerDetail,
  Dashboard,
  Faq,
  InterfaceFeature,
  Login,
  ManageOccasions,
  MemberShip,
  PromoCode,
  Reviews,
  ServiceDetail,
  Settings,
  SubscribersList,
  SupportTickets,
  Vender,
  VenderDetail,
  VenderServiceGrid,
  VenderServices,
  VendorsAccordingToCities,
  VerificationRequest,
  VerificationRequestDetail,
  Wallet,
} from "./module";
import {
  ProtectedRoutes,
  ProtectedloginRoutes,
  AccessDenied,
  NotFound,
} from "./components";
import { Toaster } from "react-hot-toast";
import ServicesCrud from "./module/ServicesCrud/ServicesCrud";
import ServiceTypeDetail from "./module/serviceTypeDetail/ServiceTypeDetail";
import EditServiceType from "./module/EditServiceType/EditServiceType";
import { NavigateOutsideofComponent } from "./utils/history";
import OccasionDetails from "./module/OccasionDetails/OccasionDetails";

const Routess = () => {
  return (
    <>
      <HashRouter>
        <NavigateOutsideofComponent />
        <Toaster
          toastOptions={{
            style: {
              fontSize: "18px",
            },
          }}
        />
        <Routes>
          <Route element={<ProtectedloginRoutes />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="" element={<PanelLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/chat/:chatWith" element={<Chat />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route
                path="/message-to-customer/:id?"
                element={<ChatWithCustomer />}
              />
              <Route
                path="/message-to-vender/:id?"
                element={<ChatWithVender />}
              />
              <Route path="/customer" element={<Customer />} />
              <Route path="/customer_detail/:id" element={<CustomerDetail />} />
              <Route path="/vender" element={<Vender />} />
              <Route path="/vender_detail/:id" element={<VenderDetail />} />
              <Route path="/manage-services" element={<ServicesCrud />} />
              <Route path="/vender-services" element={<VenderServices />} />
              <Route
                path="/vendors-according-to-cities"
                element={<VendorsAccordingToCities />}
              />
              <Route
                path="/vender-services-grid/:id"
                element={<VenderServiceGrid />}
              />
              <Route
                path="/interface-features"
                element={<InterfaceFeature />}
              />
              <Route path="/membership" element={<MemberShip />} />
              <Route path="/promocode" element={<PromoCode />} />
              <Route path="/add_promocode/:id?" element={<AddPromoCode />} />
              <Route
                path="/verification-request"
                element={<VerificationRequest />}
              />
              <Route
                path="/verification_detail/:id"
                element={<VerificationRequestDetail />}
              />
              <Route path="/service_detail/:id" element={<ServiceDetail />} />
              <Route path="/service_type/:id" element={<ServiceTypeDetail />} />
              <Route
                path="/edit_service_type/:id"
                element={<EditServiceType />}
              />
              {/* Occassion routes */}
              <Route path="/manage-occasions" element={<ManageOccasions />} />
              <Route
                path="/manage-occasions/:id"
                element={<OccasionDetails />}
              />

              {/* End Occassion Routes */}
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/add_review" element={<AddReview />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/faqs" element={<Faq />} />
              <Route path="/add_faq" element={<AddFaq />} />
              <Route path="/subscribers" element={<SubscribersList />} />
              <Route path="/support-tickets" element={<SupportTickets />} />
            </Route>
          </Route>
          <Route
            path="/not-found"
            element={<h1 className="text-white">Not found</h1>}
          />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </>
  );
};
export default Routess;
