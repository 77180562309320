import React, { useEffect, useState } from "react";
import "./Wallet.scss";
import { Container } from "../../components";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  GetTransactionHistory,
  clearErrors,
  clearMessages,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

const Wallet = () => {
  const dispatch = useDispatch();
  const { message, errors, transactionHistory, loading } = useSelector(
    (state) => state.adminReducer
  );
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [dispatch, errors, message]);

  useEffect(() => {
    dispatch(
      GetTransactionHistory({
        created_after: fromDate ? new Date(fromDate).getTime() : undefined,
        created_before: toDate ? new Date(toDate).getTime() : undefined,
      })
    );
  }, [dispatch, fromDate, toDate]);

  return (
    <>
      <Container className="lg">
        <div className="wallet m-5">
          <TopContainerStyled>
            <div className="top">
              <h2>Transaction History</h2>
              <p>View transaction history</p>
            </div>

            <div className="bottom">
              <div className="left">
                <p>
                  Total Amount of these transactions:{" "}
                  <span className="bold">
                    {transactionHistory?.reduce(
                      (prevVal, obj) => prevVal + Number(obj.amount),
                      0
                    ) || 0}
                  </span>
                </p>
              </div>

              <div className="right">
                <input
                  type="date"
                  placeholder="From"
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                />
                <span></span>
                <input
                  type="date"
                  placeholder="To"
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
              </div>
            </div>
          </TopContainerStyled>
          <div className="wallet-container">
            <div className="wallet-container-second">
              <div className="wallet-container-second-table">
                <table>
                  <thead>
                    <tr>
                      <th>Vendor</th>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <Puff
                        height="60"
                        width="60"
                        radius="6"
                        color="black"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass
                      />
                    ) : transactionHistory.length > 0 ? (
                      transactionHistory.map((data, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              {data?.customer?.email || "Vendor deleted!"}
                            </td>
                            <td>{data?.description}</td>
                            <td>{data?.date}</td>
                            <td>£ {data?.amount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>No transaction found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Wallet;

const TopContainerStyled = styled.div`
  display: grid;
  gap: 1rem;
  & > .top {
    & > h2 {
      font-size: 2.2rem;
      color: #000;
    }

    & > p {
      font-size: 1.4rem;
      color: #444444;
    }
  }

  & > .bottom {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: auto auto;
    padding-bottom: 2rem;

    & > .left {
      display: grid;
      align-items: center;
      grid-template-columns: auto;

      & > p {
        font-size: 1.6rem;

        & .bold {
          font-weight: 600;
        }
      }
    }

    & > .right {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: auto auto auto;
      align-items: center;

      & > input {
        width: 13rem;
        border: 1px solid #dfdfdf;
        outline: none;
        border-radius: 0.8rem;
        padding: 0.8rem 1.2rem;
        font-size: 1.6rem;
        color: #838282;
      }
      & > span {
        display: block;
        width: 1rem;
        height: 0.1rem;
        background-color: black;
      }
    }
  }
`;
