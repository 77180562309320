export default function getUniqueVendors(arr) {
  const vendors = [];

  for (const service of arr) {
    if (!vendors.find((v) => v.id === service?.vendor?.id)) {
      vendors.push(service?.vendor);
    }
  }

  return vendors;
}
