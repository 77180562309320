import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { NewBlink, profile } from "../../assests";
import styled from "@emotion/styled";
import moment from "moment";

const VendorServiceStatusCardStyled = styled(Link)`
  display: grid;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #f2f1f1;

  border-radius: 3rem;
  padding: 3rem;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;

  &:hover {
    opacity: 0.7;
  }

  & > img {
    width: 7.8rem;
    height: 7.8rem;
    object-fit: cover;
    border-radius: 50%;
  }

  & > div {
    display: grid;
    gap: 1rem;

    & > h5 {
      font-size: 2rem;
      line-height: 100%;
      color: #808080;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > p {
      font-size: 1.4rem;
      line-height: 100%;
      color: #212121;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const VendorServiceStatusCard = ({
  to,
  img,
  vendorName,
  serviceName,
  createdAt,
  status,
  statusUpdateDate,
  deleteStatus,
}) => {
  const titleName = useMemo(() => {
    const f1 = () => {
      switch (status) {
        case "Pending":
          return "Requested At";
        case "Accepted":
          return "Accepteded At";
        case "Hold":
          return "Held At";
        case "Rejected":
          return "Rejected At";
        default:
          return "";
      }
    };
    return deleteStatus === "NULL" ? f1() : "Requested At";
  }, [status, deleteStatus]);

  console.log();
  return (
    <VendorServiceStatusCardStyled to={to} title={vendorName}>
      <img src={img || profile} alt={vendorName} />

      <div>
        <h5>{vendorName || "--"}</h5>
        <p>
          <strong>Service Type: </strong>
          {serviceName || "--"}
        </p>
        <p>
          <strong>Created At: </strong>
          {!createdAt
            ? "--"
            : moment(new Date(createdAt)).format("MMM DD, YYYY")}
        </p>
        {titleName && statusUpdateDate && (
          <p>
            <strong>{titleName}: </strong>
            {moment(new Date(statusUpdateDate)).format("MMM DD, YYYY")}
          </p>
        )}
      </div>
    </VendorServiceStatusCardStyled>
  );
};

export default VendorServiceStatusCard;
