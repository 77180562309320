import axios from "axios";
import asyncAction from "../../utils/asyncAction";
import { servicesConstants } from "../constants";
import { BACKEND_URL } from "../../constants";

export const getAllServicesWithVendors = (
  { status, page, deleteStatus, query },
  onSuccess,
  onError
) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service?page=${page}&${
          deleteStatus === "PENDING"
            ? `deleteStatus=${deleteStatus}`
            : `status=${status}`
        }${
          status === "Pending" && deleteStatus !== "PENDING"
            ? "&getAcceptedEdit=true"
            : ""
        }&limit=16${query ? `&query=${query}` : ""}`,
        authHeaders
      );
      return {
        services: data?.results ?? [],
        totalPages: data?.totalPages ?? 1,
      };
    },
    servicesConstants.getAllServicesWithVendors,
    onSuccess,
    onError
  );
};

export const getSingleVendorServices = ({ id, page }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service?vendor=${id}&page=${page}&limit=16`,
        authHeaders
      );
      return {
        services: data?.results ?? [],
        totalPages: data?.totalPages ?? 1,
      };
    },
    servicesConstants.getAllServicesWithVendors,
    onSuccess,
    onError
  );
};

export const deleteVendorService = ({ id }, onSuccess, onError) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.delete(
        `${BACKEND_URL}/v1/api/service/${id}`,
        authHeaders
      );
      return {
        data,
      };
    },
    servicesConstants.deleteVendorService,
    onSuccess,
    onError
  );
};

export const patchVendorServiceDeleteRequest = (
  { id, body },
  onSuccess,
  onError
) => {
  return asyncAction(
    async (authHeaders) => {
      const { data } = await axios.patch(
        `${BACKEND_URL}/v1/api/service/delete-request/${id}`,
        body,
        authHeaders
      );
      return { data };
    },
    servicesConstants.patchVendorServiceDeleteRequest,
    onSuccess,
    onError
  );
};
