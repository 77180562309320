import getLoggedInUserDetails from "./getLoggedInUserDetails";
import globalHookData from "./history";

export const getChatOfAvailableChats = (idOne, idTwo) => {
  return globalHookData.useSelector?.chatReducer?.getAllChatsData.data?.find(
    (c) => {
      const participantsIds = c?.participants
        ?.map((p) => p?.detail?.id)
        ?.filter((p) => p);

      return (
        (participantsIds[0] === idOne && participantsIds[1] === idTwo) ||
        (participantsIds[0] === idTwo && participantsIds[1] === idOne)
      );
    }
  );
};

export const getChatById = (chatId, allChats) => {
  return allChats?.find((c) => c._id === chatId);
};

export const getParticipants = (chat) => {
  return chat?.participants?.map((p) => p.detail);
};

export const getParticipantsIds = (chat) => {
  return getParticipants(chat)?.map((user) => user._id);
};

export const getOtherSideParticipant = (chat) => {
  const loggedInUserId = getLoggedInUserDetails().user?.id;

  return getParticipants(chat)?.find((p) => p?.id !== loggedInUserId);
};

export const getOtherSideParticipantId = (chat) => {
  return getOtherSideParticipant(chat)?.id;
};

export const getNumberOfUnseenMessages = (chatId, allMessages) => {
  const loggedInUserId = getLoggedInUserDetails().user?.id;

  return (
    allMessages.filter(
      (m) => m.chatId === chatId && !m.isSeen && m.receiverId === loggedInUserId
    ).length || 0
  );
};

export const getMessagesOfChat = (chatId, allMessages) => {
  return allMessages.filter((m) => m.chatId === chatId);
};

export const getLastMessageCreatedAt = (chatId, allMessages) => {
  const singleChatAllMessages = getMessagesOfChat(chatId, allMessages);
  const latestMessageOfChat =
    singleChatAllMessages[singleChatAllMessages?.length - 1];

  if (!latestMessageOfChat) return 0;

  return latestMessageOfChat.createdAt?.seconds
    ? latestMessageOfChat.createdAt?.seconds * 1000
    : Date.now();
};
