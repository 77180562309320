export const adminConstant = {
  CREATE_PROMO_CODE_REQUEST: "CREATE_PROMO_CODE_REQUEST",
  CREATE_PROMO_CODE_SUCCESS: "CREATE_PROMO_CODE_SUCCESS",
  CREATE_PROMO_CODE_FAILURE: "CREATE_PROMO_CODE_FAILURE",
  GET_PROMO_CODE_REQUEST: "GET_PROMO_CODE_REQUEST",
  GET_PROMO_CODE_SUCCESS: "GET_PROMO_CODE_SUCCESS",
  GET_PROMO_CODE_FAILURE: "GET_PROMO_CODE_FAILURE",
  DELETE_PROMO_CODE_REQUEST: "DELETE_PROMO_CODE_REQUEST",
  DELETE_PROMO_CODE_SUCCESS: "DELETE_PROMO_CODE_SUCCESS",
  DELETE_PROMO_CODE_FAILURE: "DELETE_PROMO_CODE_FAILURE",
  CREATE_INTERFACE_FEATURE_REQUEST: "CREATE_INTERFACE_FEATURE_REQUEST",
  CREATE_INTERFACE_FEATURE_SUCCESS: "CREATE_INTERFACE_FEATURE_SUCCESS",
  CREATE_INTERFACE_FEATURE_FAILURE: "CREATE_INTERFACE_FEATURE_FAILURE",
  GET_ALL_CUSTOMER_REQUEST: "GET_ALL_CUSTOMER_REQUEST",
  GET_ALL_CUSTOMER_SUCCESS: "GET_ALL_CUSTOMER_SUCCESS",
  GET_ALL_CUSTOMER_FAILURE: "GET_ALL_CUSTOMER_FAILURE",
  GET_ALL_VENDOR_REQUEST: "GET_ALL_VENDOR_REQUEST",
  GET_ALL_VENDOR_SUCCESS: "GET_ALL_VENDOR_SUCCESS",
  GET_ALL_VENDOR_FAILURE: "GET_ALL_VENDOR_FAILURE",
  GET_SINGLE_VENDOR_REQUEST: "GET_SINGLE_VENDOR_REQUEST",
  GET_SINGLE_VENDOR_SUCCESS: "GET_SINGLE_VENDOR_SUCCESS",
  GET_SINGLE_VENDOR_FAILURE: "GET_SINGLE_VENDOR_FAILURE",
  DELETE_SINGLE_VENDOR_REQUEST: "DELETE_SINGLE_VENDOR_REQUEST",
  DELETE_SINGLE_VENDOR_SUCCESS: "DELETE_SINGLE_VENDOR_SUCCESS",
  DELETE_SINGLE_VENDOR_FAILURE: "DELETE_SINGLE_VENDOR_FAILURE",
  GET_SINGLE_CUSTOMER_REQUEST: "GET_SINGLE_CUSTOMER_REQUEST",
  GET_SINGLE_CUSTOMER_SUCCESS: "GET_SINGLE_CUSTOMER_SUCCESS",
  GET_SINGLE_CUSTOMER_FAILURE: "GET_SINGLE_CUSTOMER_FAILURE",
  DELETE_SINGLE_CUSTOMER_REQUEST: "DELETE_SINGLE_CUSTOMER_REQUEST",
  DELETE_SINGLE_CUSTOMER_SUCCESS: "DELETE_SINGLE_CUSTOMER_SUCCESS",
  DELETE_SINGLE_CUSTOMER_FAILURE: "DELETE_SINGLE_CUSTOMER_FAILURE",
  GET_SERVICE_BY_STATUS_REQUEST: "GET_SERVICE_BY_STATUS_REQUEST",
  GET_SERVICE_BY_STATUS_SUCCESS: "GET_SERVICE_BY_STATUS_SUCCESS",
  GET_SERVICE_BY_STATUS_FAILURE: "GET_SERVICE_BY_STATUS_FAILURE",
  UPDATE_SERVICE_BY_STATUS_REQUEST: "UPDATE_SERVICE_BY_STATUS_REQUEST",
  UPDATE_SERVICE_BY_STATUS_SUCCESS: "UPDATE_SERVICE_BY_STATUS_SUCCESS",
  UPDATE_SERVICE_BY_STATUS_FAILURE: "UPDATE_SERVICE_BY_STATUS_FAILURE",
  ADD_FAQ_REQUEST: "ADD_FAQ_REQUEST",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  ADD_FAQ_FAILURE: "ADD_FAQ_FAILURE",
  GET_FAQ_REQUEST: "GET_FAQ_REQUEST",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAILURE: "GET_FAQ_FAILURE",
  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAILURE: "DELETE_FAQ_FAILURE",
  GET_MEMBERSHIP_SUBSCRIPTION_REQUEST: "GET_MEMBERSHIP_SUBSCRIPTION_REQUEST",
  GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS: "GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS",
  GET_MEMBERSHIP_SUBSCRIPTION_FAILURE: "GET_MEMBERSHIP_SUBSCRIPTION_FAILURE",
  UPDATE_MEMBERSHIP_SUBSCRIPTION_REQUEST:
    "UPDATE_MEMBERSHIP_SUBSCRIPTION_REQUEST",
  UPDATE_MEMBERSHIP_SUBSCRIPTION_SUCCESS:
    "UPDATE_MEMBERSHIP_SUBSCRIPTION_SUCCESS",
  UPDATE_MEMBERSHIP_SUBSCRIPTION_FAILURE:
    "UPDATE_MEMBERSHIP_SUBSCRIPTION_FAILURE",
  GET_SINGLE_SERVICE_REQUEST: "GET_SINGLE_SERVICE_REQUEST",
  GET_SINGLE_SERVICE_SUCCESS: "GET_SINGLE_SERVICE_SUCCESS",
  GET_SINGLE_SERVICE_FAILURE: "GET_SINGLE_SERVICE_FAILURE",
  GET_VENDOR_ALL_SERVICE_REQUEST: "GET_VENDOR_ALL_SERVICE_REQUEST",
  GET_VENDOR_ALL_SERVICE_SUCCESS: "GET_VENDOR_ALL_SERVICE_SUCCESS",
  GET_VENDOR_ALL_SERVICE_FAILURE: "GET_VENDOR_ALL_SERVICE_FAILURE",
  GET_TOP_FIVE_CUSTOMER_SUCCESS: "GET_TOP_FIVE_CUSTOMER_SUCCESS",
  GET_TOP_FIVE_CUSTOMER_FAILURE: "GET_TOP_FIVE_CUSTOMER_FAILURE",
  GET_TOP_FIVE_SERVICES_SUCCESS: "GET_TOP_FIVE_SERVICES_SUCCESS",
  GET_TOP_FIVE_SERVICES_FAILURE: "GET_TOP_FIVE_SERVICES_FAILURE",
  GET_TOP_FIVE_VENDOR_SUCCESS: "GET_TOP_FIVE_VENDOR_SUCCESS",
  GET_TOP_FIVE_VENDOR_FAILURE: "GET_TOP_FIVE_VENDOR_FAILURE",
  GET_DAILY_USER_SUCCESS: "GET_DAILY_USER_SUCCESS",
  GET_DAILY_USER_FAILURE: "GET_DAILY_USER_FAILURE",
  GET_WEEKLY_USER_SUCCESS: "GET_WEEKLY_USER_SUCCESS",
  GET_WEEKLY_USER_FAILURE: "GET_WEEKLY_USER_FAILURE",
  GET_MONTHLY_USER_SUCCESS: "GET_MONTHLY_USER_SUCCESS",
  GET_MONTHLY_USER_FAILURE: "GET_MONTHLY_USER_FAILURE",
  VISITORS_BY_BROWSERS_SUCCESS: "VISITORS_BY_BROWSERS_SUCCESS",
  VISITORS_BY_BROWSERS_FAILURE: "VISITORS_BY_BROWSERS_FAILURE",
  GET_TRANSACTION_HISTORY_REQUEST: "GET_TRANSACTION_HISTORY_REQUEST",
  GET_TRANSACTION_HISTORY_SUCCESS: "GET_TRANSACTION_HISTORY_SUCCESS",
  GET_TRANSACTION_HISTORY_FAILURE: "GET_TRANSACTION_HISTORY_FAILURE",
  GET_ALL_SUBSCRIPTION_REQUEST: "GET_ALL_SUBSCRIPTION_REQUEST",
  GET_ALL_SUBSCRIPTION_SUCCESS: "GET_ALL_SUBSCRIPTION_SUCCESS",
  GET_ALL_SUBSCRIPTION_FAILURE: "GET_ALL_SUBSCRIPTION_FAILURE",
  CREATE_CHAT_CONNECTION_REQUEST: "CREATE_CHAT_CONNECTION_REQUEST",
  CREATE_CHAT_CONNECTION_SUCCESS: "CREATE_CHAT_CONNECTION_SUCCESS",
  CREATE_CHAT_CONNECTION_FAILURE: "CREATE_CHAT_CONNECTION_FAILURE",
  ADD_MESSAGE_REQUEST: "ADD_MESSAGE_REQUEST",
  ADD_MESSAGE_SUCCESS: "ADD_MESSAGE_SUCCESS",
  ADD_MESSAGE_FAILURE: "ADD_MESSAGE_FAILURE",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",
  GET_CUSTOMER_FOR_CHAT_REQUEST: "GET_CUSTOMER_FOR_CHAT_REQUEST",
  GET_CUSTOMER_FOR_CHAT_SUCCESS: "GET_CUSTOMER_FOR_CHAT_SUCCESS",
  GET_CUSTOMER_FOR_CHAT_FAILURE: "GET_CUSTOMER_FOR_CHAT_FAILURE",
  GET_VENDOR_FOR_CHAT_REQUEST: "GET_VENDOR_FOR_CHAT_REQUEST",
  GET_VENDOR_FOR_CHAT_SUCCESS: "GET_VENDOR_FOR_CHAT_SUCCESS",
  GET_VENDOR_FOR_CHAT_FAILURE: "GET_VENDOR_FOR_CHAT_FAILURE",
  GET_ALL_VENDOR_FOR_CHAT_REQUEST: "GET_ALL_VENDOR_FOR_CHAT_REQUEST",
  GET_ALL_VENDOR_FOR_CHAT_SUCCESS: "GET_ALL_VENDOR_FOR_CHAT_SUCCESS",
  GET_ALL_VENDOR_FOR_CHAT_FAILURE: "GET_ALL_VENDOR_FOR_CHAT_FAILURE",
  GET_ALL_CHATS_IDS_REQUEST: "GET_ALL_CHATS_IDS_REQUEST",
  GET_ALL_CHATS_IDS_SUCCESS: "GET_ALL_CHATS_IDS_SUCCESS",
  GET_ALL_CHATS_IDS_FAILURE: "GET_ALL_CHATS_IDS_FAILURE",

  GET_NUMBER_OF_VENDORS_BY_CITY_REQUEST:
    "GET_NUMBER_OF_VENDORS_BY_CITY_REQUEST",
  GET_NUMBER_OF_VENDORS_BY_CITY_SUCCESS:
    "GET_NUMBER_OF_VENDORS_BY_CITY_SUCCESS",
  GET_NUMBER_OF_VENDORS_BY_CITY_FAILURE:
    "GET_NUMBER_OF_VENDORS_BY_CITY_FAILURE",

  GET_ALL_SUBSCRIBER_LIST_REQUEST: "GET_ALL_SUBSCRIBER_LIST_REQUEST",
  GET_ALL_SUBSCRIBER_LIST_SUCCESS: "GET_ALL_SUBSCRIBER_LIST_SUCCESS",
  GET_ALL_SUBSCRIBER_LIST_FAILURE: "GET_ALL_SUBSCRIBER_LIST_FAILURE",

  GET_ALL_CUSTOMERS_LIST_REQUEST: "GET_ALL_CUSTOMERS_LIST_REQUEST",
  GET_ALL_CUSTOMERS_LIST_SUCCESS: "GET_ALL_CUSTOMERS_LIST_SUCCESS",
  GET_ALL_CUSTOMERS_LIST_FAILURE: "GET_ALL_CUSTOMERS_LIST_FAILURE",

  RESET_ADMIN_REDUCER: "RESET_ADMIN_REDUCER",
};
