import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./VendorMessages.scss";
import { BiSend, BiSearch } from "react-icons/bi";
import { profile } from "../../assests";
import ScrollToBottom from "react-scroll-to-bottom";
import { TfiMenuAlt } from "react-icons/tfi";
import { RxCross2 } from "react-icons/rx";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  CreateChatConnectionForUser,
  GetAllVendorForChat,
  addMessage,
  GetSingleVendorForChat,
  clearErrors,
  clearMessages,
  getAllChatIds,
  resetReducer,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { db, storage } from "../../firebase";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  addDoc,
  serverTimestamp,
  getDocs,
  writeBatch,
} from "firebase/firestore";

const Messages = (props) => {
  const dispatch = useDispatch();
  const {
    message,
    errors,
    vendorData,
    chatConnection,
    vendorInfo,
    loading,
    chatConnectionIds,
  } = useSelector((state) => state.adminReducer);

  const [msg, setMsg] = useState("");
  const [showContact, setshowContact] = useState(true);
  const [messages, setMessages] = useState([]);
  const [searchText, setSearchText] = useState("");

  const messageHandler = (event) => {
    setMsg(event.target.value);
  };

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);

  const user = JSON.parse(sessionStorage.getItem("admin"));

  // useEffect(() => {
  //     if (chatConnection.id) {
  //         const collectionRef = collection(db, "customerVendorChat");
  //         const q = query(
  //             collectionRef,
  //             where("conversationId", "==", chatConnection.id),
  //             orderBy("createdAt")
  //         );
  //         const unsubscribe = onSnapshot(q, (snapshot) => {
  //             setMessages(
  //                 snapshot.docs.map((doc) => ({
  //                     ...doc.data(),
  //                     _id: doc.id,
  //                 }))
  //             );
  //         });

  //         return () => unsubscribe();
  //     }
  // }, [chatConnection]);

  useEffect(() => {
    if (!(chatConnectionIds?.length === 0 || vendorData?.length === 0)) {
      const myChatsMembersIds = vendorData?.map((member) => member?.id);
      const myChatConnections = chatConnectionIds?.filter((chat) =>
        myChatsMembersIds?.some(
          (id) =>
            chat?.members?.includes(id) && chat?.members?.includes(user?.id)
        )
      );

      const collectionRef = collection(db, "customerVendorChat");
      const unsubscribeFunctions = [];

      myChatConnections
        ?.map((chat) => chat?.id)
        ?.forEach((connectionId) => {
          const q = query(
            collectionRef,
            where("conversationId", "==", connectionId),
            orderBy("createdAt")
          );

          const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessage = snapshot.docs.map((doc) => ({
              ...doc.data(),
              _id: doc.id,
            }));

            setMessages((prevMessages) => ({
              ...prevMessages,
              [connectionId]: newMessage,
            }));
          });

          unsubscribeFunctions.push(unsubscribe);
        });

      return () => {
        unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
      };
    }
  }, [chatConnectionIds, user?.id, vendorData]);

  useEffect(() => {
    if (chatConnection?.id && messages[chatConnection?.id]?.length > 0) {
      const f1 = async () => {
        const collectionRef = collection(db, "customerVendorChat");

        const q = query(
          collectionRef,
          where("isSeen", "==", false),
          where("conversationId", "==", chatConnection?.id),
          where(
            "senderId",
            "==",
            chatConnection?.members.find((id) => id !== user?.id)
          )
        );

        const querySnapshot = await getDocs(q);

        const batch = writeBatch(db);

        querySnapshot.forEach((doc) => {
          const docRef = doc.ref;
          batch.update(docRef, { isSeen: true });
        });

        await batch.commit();
      };

      f1();
    }
  }, [chatConnection, messages, user?.id]);

  useEffect(() => {
    dispatch(getAllChatIds());
  }, [dispatch]);

  useLayoutEffect(
    () => () => {
      dispatch(resetReducer());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(GetAllVendorForChat());
    if (vendorData?.length > 0) {
      let result = {
        member: vendorData[0]?.id && vendorData[0].id,
      };
      dispatch(CreateChatConnectionForUser(result));
      dispatch(GetSingleVendorForChat(vendorData[0]?.id && vendorData[0].id));
    }
  }, []);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  let loginUser = admin?.id;
  const hanldeChatMessage = () => {
    let result = {
      message: msg,
      conversationId: chatConnection?.id ? chatConnection.id : "",
      senderId: loginUser,
      isSeen: false,
    };
    dispatch(addMessage(result));
    setMsg("");
  };
  return (
    <>
      <div className="chat">
        <div className="chat-container">
          {showContact ? (
            <div className="chat-container-user">
              <div className="chat-container-user-search">
                <input
                  type="text"
                  placeholder="Search messages"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <BiSearch className="chat-container-user-search-icon" />
              </div>
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : vendorData?.length > 0 ? (
                vendorData
                  ?.filter((data) =>
                    data.fullName
                      ?.toLowerCase()
                      ?.startsWith(searchText?.toLowerCase())
                  )
                  ?.map((item, ind) => {
                    return (
                      <UserMessage
                        key={ind}
                        to={item.id}
                        profile={item?.photoPath ? item.photoPath : profile}
                        name={item?.fullName && item.fullName}
                        // message={vendorData?.message && vendorData.message}
                        userId={item?.id}
                        unseenMessages={
                          messages[
                            chatConnectionIds.find(
                              (chat) =>
                                chat?.members?.includes(item?.id) &&
                                chat?.members?.includes(user?.id)
                            )?.id
                          ]
                            ?.filter((message) => message?.isSeen === false)
                            .filter((message) => message?.senderId !== user?.id)
                            .length
                        }
                      />
                    );
                  })
              ) : (
                <h1>No Vendor Found</h1>
              )}
            </div>
          ) : (
            ""
          )}
          {chatConnection?.id ? (
            <div className="chat-container-feed">
              <div className="chat-container-feed-profile space-between">
                <div className="chat-container-feed-profile-flex  align-item-center">
                  <div
                    className="chat-container-feed-profile-menuicon"
                    onClick={() => setshowContact(!showContact)}
                  >
                    <TfiMenuAlt />
                  </div>
                  <img
                    src={vendorInfo?.photoPath ? vendorInfo.photoPath : profile}
                    alt="profile"
                  />
                  <h3>{vendorInfo?.fullName && vendorInfo.fullName}</h3>
                </div>

                {showContact ? (
                  <div
                    className="chat-container-feed-profile-menuicon-cross"
                    onClick={() => setshowContact(!showContact)}
                  >
                    <RxCross2 />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="chat-container-feed-box">
                <ScrollToBottom>
                  {chatConnection?.id &&
                    messages[chatConnection?.id]?.map((msg) =>
                      msg.senderId != loginUser ? (
                        <Message
                          class="recieve-message"
                          key={msg.id}
                          text={msg.message}
                        />
                      ) : (
                        <Message
                          class="send-message"
                          key={msg.id}
                          text={msg.message}
                        />
                      )
                    )}
                </ScrollToBottom>
              </div>
              <div className="chat-container-feed-input">
                <form onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="text"
                    placeholder="Type a message"
                    value={msg}
                    onChange={messageHandler}
                  />
                  <button
                    className="message-send-btn "
                    onClick={() => hanldeChatMessage()}
                  >
                    <BiSend />
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <center
              style={{
                color: "black",
                margin: "18%",
              }}
            >
              <h1>Click On Any User For Chat</h1>
            </center>
          )}
        </div>
      </div>
    </>
  );
};

export default Messages;

const UserMessage = (props) => {
  const dispatch = useDispatch();
  const handleChatConnection = (userId) => {
    let result = { member: userId };
    dispatch(CreateChatConnectionForUser(result));
    dispatch(GetSingleVendorForChat(userId));
  };
  return (
    <>
      <div
        className="usermessage"
        onClick={() => handleChatConnection(props.userId)}
      >
        <div className="usermessage" id={props.active}>
          <div className="usermessage-container">
            <div className="usermessage-container-flex">
              <div className="usermessage-container-left">
                <div className="usermessage-container-image">
                  <img src={props.profile} alt="profile" />
                </div>
                <div className="usermessage-container-name">
                  <h3>{props.name}</h3>
                  <p>{props.message}</p>
                </div>
              </div>
              {props.unseenMessages ? (
                <div className="usermessage-container-right">
                  <span>
                    {props.unseenMessages > 9 ? "9+" : props.unseenMessages}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Message = (props) => {
  const messagesEndRef = useRef();
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }, [props.children]);
  return (
    <>
      <div className={`message ${props.class}`}>
        <div className="message-container">
          <p ref={messagesEndRef}>{props.text}</p>
        </div>
      </div>
    </>
  );
};
