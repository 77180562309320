import axios from "axios";
import asyncAction from "../../utils/asyncAction";
import { fileConstants } from "../constants";
import { BACKEND_URL } from "../../constants";

export const uploadFile = ({ files }, onSuccess, onError) => {
  const formData = new FormData();

  for (const file of files) {
    formData.append("file", file);
  }

  return asyncAction(
    async (authHeaders) => {
      const {
        data: { files },
      } = await axios.post(
        `${BACKEND_URL}/v1/api/upload`,
        formData,
        authHeaders
      );

      return files;
    },
    fileConstants.UPLOAD_FILE,
    onSuccess,
    onError
  );
};
