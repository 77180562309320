import React, { useMemo } from "react";
import "./Heading.scss";

const Heading = (props) => {
  const Element = useMemo(() => props.element || "h1", [props.element]);
  return (
    <>
      <div className={`heading ${props.className}`}>
        <Element>{props.children}</Element>
      </div>
    </>
  );
};

export default Heading;
