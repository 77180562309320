import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { profile } from "../../assests";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  FormText,
  Heading,
  InfoItem,
  Modal,
} from "../../components";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  GetSingleCustomer,
  DeleteSingleCustomer,
  clearErrors,
  clearMessages,
  getAllChats,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, getLoggedInUserDetails } from "../../utils";
import { getChatOfAvailableChats } from "../../utils/getChat";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { BACKEND_STATIC_URL } from "../../constants";

const CustomerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const dispatch = useDispatch();
  const { data, errors, message, loading } = useSelector(
    (state) => state.adminReducer
  );

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/customer"), 2000);
    }
  }, [errors, message, navigate, dispatch]);

  useEffect(() => {
    dispatch(GetSingleCustomer(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllChats());
  }, [dispatch]);

  const deletionValidation = Yup.object({
    reason: Yup.string()
      .min(2, "Must be 2 character")
      .max(250, "Must be 250 characters or less")
      .required("Required"),
  });

  return (
    <>
      {alert ? (
        <Modal onClose={() => setAlert((p) => !p)}>
          <div className="px-3">
            <Heading className="normal center m-4">Delete Account</Heading>
            <div>
              <Formik
                initialValues={{
                  reason: "",
                }}
                validateOnMount
                validationSchema={deletionValidation}
                onSubmit={(values, { resetForm }) => {
                  dispatch(DeleteSingleCustomer(id, values.reason));
                  setAlert(false);
                }}
              >
                {(formik) => (
                  <Form>
                    <FormText
                      label="Provide a reason for acceptance"
                      name="reason"
                      type="text"
                      cols="100"
                      rows="10"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      <Container className="md">
        <Heading className="primary center m-6">Customer Detail</Heading>
        {loading ? (
          <Puff
            height="60"
            width="60"
            radius="6"
            color="black"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        ) : data.length > 0 ? (
          data.map((item, ind) => {
            return (
              <div className="customerdetailpage m-5" key={ind}>
                <div className="customerdetailpage-container m-5">
                  <Container className="lg">
                    <div className="customerdetailpage-container-top">
                      <div className="customerdetailpage-container-top-profile">
                        <img
                          src={
                            item?.photoPath
                              ? `${BACKEND_STATIC_URL}${item?.photoPath}`
                              : profile
                          }
                          alt="profile"
                        />
                        <h4>{item.fullName ? item.fullName : ""}</h4>
                      </div>
                      <div className="customerdetailpage-container-top-button">
                        <Link
                          to={`/chat/customer?chatId=${
                            getChatOfAvailableChats(
                              getLoggedInUserDetails().user?.id,
                              item.id
                            )?._id
                          }`}
                        >
                          Message
                        </Link>
                        <button
                          onClick={() => {
                            setAlert((p) => !p);
                          }}
                        >
                          Delete Account
                        </button>
                      </div>
                    </div>
                  </Container>
                  <div className="customerdetailpage-container-content m-5">
                    <Container className="lg">
                      <InfoItem
                        title="Email"
                        content={item.email ? item.email : ""}
                      />
                      <InfoItem
                        title="Phone number"
                        content={item.phone ? item.phone : ""}
                      />
                      <InfoItem
                        title="Date of join"
                        content={
                          item.createdAt ? formatDate(item.createdAt) : ""
                        }
                      />
                    </Container>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h1>No record found</h1>
        )}
      </Container>
    </>
  );
};

export default CustomerDetail;
