import { adminConstant } from "./../constants";
import axios from "axios";
import { sendMessage } from "./../../firebase";
import { objToStr } from "../../utils";

export const CreatePromoCode = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.CREATE_PROMO_CODE_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.post(`${process.env.REACT_APP_ROOT}/v1/api/coupon`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: adminConstant.CREATE_PROMO_CODE_SUCCESS,
        payload: "Promo has been added",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.CREATE_PROMO_CODE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetPromoCode = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_PROMO_CODE_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/coupon`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_PROMO_CODE_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_PROMO_CODE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const DeletePromoCode = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.DELETE_PROMO_CODE_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.delete(
        `${process.env.REACT_APP_ROOT}/v1/api/coupon/${body}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch(GetPromoCode());
      dispatch({
        type: adminConstant.DELETE_PROMO_CODE_SUCCESS,
        payload: "Promo has been deleted",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.DELETE_PROMO_CODE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const CreateInterfaceFeature = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.CREATE_INTERFACE_FEATURE_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.post(`${process.env.REACT_APP_ROOT}/v1/api/interface`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: adminConstant.CREATE_INTERFACE_FEATURE_SUCCESS,
        payload: "InterfaceFeature has been added",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.CREATE_INTERFACE_FEATURE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllCustomer = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_ALL_CUSTOMER_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth?role=User`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_ALL_CUSTOMER_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
          totalResults: data.totalResults,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_ALL_CUSTOMER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllVendor = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_ALL_VENDOR_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth?role=Vendor`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_ALL_VENDOR_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
          totalResults: data.totalResults,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_ALL_VENDOR_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllVendorForChat = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_ALL_VENDOR_FOR_CHAT_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth?role=Vendor`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_ALL_VENDOR_FOR_CHAT_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
          totalResults: data.totalResults,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_ALL_VENDOR_FOR_CHAT_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};
export const GetSingleVendor = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_SINGLE_VENDOR_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/${body}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_SINGLE_VENDOR_SUCCESS,
        payload: [data],
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_SINGLE_VENDOR_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const DeleteSingleVendor = (id, reason) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.DELETE_SINGLE_VENDOR_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.delete(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/${id}?reason=${reason}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: adminConstant.DELETE_SINGLE_VENDOR_SUCCESS,
        payload: "Account has been deleted",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.DELETE_SINGLE_VENDOR_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetSingleCustomer = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_SINGLE_CUSTOMER_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/${body}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_SINGLE_CUSTOMER_SUCCESS,
        payload: [data],
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_SINGLE_CUSTOMER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const DeleteSingleCustomer = (id, reason) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.DELETE_SINGLE_CUSTOMER_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.delete(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/${id}?reason=${reason}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: adminConstant.DELETE_SINGLE_CUSTOMER_SUCCESS,
        payload: "Account has been deleted",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.DELETE_SINGLE_CUSTOMER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetServiceByStatus = (body, page) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_SERVICE_BY_STATUS_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service?status=${body}&page=${page}&limit=16`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_SERVICE_BY_STATUS_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_SERVICE_BY_STATUS_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const UpdateServiceByStatus = (body, serviceId, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.UPDATE_SERVICE_BY_STATUS_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.patch(
        `${process.env.REACT_APP_ROOT}/v1/api/service/update/status/${serviceId}`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: adminConstant.UPDATE_SERVICE_BY_STATUS_SUCCESS,
        payload: `Service status has been updated`,
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: adminConstant.UPDATE_SERVICE_BY_STATUS_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const AddFAQ = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.ADD_FAQ_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.post(`${process.env.REACT_APP_ROOT}/v1/api/faq`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: adminConstant.ADD_FAQ_SUCCESS,
        payload: "FAQ has been added",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.ADD_FAQ_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetFAQ = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_FAQ_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/faq`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_FAQ_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_FAQ_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const DeleteFAQ = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.DELETE_FAQ_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.delete(`${process.env.REACT_APP_ROOT}/v1/api/faq/${body}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch(GetFAQ());
      dispatch({
        type: adminConstant.DELETE_FAQ_SUCCESS,
        payload: "FAQ has been deleted",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.DELETE_FAQ_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetMembershipSubscription = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_MEMBERSHIP_SUBSCRIPTION_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/subscription`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_MEMBERSHIP_SUBSCRIPTION_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const UpdateMembershipSubscription = (body, id) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.UPDATE_MEMBERSHIP_SUBSCRIPTION_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      await axios.patch(
        `${process.env.REACT_APP_ROOT}/v1/api/subscription/${id}`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch(GetMembershipSubscription());
      dispatch({
        type: adminConstant.UPDATE_MEMBERSHIP_SUBSCRIPTION_SUCCESS,
        payload: "Subscription has been updated",
      });
    } catch (error) {
      dispatch({
        type: adminConstant.UPDATE_MEMBERSHIP_SUBSCRIPTION_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetVendorAllService = (id) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_VENDOR_ALL_SERVICE_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service?vendor=${id}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_VENDOR_ALL_SERVICE_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_VENDOR_ALL_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetSingleService = (id) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_SINGLE_SERVICE_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/${id}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_SINGLE_SERVICE_SUCCESS,
        payload: data,
      });
      dispatch(GetVendorAllService(data.vendor?.id));
    } catch (error) {
      dispatch({
        type: adminConstant.GET_SINGLE_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetTopFiveCustomer = () => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/top/five/user`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_TOP_FIVE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_TOP_FIVE_CUSTOMER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetTopFiveServices = () => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/top/five/services`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_TOP_FIVE_SERVICES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_TOP_FIVE_SERVICES_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetTopFiveVendor = () => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/top/five/vendor`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_TOP_FIVE_VENDOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_TOP_FIVE_VENDOR_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const DailyUser = () => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/daily/users/report`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_DAILY_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_DAILY_USER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const WeeklyUser = () => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/weekly/users/report`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_WEEKLY_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_WEEKLY_USER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const MonthlyUser = () => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/monthly/users/report`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_MONTHLY_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_MONTHLY_USER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const VisitorsByBrowser = () => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/visitors/by/browsers`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.VISITORS_BY_BROWSERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.VISITORS_BY_BROWSERS_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetTransactionHistory = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_TRANSACTION_HISTORY_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${
          process.env.REACT_APP_ROOT
        }/v1/api/subscription/list/of/transactions?${objToStr(queryParams)}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_TRANSACTION_HISTORY_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_TRANSACTION_HISTORY_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllSubscriber = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_ALL_SUBSCRIPTION_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/subscription/get/all-subscribed-subscription-details`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_ALL_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_ALL_SUBSCRIPTION_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllCustomersList = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_ALL_CUSTOMERS_LIST_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/get/all-customer-details`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.GET_ALL_CUSTOMERS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_ALL_CUSTOMERS_LIST_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const CreateChatConnectionForUser = (body) => {
  return async (dispatch) => {
    dispatch({
      type: adminConstant.CREATE_CHAT_CONNECTION_REQUEST,
    });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("adminToken");
      let result = await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/api/service/create/customer/vendor/chat/connection`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: adminConstant.CREATE_CHAT_CONNECTION_SUCCESS,
        payload: {
          results: data,
        },
      });
    } catch (error) {
      dispatch({
        type: adminConstant.CREATE_CHAT_CONNECTION_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const addMessage = (body) => {
  return async (dispatch) => {
    dispatch({
      type: adminConstant.ADD_MESSAGE_REQUEST,
    });
    try {
      await sendMessage(body);
      dispatch({
        type: adminConstant.ADD_MESSAGE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.ADD_MESSAGE_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const GetSingleCustomerForChat = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_CUSTOMER_FOR_CHAT_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/${body}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_CUSTOMER_FOR_CHAT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_CUSTOMER_FOR_CHAT_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetSingleVendorForChat = (body) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_VENDOR_FOR_CHAT_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/${body}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_VENDOR_FOR_CHAT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_VENDOR_FOR_CHAT_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

// export const CreateChatConnectionForVendor = (body) => {
//   return async (dispatch) => {
//     dispatch({
//       type: adminConstant.CREATE_CHAT_CONNECTION_FOR_VENDOR_REQUEST,
//     });
//     try {
//       const token =
//         sessionStorage.getItem("userToken") ?? sessionStorage.getItem("adminToken");
//       let result = await axios.post(
//         `${process.env.REACT_APP_ROOT}/v1/api/service/create/customer/vendor/chat/connection`,
//         body,
//         {
//           headers: {
//             Authorization: token ? `Bearer ${token}` : "",
//           },
//         }
//       );
//       const { data } = result;
//       dispatch({
//         type: adminConstant.CREATE_CHAT_CONNECTION_FOR_VENDOR_SUCCESS,
//         payload: {
//           results: data,
//         },
//       });
//     } catch (error) {
//       dispatch({
//         type: adminConstant.CREATE_CHAT_CONNECTION_FOR_VENDOR_FAILURE,
//         payload: { err: error.response.data.message },
//       });
//     }
//   };
// };

export const getAllChatIds = () => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_ALL_CHATS_IDS_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/service/create/customer/vendor/chat/connection`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      const { data } = result;
      dispatch({
        type: adminConstant.GET_ALL_CHATS_IDS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: adminConstant.GET_ALL_CHATS_IDS_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const resetReducer = () => ({
  type: adminConstant.RESET_ADMIN_REDUCER,
});

export const getNumberOfVendorByCity = (onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_NUMBER_OF_VENDORS_BY_CITY_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/auth/get/number-of-vendors-by-city`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: adminConstant.GET_NUMBER_OF_VENDORS_BY_CITY_SUCCESS,
        payload: data,
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: adminConstant.GET_NUMBER_OF_VENDORS_BY_CITY_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const getAllSubscriberList = (onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: adminConstant.GET_ALL_SUBSCRIBER_LIST_REQUEST });
    try {
      const token = sessionStorage.getItem("adminToken");
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/api/email-list/get-all`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: adminConstant.GET_ALL_SUBSCRIBER_LIST_SUCCESS,
        payload: data,
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: adminConstant.GET_ALL_SUBSCRIBER_LIST_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};
