import axios from "axios";

const token = window.sessionStorage.getItem("adminToken");

const api = axios.create({
  baseURL: process.env.REACT_APP_ROOT,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  },
});

export const adminLogin = (data) =>
  api.post("/v1/api/admin/login", { ...data });
