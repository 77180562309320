
export const servicesTypeConstants = {
    getAllServicesTypes: {
      request: "GET_ALL_SERVICES_TYPES_REQUEST",
      success: "GET_ALL_SERVICES_TYPES_SUCCESS",
      error: "GET_ALL_SERVICES_TYPES_FAILURE",
    },
    createServiceType: {
      request: "CREATE_SERVICE_TYPE_REQUEST",
      success: "CREATE_SERVICE_TYPE_SUCCESS",
      error: "CREATE_SERVICE_TYPE_FAILURE",
    },
    deleteServiceType:{
      request:"DELETE_SERVICE_TYPE_REQUEST",
      success:"DELETE_SERVICE_TYPE_SUCCESS",
      error:"DELETE_SERVICE_TYPE_FAILURE",
    },
    getSingleServiceType:{
      request:"GET_SINGLE_SERVICE_TYPE_REQUEST",
      success:"GET_SINGLE_SERVICE_TYPE_SUCCESS",
      error:"GET_SINGLE_SERVICE_TYPE_FAILURE",
    },
    clearErrors: "CLEAR_ERRORS",
    clearMessages: "CLEAR_MESSAGES",
    updateSingleService :{
      request:"UPDATE_SINGLE_SERVICE_REQUEST",
      success:"UPDATE_SINGLE_SERVICE_SUCCESS",
      error:"UPDATE_SINGLE_SERVICE_FAILURE",
    },
  };