
export const eventData = [
  {
    name: "Anniversary",
    to: "/anniversary",
  },
  {

    name: "Baby shower",
    to: "/babyshower",
  },
  {

    name: "Birthday",
    to: "/birthday",
  },
  {

    name: "Bon fire night",
    to: "/bonfirenight",
  },
  {

    name: "Christmas",
    to: "/christmas",
  },
  {

    name: "Diwali",
    to: "/diwali",
  },
  {

    name: "Easter",
    to: "/easter",
  },
  {

    name: "Eid",
    to: "/eid",
  },
  {

    name: "Father day",
    to: "/fatherday",
  },
  {

    name: "First date",
    to: "/firstdate",
  },
  {

    name: "Funeral",
    to: "/funeral",
  },

  {

    name: "Graduation",
    to: "/graduation",
  },
  {

    name: "Halloween",
    to: "/halloween",
  },
  {

    name: "Hen do",
    to: "/hendo",
  },
  {

    name: "House Warming",
    to: "/housewarming",
  },
  {

    name: "Mother day",
    to: "/motherday",
  },
  {

    name: "New year",
    to: "/funeral",
  },
  {

    name: "Stage do",
    to: "/stagdo",
  },
  {

    name: "Valentines",
    to: "/funeral",
  },
  {

    name: "Wedding",
    to: "/wedding",
  },
];
