import React from "react";
import "./Footer.scss";
import Container from "../../container/Container";
import { facebook, insta, logo } from "../../../assests";
import Text from "../../text/Text";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <Container className="main">
          <Container className="lg" parentStyle={{margin:"2rem 5rem"}}>

            <div className="footer-container">
              <div className="footer-container-left">
              <div className="footer-logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
                <Text className="uppercase">
                  Occasion Base UK Ltd. Company registration number 09309515.
                </Text>
                <Text className="upppercase">
                  4.7 of 5 by 1223 reviews on Trustpilot
                </Text>
              </div>
              <div className="footer-container-right">
                <Link to="/aboutus">about us</Link>
                <Link to="/vender-signup">sign up as a vendor</Link>
                <Link to="/faq">faq</Link>
                <div className="socialIcon d-flex">
                  <img
                    src={insta}
                    alt="instagram"
                    style={{ marginRight: "1rem" }}
                  />
                  <img src={facebook} alt="facebook" />
                </div>
                <Link to="/support">support</Link>
              </div>
            </div>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default Footer;
