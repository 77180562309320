import * as Yup from "yup";

export const messageSchema = Yup.object().shape({
  message: Yup.string().required(),
});

export const HomePageUserInterfaceSchema = Yup.object().shape({
  pictures: Yup.array()
    .of(
      Yup.object().shape({
        imgURL: Yup.string(),
        LinkURL: Yup.string(),
      })
    )
    .min(1),
});

export const HomePageHeroImageUserInterfaceSchema = Yup.object().shape({
  picture: Yup.array()
    .of(
      Yup.object().shape({
        imgURL: Yup.string(),
      })
    )
    .min(1, "At least one image is required.")
    .max(1, "Only 1 image is allowed."),
});

export const HomePageVideoLinkSchema = Yup.object().shape({
  videoLink: Yup.string().trim().url("Invalid URL format").required(),
});

export const HomePageHeroDescriptionSchema = Yup.object().shape({
  text: Yup.string(),
});

export const FeatureOccasionsSchema = Yup.object().shape({
  selectedOccasions: Yup.array().of(Yup.mixed()).length(3),
});
