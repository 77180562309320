import {
  addDoc,
  and,
  collection,
  getDocs,
  onSnapshot,
  or,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./config";
import { getLoggedInUserDetails } from "../utils";

export const NOTIFICATION_TYPES = {
  CUSTOMER_REQUEST: "CUSTOMER_REQUEST",
  SERVICE: "SERVICE",
};

export const createSnapShotListenerForNotifications = (onNewMessage) => {
  if (!onNewMessage) return;
  const loggedInUserId = getLoggedInUserDetails().user.id;

  const collectionRef = collection(db, "notification");
  const q = query(
    collectionRef,
    and(
      where("receiverId", "==", loggedInUserId),
      where("isSeen", "==", false)
    ),
    orderBy("createdAt")
  );

  return onSnapshot(q, (querySnapshot) => {
    onNewMessage(querySnapshot);
  });
};

export const sendNotfication = async ({
  notficationType,
  senderId,
  receiverId,
  message,
  productId,
}) => {
  if (!notficationType || !productId || !senderId || !receiverId || !message)
    return;
  const collectionRef = collection(db, "notification");
  await addDoc(collectionRef, {
    notficationType,
    senderId,
    receiverId,
    isSeen: false,
    message,
    productId,
    createdAt: serverTimestamp(),
  });
};

export const updateNotificationSeen = async (notficationType) => {
  if (!notficationType) return;
  const loggedInUserId = getLoggedInUserDetails().user.id;

  try {
    const collectionRef = collection(db, "notification");
    const q = query(
      collectionRef,
      and(
        where("receiverId", "==", loggedInUserId),
        where("notficationType", "==", notficationType),
        where("isSeen", "==", false)
      ),
      orderBy("createdAt")
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const chatDocRef = doc.ref;
      await updateDoc(chatDocRef, {
        isSeen: true,
      });
    });
  } catch (error) {
    console.log(error);
  }
};
