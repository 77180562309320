import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  ChangePassword,
  clearErrors,
  clearMessages,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, FormInput, Heading } from "../../components";
const Settings = () => {
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.authReducer
  );
  const passwordvalidation = Yup.object({
    current_password: Yup.string().required("Please enter current password."),
    new_password: Yup.string()
      .required("Please enter new password.")
      .notOneOf(
        [Yup.ref("current_password")],
        "Your are entering previous password"
      )
      .min(8, "Your password is too short."),
    retypePassword: Yup.string()
      .required("Please retype your new password.")
      .oneOf([Yup.ref("new_password")], "Your passwords do not match."),
  });

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);
  return (
    <>
      <div className="customersettings">
        <Container className="half">
          <Heading className="primary center m-5">Change Password</Heading>

          <Formik
            initialValues={{
              current_password: "",
              new_password: "",
              retypePassword: "",
            }}
            validateOnMount
            validationSchema={passwordvalidation}
            onSubmit={(values, { resetForm }) => {
              let { current_password, new_password } = values;
              let result = {
                oldPassword: current_password,
                newPassword: new_password,
              };
              dispatch(ChangePassword(result));
              resetForm({ values: "" });
            }}
          >
            {(formik) => (
              <div className="m-5">
                <Form>
                  <FormInput
                    place="Current Password"
                    name="current_password"
                    type="password"
                  />
                  <FormInput
                    place="New Password"
                    name="new_password"
                    type="password"
                  />
                  <FormInput
                    place="Confirm Password"
                    name="retypePassword"
                    type="password"
                  />
                  <div className="save-btn">
                    <Button
                      disabled={!formik.isValid}
                      className="btn-lighter rounded center m-2"
                    >
                      {loading ? (
                        <Puff
                          height="25"
                          width="30"
                          radius="6"
                          color="white"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Container>
      </div>
    </>
  );
};

export default Settings;
