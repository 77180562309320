import React, { useEffect, useLayoutEffect } from "react";
import "./MemberShip.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Heading, FormInput } from "../../components";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  GetMembershipSubscription,
  UpdateMembershipSubscription,
  clearErrors,
  clearMessages,
  resetReducer,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { StyledOverflowScroll } from "../analytics/Analytics";
import styled from "@emotion/styled";

const MemberShip = () => {
  const dispatch = useDispatch();
  const { data, message, errors, loading } = useSelector(
    (state) => state.adminReducer
  );

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);

  useEffect(
    () => () => {
      // console.log("biba");
      dispatch(resetReducer());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(GetMembershipSubscription());
  }, []);
  return (
    <StyledOverflowScroll>
      <Container className="md">
        <div className="m-5">
          <Heading className=" center primary">Membership Package</Heading>
          <div className="m-6">
            <LayoutStyled>
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : data?.length > 0 ? (
                <PlainCard
                  plainName={data[0]?.name && data[0].name}
                  price={data[0]?.price && data[0].price}
                  subscriptionId={data[0]?._id && data[0]._id}
                  services={data[0]?.services && data[0].services}
                />
              ) : (
                <h1>Loading...</h1>
              )}
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : data?.length > 0 ? (
                <PlainCard
                  plainName={data[1]?.name && data[1].name}
                  price={data[1]?.price && data[1].price}
                  subscriptionId={data[1]?._id && data[1]._id}
                  services={data[1]?.services && data[1].services}
                />
              ) : (
                <h1>Loading...</h1>
              )}
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : data?.length > 0 ? (
                <PlainCard
                  plainName={data[2]?.name && data[2].name}
                  price={data[2]?.price && data[2].price}
                  subscriptionId={data[2]?._id && data[2]._id}
                  services={data[2]?.services && data[2].services}
                />
              ) : (
                <h1>Loading...</h1>
              )}
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : data?.length > 0 ? (
                <PlainCard
                  plainName={data[3]?.name && data[3].name}
                  price={data[3]?.price && data[3].price}
                  subscriptionId={data[3]?._id && data[3]._id}
                  services={data[3]?.services && data[3].services}
                />
              ) : (
                <h1>Loading...</h1>
              )}
            </LayoutStyled>
          </div>
        </div>
      </Container>
    </StyledOverflowScroll>
  );
};

export default MemberShip;

///////////////////
///////   Sub components

const PlainCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validation = Yup.object({
    services: Yup.number(),
    amount: Yup.number(),
  });
  return (
    <>
      <Formik
        initialValues={{
          services: props.services || "Infinity",
          amount: props.price && props.price,
        }}
        validateOnMount
        validationSchema={validation}
        onSubmit={(values, { resetForm }) => {
          dispatch(UpdateMembershipSubscription(values, props.subscriptionId));
        }}
      >
        {(formik) => (
          <Form>
            <div className="plaincard">
              <div className="plaincard-top">
                <h5 className="center">{props.plainName}</h5>
              </div>
              <div className="plaincard-item-container">
                <div className="plaincard-item">
                  <h5>Services</h5>
                  <div className="plaincard-item-input">
                    <FormInput
                      type="text"
                      name="services"
                      className="member-ship-form-container"
                    />
                  </div>
                </div>
                <div className="plaincard-item">
                  <h5>Price : $</h5>
                  <div className="plaincard-item-input">
                    <FormInput type="text" name="amount" />
                  </div>
                </div>
                {/* <div className="plaincard-item">
                  <h5>Featured listing</h5>
                  <div className="plaincard-item-checkbox">
                    <label>
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div> */}
              </div>
              <div className="plaincard-bottom">
                <button className="center">UPDATE</button>
              </div>
              <div className="plaincard-bottom">
                <button
                  className="center"
                  onClick={() =>
                    navigate(`/add_promocode/${props.subscriptionId}`)
                  }
                >
                  ADD PROMO
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const LayoutStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  row-gap: 3rem;
  column-gap: 1rem;
`;
