import { useState, useEffect, useRef, useMemo } from "react";
import {
  Button,
  Container,
  FormInput,
  FormText,
  Heading,
} from "../../components";
import { MdDelete } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  GetMyService,
  DeleteMyService,
  clearErrors,
  clearMessages,
  createServiceType,
  getAllServicesTypes,
  deleteServiceType,
  deleteOccasion2,
  getAllOccasions2,
  createOccasion2,
  getSingleOccasion2,
  editOccasion2,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { Form } from "formik";
import { RiUpload2Fill } from "react-icons/ri";
import "./ServicesCrud.scss";
import {
  clearServiceTypeErrors,
  clearServiceTypeMessages,
} from "./../../store/actions";
import { BACKEND_STATIC_URL } from "../../constants";
import { useQueryParams } from "../../hooks";
import { Modal } from "@mui/material";
import styled from "@emotion/styled";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal/DeleteConfirmationModal";
// import { fakeServiceDetailsData } from "./fakeServiceDetailsData";

const ManageOccasions = () => {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const { setQueryParam, editId } = useQueryParams({
    editId: "",
  });
  const [selectedDeleteId, setSelectedDeleteId] = useState("");

  const imageURL = useMemo(() => {
    return typeof image === "string"
      ? `${process.env.REACT_APP_ROOT}/uploads/${image}`
      : URL.createObjectURL(image);
  }, [image]);

  const {
    getAllOccasionsData,
    deleteOccasionData,
    editOccasionData,
    getSingleOccasionData,
  } = useSelector((s) => s.occasion2Reducer);

  const imageRef = useRef();
  const onImageChange = (event) => {
    if (event.target.files[0]) setImage(event.target.files[0]);
  };
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllOccasions2());
  }, []);

  useEffect(() => {
    setImage("");
    setName("");
    if (!editId) {
      return;
    }

    dispatch(
      getSingleOccasion2({ _id: editId }, (data) => {
        setImage(data?.occasionImage);
        setName(data?.occasionName);
      })
    );
  }, [editId]);

  const handleSubmit = () => {
    const body = new FormData();
    body.append("occasionName", name);
    body.append("occasionImage", image);

    dispatch(
      (editId ? editOccasion2 : createOccasion2)(
        {
          body,
          _id: editId,
        },
        () => {
          toast.success(
            `${editId ? "Updated" : "Created"} Occasion successfully!`
          );
          if (editId) {
            setQueryParam({}, true);
          }
          setName("");
          setImage("");
          dispatch(getAllOccasions2());
        }
      )
    );
  };

  const handleDelete = (_id) => {
    dispatch(
      deleteOccasion2(
        {
          _id,
        },
        () => {
          if (_id === editId) {
            setQueryParam({}, true, true);
          }
          toast.success("Deleted Occasion successfully!");
          dispatch(getAllOccasions2());
          setSelectedDeleteId("");
        }
      )
    );
  };

  return (
    <>
      <Container className="lg">
        <Heading className="m-5 center">Manage Occassions</Heading>
        {getSingleOccasionData?.isLoading ? (
          <div className="full-center">
            <Puff
              height="60"
              width="60"
              radius="6"
              color="black"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        ) : (
          <div className="add-serviceType">
            <label htmlFor="name">Occasion Name</label>
            <input
              label="Enter Occasion Name"
              placeholder="Brithday"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="uploadimages-btn m-5">
              <div onClick={() => imageRef.current.click()}>
                <RiUpload2Fill style={{ marginRight: "1rem" }} />
                Upload Image
              </div>
            </div>
            {image && (
              <div className="create-service-images_preview m-2">
                <div className="create-service-images_preview-images">
                  <div className="create-service-images_preview-images-item">
                    <img src={imageURL} alt="service" />
                  </div>
                </div>
              </div>
            )}
            <div style={{ display: "none" }} className="">
              <input
                type="file"
                name="myImage"
                accept="image/*"
                ref={imageRef}
                onChange={onImageChange}
              />
            </div>
            <Button
              onClick={handleSubmit}
              className="rounded center "
              disabled={!image || !name}
            >
              {editId ? "Update" : "Create"} Occasion
            </Button>
          </div>
        )}

        <Container className="md">
          <Heading className="center m-5">Occasions Lists</Heading>
          <p className="count">
            {getAllOccasionsData?.data?.length || 0} Occasions
          </p>
          <div className="table-container">
            <div className="myservices-table m-2">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Occasion name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getAllOccasionsData?.isLoading ? (
                    <tr>
                      <td colSpan={3}>
                        <div className="full-center">
                          <Puff
                            height="60"
                            width="60"
                            radius="6"
                            color="black"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                          />
                        </div>
                      </td>
                    </tr>
                  ) : getAllOccasionsData?.data?.length > 0 ? (
                    getAllOccasionsData?.data?.map((data, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{ind + 1}</td>
                          <td>{data.occasionName}</td>
                          <td>
                            <HiEye
                              onClick={() => {
                                navigate(`/manage-occasions/${data?._id}`);
                              }}
                            />

                            <FaRegEdit
                              onClick={() => {
                                setQueryParam({ editId: data._id }, true);
                              }}
                            />

                            <MdDelete
                              onClick={() => {
                                setSelectedDeleteId(data._id);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>No occasion found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Container>
      </Container>

      {/* <ViewOccasion /> */}

      <DeleteConfirmationModal
        open={Boolean(selectedDeleteId)}
        onClose={() => setSelectedDeleteId("")}
        onConfirmation={() => handleDelete(selectedDeleteId)}
        disabled={deleteOccasionData?.isLoading}
      />
    </>
  );
};

export default ManageOccasions;

const MyModal = styled(Modal)`
  z-index: 100000000;
  & .modal-item {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: white;

    overflow-y: auto;

    width: 95%;
    max-height: calc(100svh - 5rem);
    max-width: 66rem;
    padding: 1.6rem 2.4rem;
    border-radius: 0.5rem;
    outline: none;
  }
`;

// const ViewOccasion = () => {
//   const { viewId, setQueryParam } = useQueryParams({
//     viewId: "",
//   });
//   const dispatch = useDispatch();

//   const onClose = () => {
//     setQueryParam({ viewId: "" }, false);
//   };

//   const { getSingleOccasionData } = useSelector((s) => s.occasion2Reducer);

//   useEffect(() => {
//     if (!viewId) {
//       return;
//     }

//     dispatch(getSingleOccasion2({ _id: viewId }));
//   }, [viewId]);

//   return (
//     <MyModal open={Boolean(viewId)} onClose={onClose}>
//       <div className="modal-item">
//         <div className="serviceTypeDetail m-5">
//           <Heading className="primary center m-5" element="h2">
//             {getSingleOccasionData?.data?.occasionName || "--"}
//           </Heading>
//         </div>

//         <div className="serviceTypeDetail-container m-5">
//           {/* <Heading className="center m-5">Image</Heading> */}
//           <div className="serviceTypeDetail-container-image m-5">
//             <div className="serviceTypeDetail-container-image-section">
//               {getSingleOccasionData?.data?.occasionName &&
//                 [getSingleOccasionData?.data?.occasionImage].map(
//                   (item, ind) => {
//                     return (
//                       <img
//                         key={ind}
//
//                         src={`${BACKEND_STATIC_URL}${item}`}
//                         alt="service-photos"
//                         className="w-100"
//                       />
//                     );
//                   }
//                 )}
//             </div>
//           </div>
//         </div>

//         <Button className="rounded center" onClick={onClose}>
//           Close
//         </Button>
//       </div>
//     </MyModal>
//   );
// };
