import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import Routess from "./Routes";
import { useSessionStrorage, useUserChange } from "./hooks";
import {
  createNewChatsWithVendorsAndCustomers,
  getAllChats,
  updateMessages,
} from "./store/actions";
import { getLoggedInUserDetails } from "./utils";
import { useEffect } from "react";
import { createSnapShotListenerForMessages } from "./firebase/message";
import { updateNotification } from "./store/actions/notification.action";
import { createSnapShotListenerForNotifications } from "./firebase/notfication";

function App() {
  const dispatch = useDispatch();

  useUserChange(() => {
    const { user, token } = getLoggedInUserDetails();

    if (token) {
      dispatch(
        createNewChatsWithVendorsAndCustomers({}, () => {
          dispatch(getAllChats());
        })
      );

      if (!user) {
        return;
      }

      const unsubscribeMessage = createSnapShotListenerForMessages(
        user?.id,
        ({ docs }) => {
          dispatch(
            updateMessages(docs.map((d) => ({ _id: d.id, ...d.data() })))
          );
        }
      );

      const unsubscribeNotifications = createSnapShotListenerForNotifications(
        ({ docs }) => {
          dispatch(
            updateNotification(docs.map((d) => ({ _id: d.id, ...d.data() })))
          );
        }
      );

      return () => {
        unsubscribeMessage();
        unsubscribeNotifications();
      };
    }
  }, [dispatch]);

  return (
    <>
      <Routess />
    </>
  );
}

export default App;
