import { createContext, useContext, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import { useDispatch } from "react-redux";
import { getNumberOfVendorByCity } from "../store/actions";
import axios from "axios";

const HeatMapContext = createContext({
  citiesWithLatAndLong: [],
});

const HeatMapContextProvider = ({ children }) => {
  const [citiesWithLatAndLong, setCitiesWithLatAndLong] = useState([]);
  const user = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      return;
    }

    const f1 = async (data) => {
      const cities = [];
      for (const obj of data) {
        const name = obj._id.slice(0, -4);
        const { data } = await axios.get(
          `https://nominatim.openstreetmap.org/search?city=${name}&format=json`
        );

        console.log(data);

        if (!data[0]) {
          continue;
        }

        const { lat, lon } = data[0];
        const city = {
          name,
          lat: lat,
          lon: lon,
          count: obj.count,
        };

        cities.push(city);
      }

      setCitiesWithLatAndLong(cities);
    };

    dispatch(getNumberOfVendorByCity(f1));
  }, [dispatch, user]);

  return (
    <HeatMapContext.Provider value={{ citiesWithLatAndLong }}>
      {children}
    </HeatMapContext.Provider>
  );
};

export default HeatMapContextProvider;

export const useHeatMapContext = () => useContext(HeatMapContext);
