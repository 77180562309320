import React, { useState, useEffect } from "react";
import "./Faq.scss";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Container, Modal } from "../../components";
import { Link } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  GetFAQ,
  DeleteFAQ,
  clearMessages,
  clearErrors,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { StyledOverflowScroll } from "../analytics/Analytics";
const Faq = () => {
  const [alert, setAlert] = useState(false);
  const [faqId, setFaqId] = useState(false);
  const dispatch = useDispatch();
  const { data2, message, errors, loading } = useSelector(
    (state) => state.adminReducer
  );
  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);

  useEffect(() => {
    dispatch(GetFAQ());
  }, []);

  const handleFaqDeleteId = (id) => {
    setFaqId(id);
    setAlert(!alert);
  };
  const handleDelete = () => {
    dispatch(DeleteFAQ(faqId));
    setAlert(!alert);
  };
  return (
    <StyledOverflowScroll>
      {alert ? (
        <Modal action={alert}>
          <div className="modal-accept">
            <h3>Are you sure !</h3>
            <p>You want to delete this Faq ? </p>
            <div className="modal-accept-button">
              <button onClick={() => handleDelete()}>Yes</button>
              <button
                onClick={() => {
                  setAlert(!alert);
                }}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <Container className="md">
        <Container className="md">
          <div className="addfaq-btn m-5 align-item-right">
            <Link to="/add_faq">Add Faq</Link>
          </div>
        </Container>
      </Container>
      <div className="m-4">
        <Container className="md">
          <div className="m-5">
            <Container className="md">
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : data2.length > 0 ? (
                data2.map((item, ind) => {
                  return (
                    <FaqItem
                      key={ind}
                      question={item.question}
                      answer={item.answer}
                      onClick={() => handleFaqDeleteId(item.id)}
                    />
                  );
                })
              ) : (
                <h1>No record found</h1>
              )}
            </Container>
          </div>
        </Container>
      </div>
    </StyledOverflowScroll>
  );
};

export default Faq;

const FaqItem = (props) => {
  return (
    <>
      <div className="faq-item m-4">
        <div className="faq-item-flex">
          <CardItem heading="Question" content={props.question} />
          <div style={{ paddingLeft: "3rem" }} className="delete-icon">
            <RiDeleteBin5Fill onClick={props.onClick} />
          </div>
        </div>
        <CardItem heading="Answer" content={props.answer} />
      </div>
    </>
  );
};

const CardItem = (props) => {
  return (
    <>
      <div className="carditem m-1">
        <h3>{props.heading}</h3>
        <p>{props.content}</p>
      </div>
    </>
  );
};
